import React from "react";
import { Link } from "react-router-dom";
import ImageManager from 'common/Manager/ImageManager';

const StepFour = (prop) => {

  const {translate} = prop

  return (
    <section>
    
      <div className="text-center">
        <img src={ImageManager.default.images.sabuypay.successimg} alt="" />
      </div>
   
      <div className="row">
        <div className="col-12">
          <div className="skip-email text-center pb-2 success">
            <p>{translate['register.merchantinfo.step.success']}</p>            
          </div>
        </div>
      </div>

    </section>
  );
};

export default StepFour;
