import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import useStyles from "./Style";
import ThaiQRCode from "../ThaiQRCode";
import MyQRModel from 'modules/myqr/models/MyQRModel';
import html2canvas from "html2canvas";

type Props = {
  isMobile: boolean;
  myQR: MyQRModel;
};


const downloadQRCode = () => {
  
  const canvas: any = document.getElementById("qr-gen");
  const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
  let downloadLink = document.createElement("a");

  downloadLink.href = pngUrl;
  downloadLink.download = `xxxx.png`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);


};

const ThaiQRPannel = (Ipops: Props) => {
  const classes = useStyles();
  const qrRef = useRef(null);

  const downloadQRCode2 = () => {
    // Generate download with use canvas and stream
    const canvas = qrRef.current.querySelector("canvas")
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a")
    anchor.href = image

    anchor.download = "qr-demo.png";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor)

  };

  const handleDownloadImage = async (merchant_name : string) => {
    const element = document.getElementById('myqr'),
    canvas = await html2canvas(element),
    data = canvas.toDataURL('image/jpg'),
    link = document.createElement('a');

    link.href = data;
    link.download = `${merchant_name}-QR-Payment.jpg`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  };

  const { isMobile, myQR } = Ipops;

  return (
    <div className={classes.instruction} id="qr-code">

      <Grid container>
        <Grid item xs={12}>
          <div 
            role="alert"
            className="fade alert-dismissible alert-alt fade show alert alert-success show mb-2 qr-instruction"
          >
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              stroke-width="2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="mr-2"
            >
              <polyline points="9 11 12 14 22 4"></polyline>
              <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
            </svg>
            <strong>QR is ready! </strong> Show this QR Code to scan and receive money into your account.
            <button
              ata-dismiss="alert"
              aria-label="Close"
              type="button"
              className="close h-100 btn"
            >
              <span>
                <i className="mdi mdi-close"></i>
              </span>
            </button>
          </div>

          <Box display="flex" justifyContent="center" mt={4} >
          
            {(myQR) && ( <ThaiQRCode 
               qrData={myQR.qr_data?.qr_raw_data}
               isMobile={false}
               memberName={myQR.merchant?.merchant_name}
               companyBy={myQR.qr_data?.name?.en}
            />)}
           
          </Box>

          <Box mt={2} display="flex" justifyContent="center">
            <button type="button" className="btn btn-outline-primary btn-md"  onClick={() => handleDownloadImage(myQR.merchant?.merchant_name)} >
              Save QR
            </button>
          </Box>

        </Grid>


      </Grid>
    </div>
  );
};

export default ThaiQRPannel;
