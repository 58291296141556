import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import SearchTransactionRequest from "modules/report/transfer-history/APIRequest/SearchTransferRequest";
import CountTransactionRequest from "modules/report/transfer-history/APIRequest/CountTransferRequest";

import APIManager from "common/Manager/APIManager";
import FilterModel from "common/models/FilterModel";
import TransferHistory from "modules/report/transfer-history/component/index";
import { endDateISO, formatDateForApi, startDateISO } from "common/Manager/Helper";

import DownloadTransferRequest from "modules/report/transfer-history/APIRequest/DownloadTransferRequest";
import axios from "axios";

const index = (props: any) => {
  //const [status, setStatus] = useState(0)
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [totalPage, setTotalPage] = useState(0);

  const [sort, setSort] = useState({
    field: "created_at",
    sortby: "DESC",
  });

  const [report, setTransaction] = useState([]);
  const getTransaction = async (
    current_page = page,
    per_page = perPage,
    sortData = sort
  ) => {
    const filterModel = new FilterModel({
      start_date: startDate,
      end_date: endDate,

      page: current_page,
      pagesize: per_page,
      sort: sortData,
      orderdirection: "DESC",
    });

    const apiRequest = new SearchTransactionRequest(filterModel);
    const countApiRequest = new CountTransactionRequest(filterModel);
    const items = await APIManager.default.fetch(apiRequest);
    const count = await APIManager.default.fetch(countApiRequest);

    setTransaction(items.data.data);
    setPageCount(count.data.data);
    setTotalPage(Math.ceil(count.data.data / perPage));
  };

  useEffect(() => {
    getTransaction(page, perPage);
  }, []);

  const handleDateChange = (st, end) => {
    if (st) {
      setStartDate(startDateISO(st))    
    }

    if (end) {
      setEndDate(endDateISO(end));
    }
  };

  const onDownload = async (transaction_date: string, created_at:string) => {

    const apiRequest = new DownloadTransferRequest(transaction_date);
    const formData = new FormData();
    formData.append("transaction_date", transaction_date);

    axios
      .post(
        apiRequest.url,
        {
          transaction_date: transaction_date,
        },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
		      
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `transfer-history-${formatDateForApi(
            new Date(created_at)
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.log(error);
      });
    


  };

  return (
    <>
      <TransferHistory
        handleDateChange={handleDateChange}
        getTransaction={getTransaction}
        data={report}
        key={1}
        setSort={setSort}
        setPage={setPage}
        setPerPage={setPerPage}
        setTotalPage={setTotalPage}
        page={page}
        perPage={perPage}
        countRow={pageCount}
        totalPage={totalPage}
        onDownload={onDownload}
      ></TransferHistory>
    </>
  );
};

export default index;
