import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import APIRequest from "common/interface/APIRequest";
import APISearchRequest from "common/interface/APISearchRequest";
import QuickPayModel from "modules/quickpay/models/QuickPayModel";



class QuickpayRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/quickpay/create";
  param: QuickPayModel
 

  constructor(quickPay : QuickPayModel) {
    this.param = quickPay
  }

  makeBody() { 
    return this.param 
    
  }

  makeQuery() {}
}

export default QuickpayRequest;


