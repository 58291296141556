import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoginInterface from "common/interface/LoginInterface";

// import LoginRequest  from 'modules/account/user/APIRequest/LoginRequest'

import LoginRequest from "common/APIRequests/LoginRequest";
import APIManager from "common/Manager/APIManager";
import RegularExpression from "utilities/RegularExpression";
import swal from "sweetalert";
import InitializeManager from "common/Manager/InitializeManager";

const isClient = typeof window === "object";

const Login = (prop: any) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);
  const validation = RegularExpression.default;

  let ENV_CLIENT: any = {};

  if (!isClient) {
    ENV_CLIENT = JSON.parse(InitializeManager.default.get()).data?.ENV_CLIENT;
  } else {
    ENV_CLIENT = InitializeManager.default.get().data.ENV_CLIENT;
  }

  useEffect(() => {
    // loadRemember()
    setEmail("");
    setPassword("");
  }, []);

  const loadRemember = () => {
    const r = localStorage.getItem("remember");
    const em = localStorage.getItem("email");

    if (r == "y") {
      setRemember(true);
      if (em) {
        setEmail(em);
      }
    } else {
      setRemember(false);
    }
  };

  const handleEyeClick = () => {

    if(showPassword == false){
      setShowPassword(true)
    }else{
      setShowPassword(false)
    }
   

  };

  const checkRemember = () => {
    if (remember) {
      localStorage.setItem("remember", "n");
      localStorage.removeItem("email");
      setRemember(false);
    } else {
      localStorage.setItem("remember", "y");
      localStorage.setItem("email", email);
      setRemember(true);
    }
  };

  const handleEmailChange = (e: any) => {
    const { target } = e;

    var obj = {};
    obj[target.name] = target.value;

    let emailKeyUpValidate = validation.emailKeyUpValidate(String(target.value));

    if (emailKeyUpValidate) {
      setEmail(target.value);
    }
    
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (email == "") {
      swal("", "Email is required", "error");
      return;
    }

    if (password == "") {
      swal("", "Password is required", "error");
      return;
    }

    const apiRequest = new LoginRequest({
      email: email,
      password: password,
    });

    const loginData = await APIManager.default.fetch(apiRequest);
    const dataRegister = loginData.data.data;
    
    if (loginData.data.status == 302) {
      window.location.href = `/page-register?token=${dataRegister.token}&id=${dataRegister.id}`;
    }

    if (loginData.data.status == 201) {
      window.location.href = `/page-reset-password?token=${loginData.data.token}`;
    }

   if (loginData.data.status == 200) {
     
      window.location.href = loginData.data.landingURL;
    }

   
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        {ENV_CLIENT["MERCHANT_BACKOFFICE_ASSET"] && (
                          <img
                            src={`${ENV_CLIENT["MERCHANT_BACKOFFICE_ASSET"]}/images/sabuypay_logo_login.svg`}
                            width="60%"
                          />
                        )}
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 text-white">
                      Sign in your account
                    </h4>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label className="mb-1 text-white">
                          <strong>Email</strong>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          defaultValue=""
                          name="email"
                          value={email}
                          onChange={handleEmailChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1 text-white">
                          <strong>Password</strong>
                        </label>
                        
                        <div className="input-group transparent-append">
                          <input
                            type={showPassword == true ? 'text' : 'password' }
                            className="form-control input-pass-login"
                            defaultValue=''
                            name='password'
                            onChange={ (e:any) => setPassword(e.target.value) }
                          />
                          <div className="input-group-append show-pass-login" 
                          onClick={handleEyeClick} >
                            <span className="input-group-text-login">
                            {showPassword == true && (<i className="fa fa-eye" style={{display:'block'}}></i>)}   
                            {showPassword == false  && ( <i className="fa fa-eye-slash"> </i>  )} 
                            </span>
                          </div>
                        </div>
                     
                      </div>
                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="basic_checkbox_1"
                              checked={remember}
                              onChange={checkRemember}
                            />
                            <label
                              className="custom-control-label text-white"
                              htmlFor="basic_checkbox_1"
                            >
                              Remember my preference
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <Link
                            className="text-white"
                            to="/page-forgot-password"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <div className="text-center">
                        <input
                          type="submit"
                          value="Sign Me In"
                          className="btn bg-white text-primary btn-block"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
