import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import PaymentStatusTab from "modules/dashboard/component/PaymentStatusTab";

import avt1 from "images/avatar/15.png";
import avt2 from "images/avatar/16.png";
import avt3 from "images/avatar/17.png";
import avt4 from "images/avatar/18.png";

import APIManager from "common/Manager/APIManager";
import GetMainBalanceRequest from "modules/dashboard/APIRequest/GetMainBalanceRequest";
import GetPaymentStatusRequest from "modules/dashboard/APIRequest/GetPaymentStatusRequest";
import GetTopAmountProductRequest from "modules/dashboard/APIRequest/GetTopAmountProductRequest";

import { PaymentStatusPeriodEnum } from "common/enumerations/PaymentStatusPeriodEnum";

import UpTrend from "modules/dashboard/component/UpTrend";
import DownTrend from "modules/dashboard/component/DownTrend";

import AmountPeriodChart from "modules/dashboard/component/AmountPeriodChart";
import MonthlyChart from "modules/dashboard/component/MonthlyChart";

const LimitChart = loadable(() =>
  pMinDelay(import("jsx/components/Mophy/Dashboard/LimitChart"), 1000)
);
const IncomeChart = loadable(() =>
  pMinDelay(import("jsx/components/Mophy/Dashboard/IncomeChart"), 1000)
);
const OutomeChart = loadable(() =>
  pMinDelay(import("jsx/components/Mophy/Dashboard/OutomeChart"), 1000)
);
const WeeklyChart = loadable(() =>
  pMinDelay(import("jsx/components/Mophy/Dashboard/WeeklyChart"), 1000)
);

import TopAmountProductModel from "modules/dashboard/models/TopAmountProductModel";
import PaymentStatusTabsModel from "modules/dashboard/models/PaymentStatusTabsModel";
import MainBalanceModel from "modules/dashboard/models/MainBalanceModel";

import NumberFormat from 'utilities/NumberFormat'
import PaymentChannelAmountModel from "modules/dashboard/models/PaymentChannelAmountModel";
import PaymentStatusDatePeriodModel from "modules/dashboard/models/PaymentStatusDatePeriodModel";


import ChannelAmountModel from "modules/dashboard/models/ChannelAmountModel";
import { getPeriod1year, getPeriod2year, getPeriod30, getPeriod60, getPeriodMonthly, getPeriodToday, getPeriodTop30Day, getPeriodWeekly, markNumber, markNumberFormatPercent, startDateISO } from "common/Manager/Helper";
import MainBalanceDatePeriodModel from "modules/dashboard/models/MainBalanceDatePeriodModel";

// import TransactionColorStatusText from "common/enumerations/TransactionColorStatus";

const contentBlog = [
  { images: avt1, title: "FSoziety", price: "$45", time: "4h ago" },
  { images: avt2, title: "David Ignis", price: "$672", time: "5h ago" },
  { images: avt3, title: "Olivis Johan", price: "$769", time: "7h ago" },
  { images: avt4, title: "Stevan Store", price: "$562", time: "5h ago" },
  { images: avt2, title: "Kidz Zoo", price: "$776", time: "3h ago" },
];

const Home = () =>  {


  const [sendMessage, setSendMessage] = useState(false);
  const [mainBalance, setMainBalance] = useState<MainBalanceModel>(null);
  const [paymentStatusTab, setPaymentStatusTab] = useState<PaymentStatusTabsModel>(null);
  const [topAmountProduct, setTopAmountProduct] = useState<TopAmountProductModel[]>(null);
  
  // const [paymentStatus1, setPaymentStatus1] = useState<PaymentStatusModel>(null);
	// const [paymentStatus2, setPaymentStatus2] = useState<PaymentStatusModel>(null);
	// const [paymentStatus3, setPaymentStatus3] = useState<PaymentStatusModel>(null);
		

  // const getById = async () => {

  let amount_data : any;

  const getMainBalance = async () => {
    
    const mainBalanceDatePeriod = new MainBalanceDatePeriodModel({
     period30:getPeriod30(new Date()),
     period60 :getPeriod60(new Date()),     
     period1year :getPeriod1year(new Date()),
     period2year:getPeriod2year(new Date()),
    })

   
    const apiRequest = new GetMainBalanceRequest(mainBalanceDatePeriod);
    const mainData = await APIManager.default.fetch(apiRequest);
    setMainBalance(mainData.data.data.data.data)
    
  }

  const getPaymentStatus = async (p:PaymentStatusPeriodEnum) => {
          
    const today = getPeriodToday(new Date());
    const weekly = getPeriodWeekly(new Date());
    const monthly = getPeriodMonthly(new Date());

    const paymentStatusDatePeriodModel = new PaymentStatusDatePeriodModel({
      today : today,
      weekly : weekly,
      monthly : monthly
    });
      
    const apiRequest = new GetPaymentStatusRequest(p, paymentStatusDatePeriodModel);
    const paymentStatusData = await APIManager.default.fetch(apiRequest);
    setPaymentStatusTab(paymentStatusData.data.data.data.data)	
         
  }

  const getTopAmountProduct = async () => {

    const period = getPeriodTop30Day(new Date());
    const apiRequest = new GetTopAmountProductRequest(period);
    const topAmountProduct = await APIManager.default.fetch(apiRequest);
    setTopAmountProduct(topAmountProduct.data.data.data.data)	
         
  }
  
  useEffect(() => {
   
     getMainBalance();   
  	 getPaymentStatus(PaymentStatusPeriodEnum.MONTHLY);
     getTopAmountProduct();

}, []);

  return (
    <Fragment>
      <div className="form-head mb-4">
        <h2 className="text-black font-w600 mb-0">Dashboard</h2>
      </div>

      <div className="row">
        <div className="col-xl-9 col-xxl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header flex-wrap border-0 pb-0 align-items-end">                  
                  
                    <div className="d-flex align-items-center mb-3 mr-3">
                      <svg
                        className="mr-3"
                        width="68"
                        height="68"
                        viewBox="0 0 68 68"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M59.4999 31.688V19.8333C59.4999 19.0818 59.2014 18.3612 58.6701 17.8298C58.1387 17.2985 57.418 17 56.6666 17H11.3333C10.5818 17 9.86114 16.7014 9.32978 16.1701C8.79843 15.6387 8.49992 14.9181 8.49992 14.1666C8.49992 13.4152 8.79843 12.6945 9.32978 12.1632C9.86114 11.6318 10.5818 11.3333 11.3333 11.3333H56.6666C57.418 11.3333 58.1387 11.0348 58.6701 10.5034C59.2014 9.97208 59.4999 9.25141 59.4999 8.49996C59.4999 7.74851 59.2014 7.02784 58.6701 6.49649C58.1387 5.96514 57.418 5.66663 56.6666 5.66663H11.3333C9.07891 5.66663 6.9169 6.56216 5.32284 8.15622C3.72878 9.75028 2.83325 11.9123 2.83325 14.1666V53.8333C2.83325 56.0876 3.72878 58.2496 5.32284 59.8437C6.9169 61.4378 9.07891 62.3333 11.3333 62.3333H56.6666C57.418 62.3333 58.1387 62.0348 58.6701 61.5034C59.2014 60.9721 59.4999 60.2514 59.4999 59.5V47.6453C61.1561 47.0683 62.5917 45.9902 63.6076 44.5605C64.6235 43.1308 65.1693 41.4205 65.1693 39.6666C65.1693 37.9128 64.6235 36.2024 63.6076 34.7727C62.5917 33.3431 61.1561 32.265 59.4999 31.688ZM53.8333 56.6666H11.3333C10.5818 56.6666 9.86114 56.3681 9.32978 55.8368C8.79843 55.3054 8.49992 54.5847 8.49992 53.8333V22.1453C9.40731 22.4809 10.3658 22.6572 11.3333 22.6666H53.8333V31.1666H45.3333C43.0789 31.1666 40.9169 32.0622 39.3228 33.6562C37.7288 35.2503 36.8333 37.4123 36.8333 39.6666C36.8333 41.921 37.7288 44.083 39.3228 45.677C40.9169 47.2711 43.0789 48.1666 45.3333 48.1666H53.8333V56.6666ZM56.6666 42.5H45.3333C44.5818 42.5 43.8611 42.2015 43.3298 41.6701C42.7984 41.1387 42.4999 40.4181 42.4999 39.6666C42.4999 38.9152 42.7984 38.1945 43.3298 37.6632C43.8611 37.1318 44.5818 36.8333 45.3333 36.8333H56.6666C57.418 36.8333 58.1387 37.1318 58.6701 37.6632C59.2014 38.1945 59.4999 38.9152 59.4999 39.6666C59.4999 40.4181 59.2014 41.1387 58.6701 41.6701C58.1387 42.2015 57.418 42.5 56.6666 42.5Z"
                          fill="#1EAAE7"
                        />
                      </svg>
                      <div className="mr-auto">
                        <h5 className="fs-20 text-black font-w600">
                          Main Balance
                        </h5>
                        <span className="text-num text-black font-w600">
                          {NumberFormat.default.numberWithCommas(mainBalance?.main_balance?.toString())}
                        </span>
                      </div>
                    </div>
                    <div className="mr-3 mb-3">

                      <div>                                             
                        <p className="fs-14 mb-1">Create Date</p>
                      <span className="text-black">{mainBalance?.foundation_date}</span>
                      </div>
 


                    </div>
                    <div className="mr-3 mb-3">
                      <p className="fs-14 mb-1">Number Of Year Created</p>
                      <span className="text-black">{ mainBalance?.number_of_years_foundation}</span>
                    </div>

              
                </div>

                

              </div>
            </div>


          </div>

          <div className="row">
              
                
                  <div className="card-body">

                    <div className="row align-items-center">
                    <h5 className="fs-20 text-black font-w600">Report Summary</h5>
                      <div className="row">
                        <div className="col-xl-6 col-sm-6">
                          <div className="card">

                            <div className="card-header flex-wrap border-0 pb-0">
                              <div className="mr-3 mb-2">
                                <p className="fs-14 mb-1">Total Amount</p>
                                <span className="fs-24 text-black font-w600">
                                    {NumberFormat.default.numberWithCommas(mainBalance?.total_amount30.value?.toString())}
                                </span>
                              </div>
                              <span className="fs-12 mb-2">

                              {mainBalance?.total_amount30.percent > 0 ? <UpTrend></UpTrend> : ( 
                                mainBalance?.total_amount30.percent < 0 ? <DownTrend></DownTrend>  : '' ) }
                                {NumberFormat.default.numberWithCommasWithOutDot(mainBalance?.total_amount30?.percent?.toString())}% (30 days)
                              </span>
                            </div>

                            <div className="card-body"  style={{zIndex:1}}>

                              <AmountPeriodChart  data_list={ mainBalance?.total_amount30.data_list} chart_name={'Total Amount'}
                                />

                            </div>

                          </div>
                        </div>

                        <div className="col-xl-6 col-sm-6">
                          <div className="card">
                            <div className="card-header flex-wrap border-0 pb-0">
                              <div className="mr-3 mb-2">
                                <p className="fs-14 mb-1">Transaction Count</p>
                                <span className="fs-24 text-black font-w600">
                                {(mainBalance?.transaction_count30.value?.toString())}
                                </span>
                              </div>
                              <span className="fs-12 mb-2">
                                {mainBalance?.transaction_count30.percent > 0 ? <UpTrend></UpTrend> :
                                (mainBalance?.transaction_count30.percent < 0 ? <DownTrend></DownTrend>  : '' ) }

                                {NumberFormat.default.numberWithCommasWithOutDot(mainBalance?.transaction_count30.percent?.toString())}% (30 days)
                              </span>
                            </div>
                            <div className="card-body p-0">
                            <AmountPeriodChart data_list={ mainBalance?.transaction_count30.data_list} chart_name={'Transaction Count'} />

                            </div>
                          </div>
                        </div>
                        
                        <div className="col-xl-12">
                          <div className="card overflow-hidden">
                            <div className="card-header d-sm-flex d-block border-0 pb-0">
                              <div className="mb-sm-0 mb-2">
                                <p className="fs-14 mb-1">Total Amount (365 days)</p>
                                <span className="mb-0">
                                  <svg
                                    width="12"
                                    height="6"
                                    viewBox="0 0 12 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.9999 6L5.99994 -2.62268e-07L-6.10352e-05 6"
                                      fill="#2BC155"
                                    />
                                  </svg>
                                  <strong className="fs-24 text-black ml-2 mr-3">
                                    {NumberFormat.default.numberWithCommas(mainBalance?.yearly_amount.value?.toString())}
                                  </strong>
                                  
                                </span>
                              </div>

                              <span className="fs-12">
                                <svg
                                  width="21"
                                  height="15"
                                  viewBox="0 0 21 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.999939 13.5C1.91791 12.4157 4.89722 9.22772 6.49994 7.5L12.4999 10.5L19.4999 1.5"
                                    stroke="#2BC155"
                                    stroke-width="2"
                                  />
                                  <path
                                    d="M6.49994 7.5C4.89722 9.22772 1.91791 12.4157 0.999939 13.5H19.4999V1.5L12.4999 10.5L6.49994 7.5Z"
                                    fill="url(#paint0_linear2)"
                                  />
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear2"
                                      x1="10.2499"
                                      y1="3"
                                      x2="10.9999"
                                      y2="13.5"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop
                                        offset="0"
                                        stop-color="#2BC155"
                                        stop-opacity="0.73"
                                      />
                                      <stop
                                        offset="1"
                                        stop-color="#2BC155"
                                        stop-opacity="0"
                                      />
                                    </linearGradient>
                                  </defs>
                                </svg>
                                {NumberFormat.default.numberWithCommasWithOutDot(mainBalance?.yearly_amount.percent?.toString())}% (365 days)
                              </span>

                            </div>
                            <div className="card-body p-0">
                              <MonthlyChart data_list={mainBalance?.yearly_amount.data_list} chart_name={"Total Amount"} />
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-12">

                          <h5 className="fs-20 text-black font-w600">Payment Channel (30 days)</h5>

                            <div className="row">
                              <div className="col-xl-4 col-lg-6 col-sm-6">
                                <div className="widget-stat card">
                                  <div className="card-body p-4">
                                    <div className="media ai-icon">
                                      <span className="mr-3 bgl-primary text-primary">
          {/*                                      
                                        <span className="mr-3"> */}
                                        <i className="fa fa-calculator" aria-hidden="true"></i>
                                          
                                        </span>
                                        <div className="media-body">
                                          <p className="mb-1">{mainBalance?.payment_channel?.title}</p>
                                          <h4 className="mb-0">{NumberFormat.default.numberWithCommas(mainBalance?.payment_channel?.value?.toString())}</h4>
                                          <span className="badge badge-total-channel">
                                            { markNumberFormatPercent(mainBalance?.payment_channel.percent)}%
                                          </span>
                                        </div>

                                      {/* </span> */}

                                    </div>
                                  </div>
                                </div>
                              </div>

                              {mainBalance?.payment_channel?.channel_amount?.map( (x :ChannelAmountModel) =>(

                                <div className="col-xl-4 col-lg-6 col-sm-6">
                                <div className="widget-stat card">
                                  <div className="card-body p-4">
                                    <div className="media ai-icon">
                                        <span className="mr-3 bgl-primary text-primary">
                                      {/* <span className="mr-3"> */}
                                        <img src={x.icon} alt="" width={'30px'}  className="logo-img-credit" />
                                      </span>
                                      
                                      <div className="media-body">
                                        <p className="mb-1">{x.channel}</p>
                                        <h4 className="mb-0">{NumberFormat.default.numberWithCommas(x.value?.toString())}</h4>
                                        <span className="badge badge-primary">
                                          {markNumberFormatPercent(x?.percent)}%

                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>

                              ))}
                              
                            </div>

                        </div>
                      </div>
                    </div>
                  </div>
              
          </div>

          <div className="row">
            <PaymentStatusTab             
              paymentStatusTab={paymentStatusTab}
            />
          </div>
          

        </div>
        

        <div className="col-xl-3 col-xxl-12">

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header pb-0 border-0">
                  <div>
                    <h4 className="fs-20 text-black mb-0">
                      Top Amount By Product {" "}
                    </h4>
                    <span className="fs-12">
                      Frequency of Transaction (30 days)
                    </span>
                  </div>
                </div>
                <div className="card-body">


            {topAmountProduct?.map((data, index) => (
                    <div className="d-flex pb-3 border-bottom mb-3 align-items-center">
                      <img
src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAAAAAAfl4auAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAASgSURBVEjHbZaxiyJNEMXrn/vgWEREcUUXZYYRRXEUdWRGHFyZQTC51NTUbKPNzAxkwwtNNzc19euuqu6pdrfv4Djxx3v1qqpb8L2OOu32mzqtVqvZbKjz+lqv12u1WrVarVQr5Uq5XCqpv6WXl5c/L3/U+Q8PeOpIvIm4opGv1hSsTrmseaQRJhp839JtoW3Eq/oIumRozSvY9/yOh9IO/qrxmvZeqbB46UkcgsDXvEfahkbrDcar1rm1TjgE3cAPHOtUuSterYjKXwwO3W6A4ox3ChrVOfWapXXqAta4tG5TbzraNrfCO8NMe1b7zdAN0fJCm3Do9XpW22fndmIajZ94xWqXoN9HGmN7zq3pztuP2BHuycKp9Lf2L9MqaHSuYKttUvdccZO6Ca4Qh0F/YMRtbmrg2lR5y9ImdsKJhoE6QtzSRtw2zdVGHIZDhBk34h32blI3La8746Zggz9VTi0n/ufEIA6jkcVtywMzb2JabeyCZphpVA/0+WVVEK/bPVU4hJI24r5vh13m1hCVa3UIQ8IHhp4cr7fH4349RY51d94oOBiPFVzU3T/eH+acaGLa7Y5JvZgY1IbJeDwejYz44PMhztXE/tRyow2TyYTEEV89nPPxtCutZot7huoa1jRXfnHhO16P3u54Pp8/tlx5sacwRdiIXwnaL7nyjaIX/OHj9tcMDFcOU01b6zf6Wt4/XC7ny/m8CYJFkeBj704MzKazqbE+Gv1jjYMamC6N61WWMX2TdzPMZ7PZVB2ybsO+XXIa9pj/T/98OtcjREhPsPQwXNxEo3JN77jl5P7uzBtEUTSba20Kbifox6cSz8nveUfHeRFhEUXz+Yxphb/Lbp36vYnN6/scFe9pS+MaVjTiFHu4uRTq237vKIfG88yTpsVhoU8UcW6Y+jjcG/mjWpWDqOTgPOYQxwQTff76+rpcLmF4oi9fcNOy479vM+1y0yCJSZpw7vM+/ODIBlc8f3sJ1Y6zbnZFwbHFZ7OPh9PWx35AM3LbH+gDXyxaG5ZJnBC+ULmt7s5i3IbDg/PBt3PFwHKZJMq6qdz97uF50Q7Oe6pgRcfW+1xkez+Go+FoeCrYo3yOvQ6sVkvmqWnz9Mhlfsa45aNhSo2/n3Jcla692SFdaTxZcnCUW57nSxp2vmMi9bWnd0HhkKaFtm15MexqYn5czYF5VhD+BS/mLQxH7t1M1rU2rNdpStZt4TTsfElMxgXND6J9GAhOV0sUX8Y/xRkfGr5X/JKAzTvSKcGJVDebRjcUV07eiYbNZr1m60jHT/TUsT4ciPe0C9lGaa/TtRFPEqdpU7vm4lkx3iHLNCxis6mrgYmw8ImNnXtG6t2eVlba72sObuUUPp//XjmnDlmO+HotYift2FgXtBEn75BnmRVPjfcEx3Uh6KnVFs8xqDHOGXetxxaPhPWx/CWBsKY5uDSVw853hO7aVFRuaNjm6k9G3g3N+NKu+bzoOf+SwK7BdrvNFY/Ws41Lk3i8iBw6tNNKMHnX9ObJeiKvR9kzXTrstjvis4zM88isipbrni1oVWa0p+wddpZm6++FOE+7GHb9GBfe/wcvs5iaHE6L4wAAAABJRU5ErkJggg=="                        alt=""
                        className="rounded-circle mr-3"
                        width="50"
                      />
                      <div className="mr-auto">
                        <h6 className="fs-16 font-w600 mb-0">
                        
                           {data.product_name}
                        
                        </h6>
                        <span className="fs-12">{data.transaction_count} Transaction</span>
                      </div>
                      <span className="fs-16 text-black font-w600">
                        {NumberFormat.default.numberWithCommas(data.sum_net_amount?.toString())}
                      </span>
                    </div>
                  ))}


                </div>
              </div>
            </div>
          </div>
                


          


        </div>


      </div>
    </Fragment>
  );
};

export default Home;


