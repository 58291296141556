import APIRequest from 'common/Manager/interface/APIRequest';
import { HTTPMethodEnum } from 'common/Manager/Enumeration/HTTPMethodEnum';
import { serverConfig } from 'common/Config/ServerConfig';

class StrapiAuthRequest implements APIRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string 
  param: any;
  identifier:string;
  password: string;



  constructor(identifier:string , password : string) {
    this.identifier = identifier
    this.password = password
    this.url = `${serverConfig.WEB_LABEL_ENDPOINT}/api/auth/local`;
  }

  makeQuery() {
    return {};
  }

  makeHeader() {
    return {};
  }

  makeBody() {

    return {
      "identifier": this.identifier,
      "password": this.password 
    }

  }

}

export default StrapiAuthRequest;
