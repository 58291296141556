import React from 'react'
import { Box, Grid, TextField, MenuItem, Hidden, Menu, Select, Button } from '@material-ui/core';
import { AppImage } from 'common/components';
import ImageManager from 'common/Manager/ImageManager';
import LanguageCodeEnum from 'common/enumerations/LanguageCodeEnum';
import _ from 'lodash';
import useStyles from './Styles'

import {BtnRegister, BtnLogin} from '../Button';
import SideNav from '../SideNav'

import LocalizationStore from 'stores/LocalizationStore';

import StepOne from "../Forms/Wizard/StepOne";
import StepTwo from "../Forms/Wizard/StepTwo";
import StepThree from "../Forms/Wizard/StepThree";
import InitializeManager from 'common/Manager/InitializeManager';

const isClient = typeof window === 'object';

const Header = (props: any) => {


    const { translate, token, id, locale  } = props
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let ENV_CLIENT: any = {}
    const [bars, setBars] = React.useState("none");
    
    if (!isClient) {    
        ENV_CLIENT = JSON.parse(InitializeManager.default.get()).data?.ENV_CLIENT
    } else {
        ENV_CLIENT = InitializeManager.default.get().data.ENV_CLIENT
    }
  
    const languageChange = (language: LanguageCodeEnum) => {
        const lang = language.toLowerCase()
        window.location.href = `${ENV_CLIENT['BACKOFFICE_FRONT_ENDPOINT']}/page-register?token=${token}&id=${id}&locale=${lang}`
    }

    LocalizationStore.default.init()
    
    const handleBars = () => {

        if(bars == "block"){
            setBars("none")
        }
        
        if(bars == "none"){
            setBars("block")
        }      
        
    }

    const goToURL = (url:string) =>{
        window.location.href = url
    }
    
const steps = [
    {name: 'StepOne', component: <StepOne/>},
    {name: 'StepTwo', component: <StepTwo/>},
    {name: 'StepThree', component: <StepThree/>},

  ];
  
    return (
        <>
            <Box className={classes.root}>    
                <div className={classes.container} >                     
                        <Box flexGrow={1} >
                            <AppImage className={classes.logo} src={ImageManager.default.images.sabuypay.icon.logo}
                            />
                        </Box>
                        <Box  className={classes.box_img}>
                            <BtnRegister onClick={ ()=> goToURL( `${ENV_CLIENT['SABUYPAY_FRONT_ENDPOINT']}/register`) }
                            > 
                                <Box display={'flex'} alignItems={'center'}>
                                    <Box mr={2}>
                                        <img src={ImageManager.default.images.sabuypay.register.register_icon} alt="" />
                                    </Box>
                                    <Box>{translate['register.regis.header.btn']}</Box>
                                </Box>
                            </BtnRegister>
                        </Box>
                        <Box >
                            <BtnLogin onClick={ ()=> goToURL(`${ENV_CLIENT['BACKOFFICE_FRONT_ENDPOINT']}`) }> 
                                <Box display={'flex'} alignItems={'center'}>
                                    <Box mr={2}>
                                        <img  
                                            src={ImageManager.default.images.sabuypay.register.person_outline_24dp} alt="" />
                                    </Box>
                                     <Box>{translate['register.login.header.btn']}</Box>
                                </Box>
                            </BtnLogin>
                        </Box>
                        <div className={classes.line} >
                            <img  className={classes.line_img} src={ImageManager.default.images.sabuypay.register.border_right} alt="" />
                        </div>
                        <Box  >
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.btn_flag}>
                                {
                                LocalizationStore.default.availableLanguages.map((language, index) => {
                                    return (
                                        (locale == language.code.toLocaleLowerCase()) &&
                                        (<>
                                            <img src={language.icon_regis} alt={language.code} className={classes.img_flag} />
                                            <div className={classes.text_language} >{language.text}</div>
                                            <img src={ImageManager.default.images.sabuypay.register.expand_more}/>
                                        </>)
                                    )
                                })}
                                
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                 {LocalizationStore.default.availableLanguages.map((language, index) => {
                                    return (
                                        <MenuItem
                                            key={index} value={language.code}
                                                onClick={() => languageChange(language.code) }
                                                >                                               
                                            <div style={{ display: 'flex', alignItems: 'center' }} >
                                                <img style={{ marginRight: 4 }} src={language.icon_regis} alt={language.code} 
                                                className={classes.img_flag}  />
                                                <div className={classes.text_language} >{language.text}</div>
                                            </div>
                                        </MenuItem>
                                    )
                                })} 
                            </Menu> 

                        </Box>
                    
                </div>

            </Box>
             {/* Mobile                         */}
            <SideNav bars={bars} translate={translate} ENV_CLIENT={ENV_CLIENT} />
            <Box className={classes.root_mobile} > 
                                
                <Box  className={classes.container_mobile}>
                    
                    <Box flexGrow={1}>
                        { (bars == "none") && (<img src={ImageManager.default.images.sabuypay.register.bars} onClick={handleBars} />)}
                        { (bars == "block") && (<img src={ImageManager.default.images.sabuypay.register.outline_close_black_24dp} onClick={handleBars} />) }
                    </Box>

                    <Box flexGrow={1} >
                        <img  src={ImageManager.default.images.sabuypay.register.sabuypay_log_horizontal} />
                    </Box>
                </Box>                    
            </Box> 
           
        </> 

    )
}

export default Header