

class APIConfig {
  static default: APIConfig = new APIConfig();
  token: string;
  ENV_CLIENT: any = {}

  private constructor() {}

  setEnv(ENV_CLIENT :any) {
    this.ENV_CLIENT = ENV_CLIENT
  }

  getConfigs() {   
    return this.ENV_CLIENT['BACKOFFICE_FRONT_ENDPOINT']    
  }


  getBaseUrl(): string {
    return this.getConfigs()
  }

  getRequestHeaders() {
    return {};
  }



}

export default APIConfig;
