import React, { useRef, useEffect, useState } from "react";


const CredentialsItem = (prop) => {
  const {
    credential
  } = prop;


  const [hidePublicKey, setHidePublicKey] = useState(true);
  const [publicKey, setPublicKey] = useState('**** **** **** ****');
  const [copySuccess, setCopySuccess] = useState('');
  const textPublicKeyRef = useRef(null);
 
  function copyToClipboard(e) {
    textPublicKeyRef.current?.select();
    document.execCommand('copy');

    e.target.focus();
    setCopySuccess('Copied!');
    
  };


  useEffect(() => {

    
  }, []);

  const handlePublicKey = async (hideStatus : boolean) => {   
        
    if(hideStatus){     

      setHidePublicKey(false);
      setPublicKey(credential?.public_key)   
    }
    else {
      setHidePublicKey(true);
      setPublicKey("**** **** **** ****")   
    } 

  };

  return (
    
    <>
      <div className="basic-form">
        <div className="row mb-4">
          <label className="col-sm-2 col-form-label">Product name</label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              placeholder=""
              value={credential.name}
           
            />
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-2 col-form-label">Public Key</label>
          <div className="col-sm-10">

          <div className="input-group transparent-append">
              <input type={'text'} className="form-control" value={publicKey} ref={textPublicKeyRef} />
              <div className="input-group-append show-pass" onClick={() =>  handlePublicKey(hidePublicKey)  }>
                <span className="input-group-text">                                  
                {hidePublicKey && ( <i className="fa fa-eye-slash"> </i>  )} 
                {hidePublicKey == false && (<i className="fa fa-eye" style={{display:'block'}}></i>)}                                   
                </span> 
              </div>
          </div>
        
          <div className="form-inline mt-2 mb-2">   
                <button type="button" onClick={copyToClipboard} className="btn btn-primary">Copy</button>
            <span className="ml-2"> {copySuccess}  </span>

          </div>


          </div>
        </div>

    
        <hr></hr>
      </div>

    </>
  );
};
export default CredentialsItem;


