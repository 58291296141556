import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import FilterModel from "modules/quickpay/models/FilterModel";




class SearchQuickpayRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/quickpay/get";
  param:FilterModel
 

  constructor(param: FilterModel) {
    this.param = param   
  }

  makeBody() {

    return {
        ...this.param
    }
  }

  makeQuery() {}
}

export default SearchQuickpayRequest;
