import React from "react";

const DownTrend = () => {
  return (
    <>
      <svg
        width="21"
        height="15"
        viewBox="0 0 21 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.3514 7.5C15.9974 9.37169 19.0572 12.8253 20 14H1V1L8.18919 10.75L14.3514 7.5Z"
          fill="url(#paint0_linear1)"
        />
        <path
          d="M19.5 13.5C18.582 12.4157 15.6027 9.22772 14 7.5L8 10.5L1 1.5"
          stroke="#FF2E2E"
          stroke-width="2"
          stroke-linecap="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear1"
            x1="10.5"
            y1="2.625"
            x2="9.64345"
            y2="13.9935"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#FF2E2E" />
            <stop offset="1" stop-color="#FF2E2E" stop-opacity="0.03" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default DownTrend;
