import React, { Fragment, useState, useEffect } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import ChatBox from "../ChatBox";

import APIManager from "common/Manager/BackendAPIManager";
import InitializeManager from  "common/Manager/InitializeManager";
import UserMenuModel from "modules/usermenu/models/UserMenuModel";
import UserInfoModel from "modules/usermenu/models/UserInfoModel";
import MemberInfoModel from "modules/usermenu/models/MemberInfoModel";
const isClient = typeof window === 'object';


const JobieNav = ({ 
   // title, 
   onClick: ClickToAddEvent, onClick2, onClick3 }) => {
   const [toggle, setToggle] = useState("");
   const onClick = (name) => setToggle(toggle === name ? "" : name);

   let moduleMenu, userInfo, memberInfo; 
     
   if (!isClient) {      

      moduleMenu = new UserMenuModel(JSON.parse(InitializeManager.default.get()).data?.moduleMenu)
      userInfo = new UserInfoModel(JSON.parse(InitializeManager.default.get()).data?.userInfo)
      memberInfo = new MemberInfoModel(JSON.parse(InitializeManager.default.get()).data?.memberInfo)     

   } else {

      moduleMenu = InitializeManager.default.get().data?.moduleMenu
      userInfo = InitializeManager.default.get().data?.userInfo
      memberInfo = InitializeManager.default.get().data?.memberInfo

   }

   return (
      <Fragment>
         <NavHader />
         <SideBar onClick={() => onClick2()} onClick3={() => onClick3()} moduleMenu={moduleMenu} />
         <Header
            onNote={() => onClick("chatbox")}
            onNotification={() => onClick("notification")}
            onProfile={() => onClick("profile")}
            toggle={toggle}
            //title={title}
            // onBox={() => onClick("box")}           
            onClick={() => ClickToAddEvent()} 
            userInfo={userInfo}
            memberInfo={memberInfo}          
                        
         />
         <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
      </Fragment>
   );
};

export default JobieNav;
