import _ from 'lodash'
import UserRoleModel from './TransactionModel'

import QuickPayModel from './QuickPayModel'


class FormEmailModel {


	quickpay : QuickPayModel
    html?:string
   
    constructor(json: any) {
        
        this.quickpay = _.get(json, 'quickpay')
        this.html = _.get(json, 'html')
      
    }

  
}

export default FormEmailModel