import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import RegularExpression from "utilities/RegularExpression";
import EditMerchantInfoRequest from "modules/account/merchant-info/APIRequest/EditMerchantInfoRequest";
import APIManager from "common/Manager/APIManager";
import EditContact from 'modules/account/merchant-info/component/EditContact';
import ChannelRequest from "modules/account/merchant-info/APIRequest/ChannelRequest";
import GetMerchantInfoByIdRequest from 'modules/account/merchant-info/APIRequest/GetMerchantInfoByIdRequest'
import BusinessTypeRequest from 'modules/register/APIRequest/BusinessTypeRequest';
import BankRequest from "modules/register/APIRequest/BankRequest";
import swal from "sweetalert";

let cselected = [];

const index = (props) => {


  const { contentTranslate } = props
  const [docData, setDoctData] = useState<any>();
  const [contactForm, setContactForm] = useState<any>([]);
  const [formEror, setFormEror] = useState([]);
  const [channel, setChannel] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [bank, setBank] = useState([]);

  const didMount = React.useRef(false);

  const validation = RegularExpression.default;

  useEffect(() => {
    getMerchantInfo();
    getChannel();
    getBusinessType();
    getBank();
  }, []);

  const getMerchantInfo = async () => {

    const apiRequest = new GetMerchantInfoByIdRequest();
    const items = await APIManager.default.fetch(apiRequest);
    const apiChannelRequest = new ChannelRequest('en');
    const channelList = await APIManager.default.fetch(apiChannelRequest);
    const {merchantData , documentData} = items.data.data
    cselected = merchantData.payment_method?.split(",")
   
    setContactForm(merchantData);
    setDoctData(documentData)

  };

  const getChannel = async () => {

    const apiRequest = new ChannelRequest('en');
    const channelList = await APIManager.default.fetch(apiRequest);
    setChannel(channelList.data.data)

  };

  const channelSelected = (cid) => {

    let find = cselected?.findIndex((v) => v  == cid);
    if (find > -1) {
      return true;
    }
    return false;

  };

  const handleCheckbox = (e) => {

    const { target } = e;
    var obj = {};
    const cid  = target.value;
    let find = cselected.findIndex((v) => v == cid);

    if (find > -1) {
          cselected.splice(find, 1);		
    } else {
          cselected.push(cid);	
    }

    obj[target.name] = cselected
    
    setContactForm({ ...contactForm, ...{ [target.name]: cselected } });

  };

  const handleInputChange = (e) => {

    const { target } = e;
    var obj = {};
    obj[target.name] = target.value;  
        
    if(target.name == 'contact_email' || target.name == 'support_email'){

      let emailKeyUpValidate = validation.emailKeyUpValidate(String(target.value));
      if (!emailKeyUpValidate) {
         return 0;         
      }

    }

    validateCondition();  
    setContactForm({ ...contactForm, ...{ [target.name]: target.value } });   
   

  };

  useEffect(() => {  

    if (!didMount.current) {
      didMount.current = true;
      return;
    }
     
    validateCondition();
    
    
 }, [contactForm]);

  const updateContact = async (e) => {
    
     e.preventDefault();  
    if(!validateCondition()){
      return false;
    }  
    
    const apiRequest = new EditMerchantInfoRequest(contactForm);
    const dataResponse = await APIManager.default.fetch(apiRequest)

    if(dataResponse){

        if (dataResponse.data.status == 200) {
          swal("Success!", {
            icon: "success",
          }).then(() => {
            window.location.href = "/account/merchant-info";
          });
        }
    }       
     
  };

  const validateCondition = () => {

    let errors = [];

    let validContactEmail = validation.emailValidate(String(contactForm?.contact_email));
    let validSupportEmail = validation.emailValidate(String(contactForm?.support_email));
  
    if (!validContactEmail || contactForm?.contact_email === "") {
      errors.push("contact_email");
    }
    else{

      const index = errors.indexOf("contact_email");

      if (index !== -1) {
        errors.splice(index, 1);
      }

    }

    if (!validSupportEmail || contactForm?.support_email === "") {
      errors.push("support_email");
    }
    else{

      const index = errors.indexOf("support_email");

      if (index !== -1) {
        errors.splice(index, 1);
      }

    }
      
    if (contactForm?.trading_name === "") {
      errors.push("trading_name");
    }
    else{

      const index = errors.indexOf("trading_name");

      if (index !== -1) {
        errors.splice(index, 1);
      }

    }

    if (contactForm?.address === "") {
      errors.push("address");
    }
    else{

      const index = errors.indexOf("address");

      if (index !== -1) {
        errors.splice(index, 1);
      }

    }

    const mobileValidate = validation.phoneNumberValidate(String(contactForm?.mobile));

    if (contactForm?.mobile === "" || !mobileValidate) {
      errors.push("mobile");
    }
    else{

      const index = errors.indexOf("mobile");

      if (index !== -1) {
        errors.splice(index, 1);
      }

    }

    const phoneValidate = validation.phoneNumberValidate(String(contactForm?.phone));
    if (contactForm?.phone === "" || !phoneValidate) {
      errors.push("phone");
    }
    else{

      const index = errors.indexOf("phone");

      if (index !== -1) {
        errors.splice(index, 1);
      }

    }

    const faxValidate = validation.phoneNumberValidate(String(contactForm?.fax));
    if (contactForm?.phone === "" || !faxValidate) {
      errors.push("fax");
    }
    else{

      const index = errors.indexOf("fax");

      if (index !== -1) {
        errors.splice(index, 1);
      }

    }
    
    if (contactForm?.contact_person === "") {
      errors.push("contact_person");
    }
    else{

      const index = errors.indexOf("contact_person");

      if (index !== -1) {
        errors.splice(index, 1);
      }

    }

    if(contactForm?.business_type === ""){     
        errors.push("business_type");     
    }
    else{

        const index = errors.indexOf("business_type");

        if (index !== -1) {
          errors.splice(index, 1);
        }

    }    

    if(contactForm?.bank === ""){     
        errors.push("bank");     
    }
    else{

        const index = errors.indexOf("bank");
        if (index !== -1) {
          errors.splice(index, 1);
        }

    }

    if(contactForm?.bank_account === ""){
        errors.push("bank_account");     
    }
    else{

        const index = errors.indexOf("bank_account");

        if (index !== -1) {
          errors.splice(index, 1);
        }      
      
    } 

    if(contactForm?.payment_method){         

      if(cselected.length == 0){
        errors.push("payment_method");     
      }
      else{

        const index = errors.indexOf("payment_method");

        if (index !== -1) {
          errors.splice(index, 1);
        }
      }
      
    } 

    setFormEror(errors);

    if (errors.length > 0) {
      return false;
    }

    return true

  }

  const getBusinessType = async () => {

    const apiRequest = new BusinessTypeRequest('th');
    const businessTypeList = await APIManager.default.fetch(apiRequest);
    setBusinessType(businessTypeList.data.data)

  };

  const getBank = async () => {

    const apiRequest = new BankRequest();
    const bankList = await APIManager.default.fetch(apiRequest);
    setBank(bankList.data.data)

  };

  const hasError = (key) => {   
    return formEror.indexOf(key) !== -1;
  };

  return (
  <>
    <EditContact	
      contactForm={contactForm}
     // setContactForm={setContactForm}
      hasError={hasError}
      updateContact={updateContact}
      handleInputChange={handleInputChange}
      channel={channel}
      formEror={formEror}
      channelSelected={channelSelected}
      handleCheckbox={handleCheckbox}
      businessType={businessType}
      contentTranslate={contentTranslate}
      bank={bank}
    >
    </EditContact>
  </>
  )


}

export default index;