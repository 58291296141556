import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import FilterModel from "common/models/FilterModel";
import { PaymentStatusPeriodEnum } from "common/enumerations/PaymentStatusPeriodEnum";
import PaymentStatusDatePeriodModel from "modules/dashboard/models/PaymentStatusDatePeriodModel";

class SearchSalesSettlementRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/dashboard/payment-status/get";
  period:PaymentStatusPeriodEnum
  paymentStatusDatePeriodModel:PaymentStatusDatePeriodModel;

  constructor(period: PaymentStatusPeriodEnum, paymentStatusDatePeriodModel : PaymentStatusDatePeriodModel) {
    this.period = period  
    this.paymentStatusDatePeriodModel = paymentStatusDatePeriodModel 
  }

  makeBody() {

    return {
      period : this.period,
      payment_status_date_period : this.paymentStatusDatePeriodModel
    }
  }

  makeQuery() {}
}

export default SearchSalesSettlementRequest;
