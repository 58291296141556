import { makeStyles } from '@material-ui/core/styles';


import styled from "styled-components";

const style = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),       
      },
    },
    appBar: {
      position: 'relative',
    },    
    instruction: {
      fontSize: '20px'  ,
      marginBottom: '18px'
    },
    instruction2: {   
        fontFamily: "DBHeaventRoundedMedv32",
      
    },
    paper2: {
      backgroundColor: theme.palette.background.paper,
      padding: "20px"
    },    
    img: {    
      verticalAlign: 'middle',
      marginBottom: '7px' 
      
    }
    
  
  

}));

export default style