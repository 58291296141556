import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "jsx/layouts/PageTitle";

import GetUsersRequest from "modules/account/user/APIRequest/GetUsersRequest";
import Users from "modules/account/user/component/Users";
import DeleteUserByIdRequest from "modules/account/user/APIRequest/DeleteUserByIdRequest";
import APIManager from "common/Manager/APIManager";
import swal from "sweetalert";
import { Link } from "react-router-dom";

const index = () => {

  const [userData, setUserData] = useState<any>([]);
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [active, setUserActive] = useState("");

  const [page, setPage] = useState("default");
  const [pageCount, setPageCount] = useState(0)
	const [perPage, setPerPage] = useState(5)
	const [totalPage, setTotalPage] = useState(0)
  
  const deleteById = (id:number) => {

    if(userData.findIndex(checkAdmin) !== -1){
      swal("", " Super Admin Cannot Delete", "error");
      return 0;
    }    

    function checkAdmin(x:any) {
      return x.id == id && x.is_superuser == 'y';
    }
   
    swal({
      title: "",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then((willDelete) => {

      if (willDelete) {

          const apiRequest = new DeleteUserByIdRequest(id);
         
           APIManager.default.fetch(apiRequest).then((items) => {

            swal("Successfull", {
              icon: "success",
            }).then(()=>{
              window.location.href = "/account/user";
            });

      
          });
      } 

    });
  };
    
  const getUser = async () => {

    const apiRequest = new GetUsersRequest({
      email: email,
      fullname: fullname,
      active: active,
    });

    const items = await APIManager.default.fetch(apiRequest);
    setUserData(items.data.data);
    setPageCount(items.data.data.length);

  };

  useEffect(() => {
    getUser();

  },[] );

  return (
    <Fragment>
      
      <PageTitle
        pageHeading=" "
        activeMenu="User And Role"
        motherMenu="User And Role"
      />

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 order-md-1 ">
                  <h4 className="mb-3">Search User</h4>
                  <form className="needs-validation">
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="firstName">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder=""
                          onChange={(e) => setEmail(e.target.value)}
                        />

                        <div className="invalid-feedback">
                          Please enter a valid email address is required.
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="fullname">Full name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fullname"
                          placeholder=""
                          required
                          onChange={(e) => setFullname(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          Valid fullname is required.
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="lastName">User status</label>
                        <Form.Control
                          as="select"
                          onChange={(e) => setUserActive(e.target.value)}
                        >
                          <option value="">All</option>
                          <option value="y">Active</option>
                          <option value="n">In Actice</option>
                        </Form.Control>

                        <div className="invalid-feedback">
                          Please provide a valid state.
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <a
                        className="btn btn-primary mb-1 mr-1"
                        onClick={(e) => getUser()}
                      >
                        Search User
                      </a>
                      <Link to="user/create">
                        <a
                          href="#"
                          className="btn btn-primary mb-1 ml-1 btn btn-primary"
                          role="button"
                        >
                          Create Users
                        </a>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          
      <Users data={userData} 					
        setPage={setPage}
        setPerPage={setPerPage}
        setTotalPage={setTotalPage}

        getData={getUser}

        page={page}
        perPage={perPage}
        countRow={pageCount}
        totalPage={totalPage}	 
        
        deleteById={deleteById}
        >

      </Users>

    </Fragment>
  );
};

export default index;
