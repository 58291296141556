import _ from "lodash";
import Axios, { AxiosRequestConfig } from "axios";
import APIResponse from "common/Manager/APIRespones";
import APIRequest from "common/interface/APIRequest";
import APIConfig from "common/Manager/API/APIConfig";
import { StatusCode } from "common/enumerations/StatusCodeEnum";
import swal from "sweetalert";
import InitializeManager from "./InitializeManager";
import qs from 'query-string'


const CancelToken = Axios.CancelToken;
const isClient = typeof window === 'object';


class APIManager {
  static default: APIManager = new APIManager();
  cancelTokens: (() => void)[] = [];
  ENV_CLIENT: any = {};

  private constructor() {}

  fetch(apiRequest: APIRequest, req: any = ''): Promise<APIResponse> {

    if (!isClient) {

    } else {
      APIConfig.default.setEnv(InitializeManager.default.get().data.ENV_CLIENT)
    }

    const options = this.createAxiosOptions(apiRequest);
  
    return new Promise((resolve, reject) => {
  
      Axios(options)
        .then(response => {
          
          const responseModel = new APIResponse(
            response.data,
            response.status == StatusCode.SUCCESS && _.get(response.data, "status") == StatusCode.SUCCESS
          );

          if(_.get(response.data, "status") == 401)  
          {             
            window.location.href = "/page-login";            
          }

          if(_.get(response.data, "status") == 408)  
          {
              swal("", response.data.message, "error")
              .then(()=>{
                window.location.href = "/page-login";
              })

          }

          if(_.get(response.data, "status") == 452)  
          {
              swal("", response.data.message, "error")
              .then(()=>{
                window.location.href = "/";
              })

          }

          if(_.get(response.data, "status") == 400)  
          {
              swal("", response.data.message, "error") 
          }
                
          resolve(responseModel);
        })
        .catch(err => {

          console.log(err);                 

          console.log("---------- axios error ---------- 2  :  ");
          swal("Oops", "Something went wrong!", "error")           
          if (Axios.isCancel(err)) {
            // console.log("REQUEST Cancelled", options);           
            console.log("REQUEST Cancelled");
          }
          
          if (err.response) {
            reject(new APIResponse(err.response.data, false));
          } else {
            reject(new APIResponse(err, false));
          }        

        });

    });
  }

  cancelAllRequests() {
    this.cancelTokens.forEach(x => x());
    this.cancelTokens = [];
  }

  private createAxiosOptions(apiRequest: APIRequest): AxiosRequestConfig {

    const  API_URL = APIConfig.default.getBaseUrl() + apiRequest.url


    const parsed_api_url = qs.parseUrl(API_URL);      
    parsed_api_url.query['email'] = sessionStorage.getItem("email");
    let url = qs.stringifyUrl(parsed_api_url)



    return {
      baseURL: APIConfig.default.getBaseUrl(),
      url: url,
      headers: apiRequest.makeHeader
        ? apiRequest.makeHeader()
        : APIConfig.default.getRequestHeaders(),
      cancelToken: new CancelToken(c => this.cancelTokens.push(c)),
      method: apiRequest.method,
      data: apiRequest.makeBody(),
      params: apiRequest.makeQuery()
    };
  }
}

export default APIManager;
