import React from 'react';
import _ from 'lodash';
import {  createStyles, makeStyles, Box } from '@material-ui/core';
import styled, { css } from "styled-components";


interface Iprop {
    disabled: boolean,
    amount_not_fee :number
}


export const BtnSubmit = styled('button')`

  border: none;
  box-shadow: none;
 
  padding: 12px 16px;
  min-width: 64px;
  font-family: DBHeaventRoundedmedv32; 
  font-size:23px;
  border: none;
  box-shadow: none;
  border-radius: 4px;
  width: 100%;
  ${props =>
    props.disabled ?
    css`
      color: rgba(0, 0, 0, 0.26);     
      background-color: rgba(0, 0, 0, 0.12);
    `:  css`
    color: #ffffff;   
    cursor:pointer;
    background-image: linear-gradient(45deg, #00bfd9 , #40e9fe);
    `};

  `

  
export const BtnRegister = styled('button')` 

border: none;
box-shadow: none;

padding-left:21px;

background: #0EBFD8;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
border-radius: 100px;

font-family: 'NotoSansSemiBold','NotoSansSemiBoldThai';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 14px;
text-align: center;
width:130px;
height:40px;
color: #FFFFFF;

cursor: pointer;

`
export const BtnLogin = styled('button')` 

font-family:  'NotoSansSemiBold','NotoSansSemiBoldThai';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 14px;
text-align: center;
color: #3E425B;

border: none;
box-shadow: none;

padding-left:21px;

width: 130px;
height : 40px;

top: 24px;
left :958px;

background: #FFFFFF;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
border-radius: 100px;

cursor: pointer;


`
export const BtnSubmitRegister = styled.button`

  font-family:  'NotoSansSemiBold','NotoSansSemiBoldThai';
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;

  width: 400px;
  height: 60px;
  background: #3E425B;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: white;
  cursor: pointer;

  
@media only screen and (max-width: 768px) {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  width: 100%;
  height: 50px;
}

`