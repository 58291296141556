import React,{ useEffect, useState, useRef} from 'react';

import SearchTransactionRequest from "modules/transaction/creditcard/APIRequest/SearchTransactionRequest";
import CountTransactionRequest from "modules/transaction/creditcard/APIRequest/CountTransactionRequest";
import APIManager from "common/Manager/APIManager";
import FilterModel from "common/models/FilterModel";
import Transaction from "modules/transaction/creditcard/component/Transaction"
import {endDateISO, startDateISO} from 'common/Manager/Helper'


const index = (props: any) => {	

	const [systemRef, setSystemRef] = useState("")
	const [orderRef, setOrderRef] = useState("")
	const [cardNo, setCardNo] = useState("")
	const [cardHolder, setCardHolder] = useState("")
	const [status, setStatus] = useState(0)
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [creditPayType, setCreditPayType] = useState(0)

	const [pageCount, setPageCount] = useState(0)
	const [page, setPage] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [totalPage, setTotalPage] = useState(0)

	const [sort, setSort] =  useState({	
			field : 'transaction_date',
			sortby: 'DESC'  
	})

	const [productName, setProductName] = useState("")
	
	const [transaction, setTransaction] = useState([])
	const getTransaction = async (current_page = page, per_page = perPage, sort_data = sort) => {
			
		const filterModel = new	FilterModel(
		{
			system_ref : systemRef,
			order_ref : orderRef,
			status : status,	
			start_date: startDate,
			end_date: endDate,
			credit_paytype : creditPayType,
			product_name: productName,
			card_no :cardNo ,
			card_holder :cardHolder,
			page: current_page,
			pagesize: per_page,	
			sort: sort_data,		
			orderdirection: "DESC"		
		})

		const apiRequest = new SearchTransactionRequest(filterModel);
		const countApiRequest = new CountTransactionRequest(filterModel);
	
		const items = await APIManager.default.fetch(apiRequest);				
		const count = await APIManager.default.fetch(countApiRequest);

		setTransaction(items.data.data);	
		setPageCount(count.data.data)
		setTotalPage(Math.ceil(count.data.data / perPage))	

	};
		
	useEffect( () => {
		getTransaction(page, perPage);	
	}, [])

	const handleDateChange = (st, end) =>{

		if(st){				
			setStartDate(startDateISO(st))
		}

		if(end){
			setEndDate(endDateISO(end))
		}

	}

	const onView = (id:any) =>{
		window.location.href = `/transaction/creditcard/${id}`;
	}

  	return (
		<>
				<Transaction
					setSystemRef={setSystemRef}
					setOrderRef={setOrderRef}		
					setStatus={setStatus}
					handleDateChange={handleDateChange} 
					getTransaction={getTransaction}
					setCreditPayType={setCreditPayType}
					setProductName={setProductName}
					setCardHolder={setCardHolder}
					setCardNo={setCardNo}

					data={transaction}
					key={1}		

					setSort={setSort}

					setPage={setPage}
					setPerPage={setPerPage}
					setTotalPage={setTotalPage}

					page={page}
					perPage={perPage}
					countRow={pageCount}
					totalPage={totalPage}	
					onView={onView}			
				>

				</Transaction>

    </>
  	)


}

export default index;