import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import MerchantTempModel from "modules/register/models/MerchantTempModel";



class CreateMerchantTemp {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/register/create-merchant-temp";
  merchant_temp: MerchantTempModel
  locale:any = 'en'
  token: string
  id: string
  formdata:any

  constructor(formData:any ) {
    this.formdata = formData 
  }
  
  makeBody() {
    return  this.formdata
  }

  makeHeader() {
    return { "Content-Type": "multipart/form-data" };
  }

  makeQuery() {}

}

export default CreateMerchantTemp;


