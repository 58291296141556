import React, { useState, useEffect } from 'react'
import { css, styled, setup } from 'goober'
import MerchantInfoHook from 'modules/register/Hook/RegisterHook';
import RegularExpression from "utilities/RegularExpression";


setup(React.createElement)

const Ol = styled('ol')`
  margin: 0;
  padding-bottom: 2.2rem;
  list-style-type: none;
`
const Li = styled('li')`
  display: inline-block;
  text-align: center;
  line-height: 4.8rem;
  padding: 0 0.7rem;
  cursor: pointer;

  color: silver;
  border-bottom: 2px solid silver;

  &:hover,
  &:before {
    color: #0FA0CE;
  }
  &:after {
    content: "\\00a0\\00a0";
  }   
  span {
    padding: 0 1.5rem;
  }
  &:before {
    position: relative;
    float: left;
    left: 50%;
    width: 1.2em;
    line-height: 1.4em;
    border-radius: 0;
    bottom: -3.99rem;
  }
`
const Todo = css`
  &:before {
    content: "\u039F";
    color: silver;
    background-color: white;
  }
`
const Doing = css`
  &:before {
    content: "\u2022";
    color: white;
    background-color: #33C3F0;  
  }
`
const Done = css`
  &:before {
    content: "\u2713";
    color: white;
    background-color: #33C3F0;
  }
`

const getStep = (defaultIndex, newIndex, length) => {
    if(newIndex <=  length){
        return newIndex;
    }
    return defaultIndex;
}

const getTopNavStyles = (indx, length) => {
  const styles = []
  for (let i = 0; i < length; i++) {
    if (i < indx) {
      styles.push('done')
    } else if (i === indx) {
      styles.push('doing')
    } else {
      styles.push('todo')
    }
  }
  return styles
}

const getButtonsState = (indx, length) => {
  if (indx > 0 && indx < length - 1) {
    return {
      showPreviousBtn: true,
      showNextBtn: true
    }
  } else if (indx === 0) {
    return {
      showPreviousBtn: false,
      showNextBtn: true
    }
  } else {
    return {
      //  showPreviousBtn: true,
      showPreviousBtn: false,
      showNextBtn: false
    }
  }
}

let isSubmit = false;

export default function MultiStep (props) {

  let errors = [];
  let maxStep = 3;
  const validation = RegularExpression.default;
  const { activeComponentClassName, inactiveComponentClassName, createMerchantTemp,
    translate, validateAllInput
  } = props

  const showNav =
    typeof props.showNavigation === 'undefined' ? true : props.showNavigation

  const [activeStep] = useState(getStep(0, props.activeStep,  props.steps.length));
  const [stylesState, setStyles] = useState(getTopNavStyles(activeStep, props.steps.length))
  const [compState, setComp] = useState(activeStep)
  const [buttonsState, setButtons] = useState(getButtonsState(activeStep, props.steps.length))

  useEffect(() => {
    setStepState(props.activeStep);
  }, [props.activeStep]);
  
  const setStepState = (indx) => {
      
    setStyles(getTopNavStyles(indx, props.steps.length))
    setComp(indx < props.steps.length ? indx : compState)
    setButtons(getButtonsState(indx, props.steps.length))

    if(indx == 3 && isSubmit == false) {

      createMerchantTemp()
      isSubmit = true;

    }

  }

  const next = () => { 

    if(!validateAllInput(compState)){
      return 0;
    }

    
    if(isSubmit){
      return 0;
    }
   
    setStepState(compState + 1) 

  }
  
  const previous = () => setStepState(compState > 0 ? compState - 1 : compState)

  const handleOnClick = evt => {

    if(isSubmit){
      return 0;
    }
   
    if(compState < evt.currentTarget.value)
    {

      for(let n = compState; n < evt.currentTarget.value; n++)
      {
        if(!validateAllInput(n)){
          return 0;
        }
      }
    }

    if (
      evt.currentTarget.value === props.steps.length - 1 &&
      compState === props.steps.length - 1
    ) {
      setStepState(props.steps.length)
    } else {
      setStepState(evt.currentTarget.value)
    }
  }

  const renderSteps = () =>
    props.steps.map((s, i) => {
      if (stylesState[i] === 'todo') {
        return (
          <Li
            className={Todo}
            onClick={handleOnClick}
            key={i}
            value={i}
          >
            <span>{i + 1 }</span>
          </Li>
        )
      } else if (stylesState[i] === 'doing') {
        return (
          <Li
            className={"go2478912419"}
            onClick={handleOnClick}
            key={i}
            value={i}
          >
            <span>{i + 1} </span>
          </Li>
        )
      } else {
        return (
          <Li
            className={'go767851704'}
            onClick={handleOnClick}
            key={i}
            value={i}
          >
            <span>{i + 1}</span>
          </Li>
        )
      }
  })

  const renderNav = (show) =>
    show && (
      <div> 
        <button
          style={buttonsState.showPreviousBtn ? props.prevStyle : { display: 'none' }}
          onClick={previous}
        >
          {translate['regis.step.pre']}
        </button>

        <button
          style={buttonsState.showNextBtn ? props.nextStyle : { display: 'none' }}
          onClick={next}
        >
          {translate['regis.step.next']}
        </button>

      </div>
  )

  return (
    <div>
      <Ol>{renderSteps()}</Ol>
      {inactiveComponentClassName
        ? props.steps.map((step, index) => {
            const className = index === compState ? activeComponentClassName : inactiveComponentClassName
            return (<div className={className} key={index}>{step.component}</div>)
          })
        : <div>{props.steps[compState].component}
        </div>}
      <div>{renderNav(showNav)}</div>
    </div>
  )
}
