import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";



class QuickpayDetailRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/quickpay/detail";
  param:string
 

  constructor(param: string) {
    this.param = param   
  }

  makeBody() {

    return {
         id : this.param
    }
  }

  makeQuery() {}
}

export default QuickpayDetailRequest;
