import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'
import APIRequest from 'common/interface/APIRequest'
import { serverConfig } from 'common/Config/ServerConfig';
import SearchUser from 'common/interface/SearchUser';
import MerchantModel from 'modules/account/merchant-info/models/MerchantModel';


class EditMerchantInfoRequest  {

  method: HTTPMethodEnum = HTTPMethodEnum.POST
  url: string = '/account/merchant-info/update-contact'
  param: MerchantModel
  
  constructor (param :MerchantModel) {
    this.param = param
  }

  makeBody() { 
    return this.param 
    
  }
  makeQuery() {}
  makeHeader() {}

}

export default EditMerchantInfoRequest