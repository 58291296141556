import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'
import LoginInterface from 'common/interface/LoginInterface';


class GetMenuListRequest  {

  method: HTTPMethodEnum = HTTPMethodEnum.POST
  url: string = '/account/user/getmenu-role'
  param: LoginInterface
  
  constructor () {  
  }

  makeBody() { 
  }
  
  makeQuery() { }

  makeHeader() { }

}

export default GetMenuListRequest