import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { serverConfig } from "common/Config/ServerConfig";
import APIManager from "common/Manager/APIManager";
import swal from "sweetalert";
import ResetRequest from "modules/password/forget/APIRequest/ResetRequest";
import CreatePasswordRequest from "common/APIRequests/CreatePasswordRequest";
import RegularExpression from "utilities/RegularExpression";
import InitializeManager from "common/Manager/InitializeManager";


const isClient = typeof window === "object";

const CreateAccount = ({ history }) => {
  const [confirmpassword, setConfirmpassword] = useState();
  const search = useLocation().search;
  const vali = RegularExpression.default;
  const [form, setForm] = useState({
    password: "",
    confirmpassword: "",
  });
  const [formEror, setFormEror] = useState([]);

  const [checkList, setCheckList] = useState({
    is_eight : false,
    is_upper : false,
    is_lower : false,
    is_numberic : false,
    is_special : false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleEyePasswordClick = () => {

    if(showPassword == false){
      setShowPassword(true)
    }else{
      setShowPassword(false)
    }

  };

  const handleEyeConfirmPasswordClick = () => {

      if(showConfirmPassword == false){
          setShowConfirmPassword(true)
      }else{
          setShowConfirmPassword(false)
      }

  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let errors = [];
    const token = new URLSearchParams(search).get("token");

    if (form.password == "") {
      swal("", " Please fill you password", "error");
      errors.push("password");
      return false;
    }

    if (form.confirmpassword == "") {
      swal("", " Please fill you confirm password", "error");
      errors.push("confirmpassword");
      return false;
    }

    if (form.password != form.confirmpassword) {
      swal("", " Password and confirm password not match", "error");
      errors.push("confirmpassword");
      return false;
    }

    if( checkList.is_eight == false || checkList.is_upper == false
       || checkList.is_lower == false || checkList.is_numberic == false || checkList.is_special == false )
    {
      errors.push("validatepassword");
    } 

    setFormEror(errors);

    if (errors.length > 0) {
      return false;
    }

    const apiRequest = new CreatePasswordRequest({
      newpassword: form.password,
      token: token,
    });

    const items = await APIManager.default.fetch(apiRequest);

      if(items.data.data.status == 400){

        swal(
          items.data.data.message,
          // 'Something went wrong!',
          'error'
        )


      }
      else{

        swal("success", {
          icon: "success",
        }).then(() => {
          window.location.href = "/page-login";
        });
      }


    
  };

  const handleInputChange = (e) => {

    e.preventDefault();
    const { target } = e;
    var obj = {};

    if(!vali.passwordCaseThai(target.value, false)){

      obj[target.name] = target.value;
      setForm({ ...form, ...{ [target.name]: target.value } });

    }

    if(target.name == "password"){
      checkList.is_lower = vali.validPassLowercase(target.value, false)
      checkList.is_upper = vali.validPassUppercase(target.value, false)
      checkList.is_numberic = vali.validPassNumberic(target.value, false)      
      checkList.is_special = vali.validPassSpecialchar(target.value, false)
      checkList.is_eight = (target.value.length > 7) ? true : false 
    }
    

  };

  const hasError = (key) => {
    return formEror.indexOf(key) !== -1;
  };

  let ENV_CLIENT: any = {};

  if (!isClient) {
    ENV_CLIENT = JSON.parse(InitializeManager.default.get()).data?.ENV_CLIENT;
  } else {
    ENV_CLIENT = InitializeManager.default.get().data.ENV_CLIENT;
  }
  
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={`${ENV_CLIENT['MERCHANT_BACKOFFICE_ASSET']}/images/sabuypay_logo_login.svg`} width="60%" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 text-white">
                      Create Password
                    </h4>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group">
                        <label className="text-white">
                          <strong>Password</strong>
                        </label>

                        <div style={{display:"flex"}} >  
                            <input
                              type={`${showPassword ? "text" : "password" }`}
                              className={
                                hasError("password")
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              id="password"
                              name="password"
                              value={form.password}
                              onChange={(e) => handleInputChange(e)}
                            />

                            <div   
                              style={{
                                  cursor: 'pointer',
                                  display: 'flex',
                                  alignItems: 'center'
                                  }}                          
                              >
                              <span 
                                  
                                  onClick={() => handleEyePasswordClick() }                                        
                                  style={{
                                      marginLeft: '-35px',
                                      zIndex: 1,
                                      cursor: 'pointer'                                        
                                      }}
                                  >
                                    {showPassword  && (<i className="fa fa-eye" style={{display:'block'}}></i>)}   
                                    {!showPassword  && ( <i className="fa fa-eye-slash"> </i>  )} 
                              </span>
                            </div>

                        </div>         

                        <div className="invalid-feedback">
                          Confirm email is required or not match email field.
                        </div>                     
                      </div>

                      <div className="form-group">
                        <label className="text-white">
                          <strong>Confirm Password</strong>
                        </label>

                        <div style={{display:"flex"}} >  
                            <input
                               type={`${showConfirmPassword ? "text" : "password" }`}
                              className={
                                hasError("confirmpassword")
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              id="confirmpassword"
                              name="confirmpassword"
                              onChange={(e) => handleInputChange(e)}
                              onPaste={(e)=>{
                                e.preventDefault()
                                return false;
                              }} 
                              onCopy={(e)=>{
                                e.preventDefault()
                                return false;
                              }} 
                              value={form.confirmpassword}
                            />

                              <div   
                                  style={{
                                      cursor: 'pointer',
                                      display: 'flex',
                                      alignItems: 'center'
                                      }}                          
                              >
                                  <span 
                                      
                                      onClick={() => handleEyeConfirmPasswordClick() }                                        
                                      style={{
                                          marginLeft: '-35px',
                                          zIndex: 1,
                                          cursor: 'pointer'                                        
                                          }}
                                      >
                                        {showConfirmPassword  && (<i className="fa fa-eye" style={{display:'block'}}></i>)}   
                                        {!showConfirmPassword  && ( <i className="fa fa-eye-slash"> </i>  )} 
                                  </span>
                            </div>

                        </div>


                        <div className="invalid-feedback">
                          Confirm email is required or not match email field.
                        </div>

                        <div
                            role="alert"
                            className="fade alert-primary notification show mt-3 reset-password-text " 
                          >
                          <div role="alert" className="show" >
                            <div className="media">
                              <div className="media-body">
                                <div className="form-group">

                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="check1"
                                      checked={checkList.is_eight}
                                    />
                                    <label className="form-check-label" htmlFor="check1">
                                    Password must at least 8 character.
                                    </label>
                                  </div>

                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="check2"
                                      checked={checkList.is_upper}
                                    />
                                    <label className="form-check-label" htmlFor="check2">
                                    Password must contain at least 1 uppercase letter.
                                    </label>
                                  </div>

                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="check2"
                                      checked={checkList.is_lower}
                                    />
                                    <label className="form-check-label" htmlFor="check2">
                                    Password must contain at least 1 lowercase letter.
                                    </label>
                                  </div>

                                  <div className="form-check disabled">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="check3"
                                      checked={checkList.is_numberic}
                                    />
                                    <label className="form-check-label" htmlFor="check3">
                                      Password must contain at least 1 numberic character.
                                    </label>
                                  </div>

                                  <div className="form-check disabled">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="check3"
                                      checked={checkList.is_special}
                                    />
                                    <label className="form-check-label" htmlFor="check3">
                                      Password must contain at least 1 special character.
                                    </label>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                            
                        </div>

                      </div>

                      <div className="text-center mt-2">
                        <input
                          type="submit"
                          value="SUBMIT"
                          className="btn bg-white text-primary btn-block"
                        />
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
