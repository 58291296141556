import React, { useState } from "react";
import { isImage, isImagePdf } from "common/Manager/Helper";

 interface DimensionType {
  naturalHeight :number;
  naturalWidth  :number;
}


const StepTwo = (prop) => {


  const { step1,step2, hasError, handleInputChange, 
      handleChangeFile, setStep2, setUpdLogoStatus,updLogoStatus,
     updFileStatus, translate, doc ,handleChangeFileLogo ,fileLogoUpload,
     setFileLogoUpload

        } 
     = prop;
  
  let imgEl :any;

  return (
    <section>
      <div className="row">

        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <div className="img-preview text-center">

             {fileLogoUpload && 
              (
              <>
                <img
                  src={fileLogoUpload}
                  ref={el => imgEl = el}
                  onLoad={() => {   
                      if(imgEl.naturalWidth > 200 || imgEl.naturalHeight > 100 ) {  

                                 
                        updLogoStatus.uploadMessage = translate['regis.fileupload.dimension.error'];
                        updLogoStatus.uploadValid = false;
                        setUpdLogoStatus({ ...updLogoStatus });
                      }
                    }                  
                  }
                  width="200"
                  height="100"
                  alt=""
                />
              </>)}             
            </div>
           
            <div className="text-center mt-4 mb-4 fs-12">             
              {translate['regis.upload.logo.detail']}
            </div>
          
            <div className="mb-2">
         
              <label className="text-label">{translate['regis.step2.logo']}</label>
              <div className="basic-form custom_file_input">

                <form action="#">
                  <div className="input-group"><div className="custom-file">
                    <input type="file"   
                      name="logo"
                      onChange={handleChangeFileLogo}
                      className={
                        hasError("support_email")
                          ? "custom-file-input is-invalid"
                          : "custom-file-input"
                      }
                      />
                    <label className="custom-file-label"></label></div>
                  </div>
                </form>

                { updLogoStatus.uploadValid == true && 
                  (<div className="success-upload-img">
                      {updLogoStatus.uploadMessage}
                  </div>
                  )
                }

                { updLogoStatus?.uploadValid == false && 
                  (<div className="error-upload-img">
                      {updLogoStatus?.uploadMessage}
                   </div>
                  )
                }

              </div>
            </div>

          </div>
          
          {doc.map((d,i) =>  
            ( 
              (d.type == step1.person_type) &&

              (<div className="form-group">    

                <div className="mb-2" >
                  <label className="text-label">{translate[`${d.key_label}`]}</label>

                  <div className="basic-form custom_file_input">
                    <form action="#">
                      <div className="input-group"><div className="custom-file">
                      <input type="file" 
                      id={d.code}
                      name={d.name}                        
                      className="custom-file-input"
                      onChange={ (e) => handleChangeFile(e,i)}
                      />
                      <label className="custom-file-label"></label>
                      
                      </div></div>
                    </form>
                  
                    { updFileStatus[i]?.uploadValid == false && 
                      (<div className="error-upload-img">
                          {updFileStatus[i]?.uploadMessage}
                      </div>
                      )
                    }
  
                    { updFileStatus[i]?.uploadValid == true && 
                      (<div className="success-upload-img">
                          {updFileStatus[i]?.uploadMessage}
                      </div>
                      )
                    }
                  </div>

                  <div className="invalid-feedback text-left">
                    {d.name} is required.
                  </div>
                </div>

              </div>)
           )
          )}
        </div>

        <div className="col-lg-6 mb-2">

            <div className="mb-2"> 
              <div className="form-group">
                <label className="text-label">{translate['regis.step2.website']}</label>
                <input
                  type='text'
                  name='website'
                  className={
                    hasError("website")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder=''
                  value={step2.website}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="mb-2">       
              <div className="form-group mb-2">
                <label className="text-label">{translate['regis.step2.facebook']}</label>
                <input
                  type='text'
                  name='facebook'
                  className={
                    hasError("facebook")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder=''
                  value={step2.facebook}
                  onChange={handleInputChange}
                />
                
              </div>
            </div>

            <div className="mb-2">      
              <div className="form-group">
                <label className="text-label">{translate['regis.step2.instagram']}</label>
                <input
                  type='text'
                  name='instagram'
                  className={
                    hasError("instagram")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder=''
                  value={step2.instagram}
                  onChange={handleInputChange}
                />
              </div>
            </div>  
            
            <div className="mb-2">          
              <div className="form-group">
                <label className="text-label">{translate['regis.step2.line']}</label>
                <input 
                  type="text" 
                  name="line"
                  className={
                    hasError("line")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder=''
                  value={step2.line}
                  onChange={handleInputChange}
                />
              </div>
            </div>
           

        </div>

        
      </div>
    </section>
  );
};

export default StepTwo;

