import _ from 'lodash'
import { getModuleAssetsUrl } from 'common/Config/APIConfig';


class ImageManager {
  static default: ImageManager = new ImageManager()

  private constructor() {   
  }

  images = {    
    common: {    
      flag: {
        TH: (getModuleAssetsUrl('common') + '/flag/TH.svg'),
        EN: (getModuleAssetsUrl('common') + '/flag/EN.svg'),
      },
      creditCardIcons: {
        visa: (getModuleAssetsUrl('common') + '/payment-methods/credit-card-icons/visa.png'),
        masterCard: (getModuleAssetsUrl('common') + '/payment-methods/credit-card-icons/mastercard.png'),
        amax: (getModuleAssetsUrl('common') + '/payment-methods/credit-card-icons/amax.png'),
      },
     
      emailWhite: (getModuleAssetsUrl('common') + '/payment-methods/email-white.png'),
      crown: (getModuleAssetsUrl('common') + '/crown.png'),
      header: {
        LogoImage: (getModuleAssetsUrl('template') + '/ttc-logo.svg'),
        DownloadApp: (getModuleAssetsUrl('template') + '/download.svg'),
        LineIcon: (getModuleAssetsUrl('template') + '/line-color.svg'),
        FBIcon: (getModuleAssetsUrl('template') + '/facebook-color.svg'),
        IGIcon: (getModuleAssetsUrl('template') + '/instagram-color.svg'),
        Avatar: (getModuleAssetsUrl('template') + '/Bright.jpg'),
        ThaiFlag: (getModuleAssetsUrl('template') + '/thailand-flag.svg'),
        currencyIcon: (getModuleAssetsUrl('template') + '/currency.svg'),
      },
      footerImage: (getModuleAssetsUrl('common') + '/footer-image.png'),
      footer: {
        bgfooter: (getModuleAssetsUrl('template') + '/kanok.svg'),
        newsletter: (getModuleAssetsUrl('template') + '/3737.png'),
        appstoreImage: (getModuleAssetsUrl('template') + '/appstore.svg'),
        playstoreImage: (getModuleAssetsUrl('template') + '/googleplay.svg'),
        socialIcon: {
          facebookIcon: (getModuleAssetsUrl('template') + '/facebook.svg'),
          twitterIcon: (getModuleAssetsUrl('template') + '/twitter.svg'),
          youtubeIcon: (getModuleAssetsUrl('template') + '/youtube.svg'),
          instagramIcon: (getModuleAssetsUrl('template') + '/instagram.svg'),
        }
      },
      lineShared: (getModuleAssetsUrl('template') + '/line-shared-color.svg'),
      pdf: (getModuleAssetsUrl('template') + '/pdf.svg'),
      printer: (getModuleAssetsUrl('template') + '/printer.svg'),
      email: (getModuleAssetsUrl('template') + '/email.svg'),
      shared: (getModuleAssetsUrl('template') + '/share.svg'),
      map: (getModuleAssetsUrl('template') + '/map-point.svg'),

    },   
    sabuypay: {
      icon: {
        logo: (getModuleAssetsUrl('sabuypay') + '/icons/SabuyPay-logo.svg'),
      
      },
      security: (getModuleAssetsUrl('sabuypay') + '/security.svg'),
      logoheader: (getModuleAssetsUrl('sabuypay') + '/bg-sabuypay.jpg'),
      logoheader_mobile: (getModuleAssetsUrl('sabuypay') + '/bg-sabuypay-mobile.jpg'),
      logofooter: (getModuleAssetsUrl('sabuypay') + '/gdsv.png'),
      pcidss: (getModuleAssetsUrl('sabuypay') + '/pci-dss.png'),
      ssl: (getModuleAssetsUrl('sabuypay') + '/ssl.png'),
      successimg: (getModuleAssetsUrl('sabuypay') + '/success-img.jpg'),
      deniedimg: (getModuleAssetsUrl('sabuypay') + '/denied-img.jpg'),
      printer: (getModuleAssetsUrl('sabuypay') + '/printer.svg'),
      reference: (getModuleAssetsUrl('sabuypay') + '/reference.svg'),
      scancode: (getModuleAssetsUrl('sabuypay') + '/scan-code.svg'),
      hourglass: (getModuleAssetsUrl('sabuypay') + '/hourglass.png'),   
      jcb_icon: (getModuleAssetsUrl('sabuypay') + '/creditcard/jcb_icon.svg'),   
      mastercard_icon: (getModuleAssetsUrl('sabuypay') + '/creditcard/mastercard_icon.svg'),   
      visa_icon: (getModuleAssetsUrl('sabuypay') + '/creditcard/visa_icon.svg'),   
      union_icon: (getModuleAssetsUrl('sabuypay') + '/creditcard/union.png'),   
      thaiqr_logo: (getModuleAssetsUrl('sabuypay') + '/thaiqr/thaiqr_logo.png'),    
      thai_qr_image: (getModuleAssetsUrl('sabuypay') + '/thaiqr/thai-qr-image.png'), 
      promptpay_logo: (getModuleAssetsUrl('sabuypay') + '/thaiqr/promptpay-logo.png'),   
      payment_method: (getModuleAssetsUrl('sabuypay') + '/payment_method.png'),  
      flag: {
        TH: (getModuleAssetsUrl('sabuypay') + '/flag/th.gif'),
        EN: (getModuleAssetsUrl('sabuypay') + '/flag/us.gif'),
      },  

      register :{
        flag: {
          TH: (getModuleAssetsUrl('sabuypay') + '/register/flag_th.png'),
          EN: (getModuleAssetsUrl('sabuypay') + '/register/flag_en.png'),
        },  
        register_icon :(getModuleAssetsUrl('sabuypay') + '/register/register_icon.png'),
        person_outline_24dp :(getModuleAssetsUrl('sabuypay') + '/register/person_outline_24dp.png'),
        bg_footer :(getModuleAssetsUrl('sabuypay') + '/register/bg_footer.png'),
        payment_solution :(getModuleAssetsUrl('sabuypay') + '/register/payment_solution5.png'),
        payment_solution2 :(getModuleAssetsUrl('sabuypay') + '/register/payment_solution2.jpeg'),


        bg_formregister2 :(getModuleAssetsUrl('sabuypay') + '/register/bg_formregister2.png'),
  
        letter :(getModuleAssetsUrl('sabuypay') + '/register/letter.png'),
        lock :(getModuleAssetsUrl('sabuypay') + '/register/lock.png'),
        letter_m :(getModuleAssetsUrl('sabuypay') + '/register/letter_m.png'),
        lock_m :(getModuleAssetsUrl('sabuypay') + '/register/lock_m.png'),
        icon_submit :(getModuleAssetsUrl('sabuypay') + '/register/icon_submit.png'),
        sabuypay_log_horizontal :(getModuleAssetsUrl('sabuypay') + '/register/sabuypay-log-horizontal.png'),
        sabuypay_log_horizontal_mobile :(getModuleAssetsUrl('sabuypay') + '/register/sabuypay-log-horizontal-mobile.png'),
      
        border_right :(getModuleAssetsUrl('sabuypay') + '/register/border_right.png'),
        expand_more :(getModuleAssetsUrl('sabuypay') + '/register/expand_more_18dp.png'),
        pcidss :(getModuleAssetsUrl('sabuypay') + '/register/pcidss.png'),
        bars :(getModuleAssetsUrl('sabuypay') + '/register/bars.png'),

        outline_close_black_24dp :(getModuleAssetsUrl('sabuypay') + '/register/outline_close_black_24dp.png'),

        app_registration_black_18dp :(getModuleAssetsUrl('sabuypay') + '/register/app_registration_black_18dp.svg'),
        person_black_18dp :(getModuleAssetsUrl('sabuypay') + '/register/person_black_18dp.svg'),
        call_black_18dp :(getModuleAssetsUrl('sabuypay') + '/register/call_black_18dp.svg')
         
        
        
        
      
      }  
      

    },




  }


}

export default ImageManager