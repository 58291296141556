import APIRequest from 'common/Manager/interface/APIRequest';
import { HTTPMethodEnum } from 'common/Manager/Enumeration/HTTPMethodEnum';
import { serverConfig } from 'common/Config/ServerConfig';

class LabelTranslationAPIRequest implements APIRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.GET;
  url: string = `${serverConfig.WEB_LABEL_ENDPOINT}/api/backoffice-web-configs`;
  param: any;
  jwt:string;
  

  constructor(jwt :string) {
    this.jwt =  jwt
  }

  makeQuery() {
    return {};
  }

  makeHeader() {
    return {
      'Authorization': `Bearer ${ this.jwt}` 
    };
  }

  makeBody() {
   
  }
}

export default LabelTranslationAPIRequest;
