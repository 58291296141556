import React from 'react';
import _ from 'lodash';
import {  createStyles, makeStyles, Box } from '@material-ui/core';
import styled, { css } from "styled-components";
import ImageManager from 'common/Manager/ImageManager';


interface Iprop {
  //background: string,
  bars:string
}



const Navbar = styled.div<Iprop>`

font-family: 'NotoSansRegular','NotoSansRegularThai'; 
height: 300px; 
width:100%;
position: fixed; 
z-index: 4; 
top: 70px; 
left: 0;
overflow-x: hidden; 
padding-top: 30px; 
padding-bottom: 30px; 
transition: 0.5s; 
background: #ffffff none repeat scroll 0 0;
display: ${Iprop => Iprop.bars};
margin-bottom: 30px;

a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}
  
a:hover {
    color: #0fbed7;
}
  
.closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

ul {
    float: none !important;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0px;
}

li {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: block;
}

ul > li > a {
    font-size: 15px;
    letter-spacing: normal;
    text-transform: none;
    font-weight: 600;
    color: #3e425b;

    display: block;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 13px 0 !important;
    border-top: solid 1px #e0e0e0;
    margin-bottom: -1px;

}

img {
    vertical-align:middle;
    margin-right:8px;
}
  

@media screen and (max-height: 450px) {
.sidenav {padding-top: 15px;}
.sidenav a {font-size: 18px;}
}
 
`;

export default Navbar



