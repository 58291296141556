import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'
import UserModel from 'modules/account/user/models/UserModel';


class GetUsersRequest  {

  method: HTTPMethodEnum = HTTPMethodEnum.POST
  url: string = '/account/user/create'
  param: UserModel
  
  constructor (param :UserModel) {
    this.param = param
  }

  makeBody() { 
    return this.param 
    
  }
  makeQuery() {}
  makeHeader() {}

}

export default GetUsersRequest