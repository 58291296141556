import React from "react";
import { Pie } from "react-chartjs-2";

const PieCustom = ({list_labels, list_datas, list_colors}) => {

 

      


   const data = {
      datasets: [
         {
            data: list_datas,
            // borderWidth: 0,
            backgroundColor: list_colors,
            // hoverBackgroundColor: [
            //    `${color1 ? color1 : "rgba(30, 170, 231, 0.9)"}`,
            //    `${color2 ? color2 : "rgba(89, 59, 219, 0.7)"}`,
            //    `${color3 ? color3 : "rgba(89, 59, 219, 0.5)"}`,
			   //    `${color3 ? color3 : "rgba(0,0,0,0.07"}`,
            //    `${color1 ? color1 : "rgba(30, 170, 231, 0.9)"}`,
            //    `${color2 ? color2 : "rgba(89, 59, 219, 0.7)"}`,
            //    `${color3 ? color3 : "rgba(89, 59, 219, 0.5)"}`,
			   //    `${color3 ? color3 : "rgba(0,0,0,0.07"}`,
            // ],
         },
      ],
      labels: list_labels,
   };

   const options = {
      responsive: false,
      legend: false,
      maintainAspectRatio: false,
   };

   return (
      <>
         <Pie data={data} height={200} width={200} options={options} />
      </>
   );
};

export default PieCustom;
