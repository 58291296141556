import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import { Accordion,Card} from 'react-bootstrap';
import classnames from 'classnames';

import {accordionBlog,IconRed,PendingBlog,AccordBox} from './TabData';
//Images
import avt10 from './../../../../images/avatar/10.png';
import avt11 from './../../../../images/avatar/11.png';
import avt12 from './../../../../images/avatar/12.png';
import avt13 from './../../../../images/avatar/13.png';
import avt14 from './../../../../images/avatar/14.png';


const WalletTab = () => {
	const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
	const [activeDefault, setActiveDefault] = useState(0)
	return(
		<>	
			<div className="col-xl-12">
				<div className="card">
					<div className="card-header d-block d-sm-flex border-0">
						<div>
							<h4 className="fs-20 text-black">Payment History</h4>
							<p className="mb-0 fs-13">Lorem ipsum dolor sit amet, consectetur</p>
						</div>
						<div className="card-action card-tabs mt-3 mt-sm-0">
							<ul className="nav nav-tabs" role="tablist">
								<li className="nav-item">
									<Link to ={"#"} className= {classnames({ active : activeTab === '1'}) + ' nav-link'} onClick={() => { toggle('1'); }}>Monthly</Link>
								</li>
								<li className="nav-item">
									<Link to ={"#"} className= {classnames({ active : activeTab === '2'}) + ' nav-link'} onClick={() => { toggle('2'); }}>Weekly</Link>
								</li>
								<li className="nav-item">
									<Link to ={"#"} className= {classnames({ active : activeTab === '3'}) + ' nav-link'} onClick={() => { toggle('3'); }}>Today</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="card-body payment-bx tab-content p-0">
						<div className="tab-pane active show fade" id="monthly" role="tabpanel">
							<TabContent activeTab={activeTab}>
								<TabPane tabId="1">
									<Accordion className="accordion accordion-primary"  defaultActiveKey="0" id="accordion-one" >
										{accordionBlog.map((data, i) => (
											<div className="accordion__item border-bottom mb-0">
												<Accordion.Toggle as="div" variant="" className={` ${ activeDefault === i ? 'd-flex flex-wrap align-items-center accordion__header  rounded' : 'collapsed d-flex flex-wrap align-items-center accordion__header  rounded'}`} 
													onClick={() => 	setActiveDefault(activeDefault === i ? -1 : i)} eventKey={`${i}`}>
													<div className="mb-lg-0 mb-3 d-flex align-items-center">
														<div className="profile-image mr-4">
															<img src={data.image} alt="" width="63" className="rounded-circle" />
															{data.icon}
														</div>
														<div>
															<h6 className="fs-16 font-w700 mb-0"><a className="text-black" href="javascript:void(0)">{data.title}</a></h6>
															<span className="fs-14">{data.subtitle}</span>
														</div>
													</div>
													<span className="mb-lg-0 mb-3 text-black px-2">{data.date} <br/>08:22 AM</span>
													<span className="mb-lg-0 mb-3 text-black font-w600 px-2">{data.price}</span>
													<span className="mb-lg-0 mb-3 text-black font-w600 px-2">MasterCard 404</span>
													{data.status}
													<span className="accordion__header--indicator"></span>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey={`${i}`} className="accordion__body">
													{data.cardbody}
												</Accordion.Collapse>
											</div>
										))}
									</Accordion>									
								</TabPane>		
								<TabPane tabId="2">
									<Accordion className="accordion accordion-primary"  defaultActiveKey="-1" id="accordion-one" >
										<div className="accordion__item border-bottom mb-0">
											<Accordion.Toggle as="div" variant="" className={` ${ activeDefault === 0 ? 'd-flex flex-wrap align-items-center accordion__header  rounded collapsed' : ' d-flex flex-wrap align-items-center accordion__header  rounded'}`} 
												onClick={() => 	setActiveDefault(activeDefault === 0 ? -1 : 0)} eventKey={`${0}`}>
												<div className="mb-lg-0 mb-3 d-flex align-items-center">
													<div className="profile-image mr-4">
														<img src={avt13} alt="" width="63" className="rounded-circle" />
														<IconRed />
													</div>
													<div>
														<h6 className="fs-16 font-w700 mb-0"><a className="text-black" href="javascript:void(0)">Olivia Brownlee</a></h6>
														<span className="fs-14">Online Shop</span>
													</div>
												</div>
												<span className="mb-lg-0 mb-3 text-black px-2">June 6, 2020 <br/>08:22 AM</span>
												<span className="mb-lg-0 mb-3 text-black font-w600 px-2">+$6,124</span>
												<span className="mb-lg-0 mb-3 text-black font-w600 px-2">MasterCard 404</span>
												<PendingBlog />
												<span className="accordion__header--indicator"></span>
											</Accordion.Toggle>
											<Accordion.Collapse eventKey={`${0}`} className="accordion__body">
												<AccordBox />
											</Accordion.Collapse>
										</div>
									</Accordion>
								</TabPane>		
								<TabPane tabId="3">
									<Accordion className="accordion accordion-primary"  defaultActiveKey="-1" id="accordion-one" >
										<div className="accordion__item border-bottom mb-0">
											<Accordion.Toggle as="div" variant="" className={` ${ activeDefault === 0 ? 'd-flex flex-wrap align-items-center accordion__header  rounded collapsed' : ' d-flex flex-wrap align-items-center accordion__header  rounded'}`} 
												onClick={() => 	setActiveDefault(activeDefault === 0 ? -1 : 0)} eventKey={`${0}`}>
												<div className="mb-lg-0 mb-3 d-flex align-items-center">
													<div className="profile-image mr-4">
														<img src={avt12} alt="" width="63" className="rounded-circle" />
														<IconRed />
													</div>
													<div>
														<h6 className="fs-16 font-w700 mb-0"><a className="text-black" href="javascript:void(0)">Peter Parkur</a></h6>
													</div>
												</div>
												<span className="mb-lg-0 mb-3 text-black px-2">June 9, 2020 <br/>08:22 AM</span>
												<span className="mb-lg-0 mb-3 text-black font-w600 px-2">+$2,624</span>
												<span className="mb-lg-0 mb-3 text-black font-w600 px-2">MasterCard 404</span>
												<PendingBlog />
												<span className="accordion__header--indicator"></span>
											</Accordion.Toggle>
											<Accordion.Collapse eventKey={`${0}`} className="accordion__body">
												<AccordBox />
											</Accordion.Collapse>
										</div>
										
									</Accordion>	
								</TabPane>		
							</TabContent>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}



export default WalletTab;