import { padWithLeadingZeros } from "common/Manager/Helper";
import React from "react";
import Select from "./Select";

type Props = {
  handleInputChange: any;
  hour:string;
  minutes:string;
 
};

const TimeList = ({ handleInputChange, hour, minutes}: Props) => {
  const hourList: any = [];
  const minuteList: any = [];

  for (let i = 0; i <= 24; i++) {
    const hour = padWithLeadingZeros(i, 2);

    hourList.push({
      label: `${hour}`,
      value: `${hour}`,
    });
  }

  for (let j = 0; j <= 59; j++) {
    const minute = padWithLeadingZeros(j, 2);

    minuteList.push({
      label: `${minute}`,
      value: `${minute}`,
    });
  }


  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="w-100">
          <Select
            id={"hour"}
            name={"hour"}
            handleInputChange={handleInputChange}
            selectList={hourList}
            defaultValue={hour}
          />
        </div>
        <div className="d-flex align-items-center pl-2 pr-2">:</div>
        <div className="w-100">
          <Select
            id={"minutes"}
            name={"minutes"}
            handleInputChange={handleInputChange}
            selectList={minuteList}
            defaultValue={minutes}
          />
        </div>
      </div>
    </>
  );
};

//     return options

export default TimeList;
