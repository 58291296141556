import React,{Fragment, useEffect, useState, useRef} from 'react';

import SearchTransactionRequest from "modules/receipt/APIRequest/SearchTransactionRequest";
import CountTransactionRequest from "modules/receipt/APIRequest/CountTransactionRequest";
import InitializeManager from "common/Manager/InitializeManager";

import APIManager from "common/Manager/APIManager";
import FilterModel from "common/models/FilterModel";
import {endDateISO, formatDateForApi, startDateISO} from 'common/Manager/Helper'
import Receipt from 'modules/receipt/component';


const index = (props: any) => {	
	const isClient = typeof window === "object";
	let ENV_CLIENT: any;

	if (!isClient) {
	  ENV_CLIENT = JSON.parse(InitializeManager.default.get()).data?.ENV_CLIENT;
	} else {
	  ENV_CLIENT = InitializeManager.default.get().data.ENV_CLIENT;
	}

	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')

	const [pageCount, setPageCount] = useState(0)
	const [page, setPage] = useState(0)
	const [perPage, setPerPage] = useState(5)
	const [totalPage, setTotalPage] = useState(0)

	const [sort, setSort] =  useState({	
			field : 'created_at',
			sortby: 'DESC'  
	})

	const [transaction, setTransaction] = useState([])
	const getTransaction = async (current_page = page, per_page = perPage, sort_data = sort) => {
			
		const filterModel = new	FilterModel(
		{
			start_date: startDate,
			end_date: endDate,
			page: current_page,
			pagesize: per_page,	
			sort: sort_data,		
			orderdirection: "DESC"		
		})

		const apiRequest = new SearchTransactionRequest(filterModel);
		const countApiRequest = new CountTransactionRequest(filterModel);

		const items = await APIManager.default.fetch(apiRequest);				
		const count = await APIManager.default.fetch(countApiRequest);
		
		setTransaction(items.data.data.data.data);	
		setPageCount(count.data.data.data.data)
		setTotalPage(Math.ceil(count.data.data.data.data / perPage))	

	};
		
	useEffect( () => {

		getTransaction(page, perPage);	

	}, [])

	const handleDateChange = (st, end) =>{

		if(st){
			setStartDate(startDateISO(st))
		}

		if(end){
			setEndDate(endDateISO(end))
		}

	}

	const onView = (id:any) =>{
		window.open(`/receipt/view/${id}`);
	}

  	return (
		<>
			<Receipt
				handleDateChange={handleDateChange} 
				getTransaction={getTransaction}

				data={transaction}
				key={1}		

				setSort={setSort}

				setPage={setPage}
				setPerPage={setPerPage}
				setTotalPage={setTotalPage}

				page={page}
				perPage={perPage}
				countRow={pageCount}
				totalPage={totalPage}	
				onView={onView}	
			
			>
			</Receipt>
    	</>
  	)


}

export default index;