import React from "react";
import RegisterChannelRequest from "modules/register/APIRequest/RegisterChannelRequest";
import BusinessTypeRequest from "modules/register/APIRequest/BusinessTypeRequest";
import BankRequest from "modules/register/APIRequest/BankRequest";
import APIManager from "common/Manager/APIManager";
import { useEffect, useState } from "react";

const StepThree = (prop) => {
  const {
    step3,
    handleInputChange,
    hasError,
    channelSelected,
    handleCheckbox,
    translate,
    locale,
  } = prop;
  const [channel, setChannel] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [bank, setBank] = useState([]);

  const getChannel = async () => {
    const apiRequest = new RegisterChannelRequest(locale);
    const channelList = await APIManager.default.fetch(apiRequest);
    setChannel(channelList.data.data);
  };

  const getBusinessType = async () => {
    const apiRequest = new BusinessTypeRequest();
    const businessTypeList = await APIManager.default.fetch(apiRequest);
    setBusinessType(businessTypeList.data.data);
  };

  const getBank = async () => {
    const apiRequest = new BankRequest();
    const bankList = await APIManager.default.fetch(apiRequest);
    setBank(bankList.data.data);
  };

  useEffect(() => {
    getChannel();
    getBusinessType();
    getBank();
  }, []);

  return (
    <>
      <section>
        <div className="row">
          <div className="col-lg-6 mb-2">
            <div className="form-group">
              <label className="text-label">
                {translate["regis.step3.business_type"]}
              </label>
              <select
                id="sel1"
                name="business_type"
                className={
                  hasError("business_type")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                onChange={handleInputChange}
              >
                <option value="">
                  {translate["regis.step3.choose_business_type"]}
                </option>
                {businessType.map((b) => (
                  <option value={b.id} selected={b.id == step3.business_type}>
                    {translate[b.key_label]}
                  </option>
                ))}
              </select>

              <div className="invalid-feedback text-left">
                {translate["regis.step3.business_type.error"]}
              </div>
              
            </div>

            <div className="form-group">
              <label className="text-label">
                {translate["regis.step3.bank_name"]}
              </label>
              <select
                id="sel1"
                name="bank_name"
                className={
                  hasError("bank_name")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                onChange={handleInputChange}
              >
                <option value="">
                  {" "}
                  {translate["regis.step3.choose_bank_name"]}
                </option>
                {bank.map((b) => (
                  <option value={b.id} selected={b.id == step3.bank_name}>
                    {translate[b.key_label]}
                  </option>
                ))}
              </select>

              <div className="invalid-feedback text-left">
                {translate["regis.step3.bank_name.error"]}
              </div>
            </div>

            <div className="form-group">
              <label className="text-label">
                {translate["regis.step3.bank_account"]}
              </label>
              <input
                type="text"
                name="bank_account"
                className={
                  hasError("bank_account")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder=""
                value={step3.bank_account}
                onChange={handleInputChange}
              />

              <div className="invalid-feedback text-left">
                {translate["regis.step3.bank_account.error"]}
              </div>
            </div>
          </div>

          <div className="col-lg-6 mb-2">
            <div className="form-group">
              <div className="div-align-left">
                <label for="lastName">
                  {translate["regis.step3.payment_method"]}
                </label>
              </div>

              <p className="div-align-left">
                {channel.map((c) => (
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        value={c.id}
                        name="payment_method"
                        id="payment_method"
                        checked={channelSelected(c.id)}
                        className={
                          hasError("channel")
                            ? "form-check-input is-invalid"
                            : "form-check-input"
                        }
                        onChange={handleCheckbox}
                      />
                      {c.name}
                    </label>
                  </div>
                ))}

                {hasError("payment_method") && (
                  <div className="error text-left">
                    {translate["regis.step3.payment_method.error"]}
                  </div>
                )}

              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StepThree;
