import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'
import SearchUser from 'common/interface/SearchUser';


class GetUsersRequest  {

  method: HTTPMethodEnum = HTTPMethodEnum.POST
  url: string = '/account/user'
  param: SearchUser
  token: string
  
  
  constructor (param: SearchUser) {  
    this.param = param
 
  }

  makeBody() {
    { 
      return this.param       
    } 
  }
  makeQuery() { }

  makeHeader() { }

}

export default GetUsersRequest