import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";

export interface OptionTypeInterface {
  filters: any[],
  columnOrder: number,
  groupSort: string,
  id: number,
  orderDirection: string,
  page: number,
  pageSize: number,
  totalCount: number,
  orderBy?:any,
  search?:string
}

class ReceiptPDFRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/receipt/download";
  receipt_tax_id:string
 

  constructor(receipt_tax_id: string) {
    this.receipt_tax_id = receipt_tax_id   
  }

  makeBody() {

    return {
      receipt_tax_id : this.receipt_tax_id
    }
  }

  makeQuery() {}
}

export default ReceiptPDFRequest;
