import _ from 'lodash';
import APIManager from 'common/Manager/APIManager';

import DefaultAPIManager from 'common/Manager/DefaultAPIManager';
//import LabelTranslationAPIRequest from '../APIRequest/LabelTranslationAPIRequest';

import GetEnvRequest from 'common/APIRequests/GetEnvRequest';
import StrapiAuthRequest from 'common/APIRequests/StrapiAuthRequest';
import { serverConfig } from 'common/Config/ServerConfig';
import ServerConfigModel from 'common/models/ServerConfigModel';


interface LabelTranslationParams {
  brandIdentifier: string;
  productIdentifier: string;
}


class ServerConfigManager {

  public static default: ServerConfigManager  = new ServerConfigManager();
  private envServer: { [key: string]: string } = {}
  private envClient: { [key: string]: string } = {}
  private changesDetector: any

  private constructor() {
    this.envServer = {}
    this.envClient = {}
  
 
  }

  async getJWT() {  

    const IDENTIFIER_STRAPI = serverConfig.IDENTIFIER_STRAPI
    const PASSWORD_STRAPI = serverConfig.PASSWORD_STRAPI
    const apiRequest = new StrapiAuthRequest(IDENTIFIER_STRAPI,PASSWORD_STRAPI);

    
    const data = await DefaultAPIManager.default.fetch(apiRequest);
    return data.data.jwt
  
  }

  async getEnv(jwt :string) {  

    const apiRequest = new GetEnvRequest(jwt);  
    const data = await DefaultAPIManager.default.fetch(apiRequest);

    const attributes = _.map(data.data.data,'attributes')
    return attributes    

  }


  static async init() {
    ServerConfigManager.default.refetchConfiguration()
  }

  private async refetchConfiguration() {    

    const jwtData = await ServerConfigManager.default.getJWT();    
    const envDatas = await ServerConfigManager.default.getEnv(jwtData);

    envDatas.forEach( (envData:ServerConfigModel) => {

      if(envData.is_serverside == 'y'){
        this.envServer[envData.name] = envData.value
      } else{
        this.envServer[envData.name] = envData.value
        this.envClient[envData.name] = envData.value
      }

    })

  }

  getEnvServer(key: string) {
    return this.envServer[key]
  }

  getEnvClient(key: string) {
    return this.envClient[key]
  }
 
  exportEnvClient() {
    return this.envClient
  }
 





}

export default ServerConfigManager;
