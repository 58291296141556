import _ from 'lodash'

class FilterModel {

    id:number;
    filters?: any[]
    page: number;
    pagesize: number | 10;
    search?: string;
    orderdirection:string | "asc" | "desc";
    merchant_id:number    

	order_ref : string
    customer_name : string
    mobile : string
    email : string
    status?: string

    created_at: string

    start_date?: string
    end_date?: string

    sort : string
    channel? : number

    constructor(json: any) {
        this.id = _.get(json, 'id')
        this.filters = _.get(json, 'filters')
        this.page = _.get(json, 'page')
        this.pagesize = _.get(json, 'pagesize')
        this.search = _.get(json, 'search')
        this.orderdirection = _.get(json, 'orderdirection')
        this.merchant_id = _.get(json, 'merchant_id')
        
        this.order_ref = _.get(json, 'order_ref')
        this.customer_name = _.get(json, 'customer_name')
        this.mobile = _.get(json, 'mobile')
        this.email = _.get(json, 'email')

        this.status = _.get(json, 'status')

        this.created_at = _.get(json, 'created_at')
        this.start_date = _.get(json, 'start_date')
        this.end_date = _.get(json, 'end_date')
     
        this.sort = _.get(json, 'sort')
        this.channel = _.get(json, 'channel')

    
    }
}

export default FilterModel

