import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'
import  ServerConfig from 'common/Manager/ServerConfigManager';

class DeleteUserByIdRequest  {

  method: HTTPMethodEnum = HTTPMethodEnum.POST
  url: string = '/account/user/delete'
  param: number
  
  constructor (param:number) {
    this.param = param
  }

  makeBody() { 
    return  {
      id : this.param 
    } 
    
  }
  makeQuery() { }

  makeHeader() { 
    return {
     'API_KEY': ServerConfig.default.getEnvServer('BACKOFFICE_API_KEY')
    }
  }

}

export default DeleteUserByIdRequest