import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";



class ChannelBillRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.GET;
  url: string = "/channel/getbillpayment";
  param:string
 

  constructor() {
  }

  makeBody() {}

  makeQuery() {}
}

export default ChannelBillRequest;
