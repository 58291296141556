import React, { useRef, useEffect, useState } from 'react';

import CredentialsItem from 'modules/account/credential/component/CredentialsItem';


const CredentialsMultiple = (prop) => {

  const {
    credentials,
    
	}  = prop


  return (
    <>    
      <div className="row">

        <div className="col-xl-12">

          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h2 className="card-title">Credentials</h2>
                </div>

                <div className="card-body pb-0">

                  <div className="mb-4 text-right">


                  </div>

                    {credentials.map( (credential:any) => 
                    (  
                      <CredentialsItem 
                        credential={credential}
                      >
                      </CredentialsItem>
                    ))}

                  
                </div>
              </div>
            </div>
          </div>
          
        </div>

      </div>

    </>
  );
};
export default CredentialsMultiple;
