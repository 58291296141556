import React,{Fragment, useEffect, useState, useRef} from 'react';
import { Redirect, useParams } from "react-router-dom";

import QuickpayDetailRequest from "modules/quickpay/APIRequest/QuickpayDetailRequest";
import APIManager from "common/Manager/APIManager";

import QuickPayModel from "modules/quickpay/models/QuickPayModel";
import FormEmailModel from "modules/quickpay/models/FormEmailModel";

import FormEmail from "modules/quickpay/component/FormEmail"
import _ from 'lodash';
import swal from "sweetalert";
import SendLinkRequest from 'modules/quickpay/APIRequest/SendLinkRequest';
import FormSendEmailRequest from 'modules/quickpay/APIRequest/FormSendEmailRequest';



type Params = {
	id: string;
  };

const SendEmail = (props: any) => {

    let  page  = 'edit-contact';
	let title = 'Credit Card'
	let clickVoid = false;

	const [formEmailData, setFormEmailData] = useState<FormEmailModel>();
	const { id } = useParams<Params>();
	
  	const getQuickpay = async (ids :string) => {   
		  
		const apiRequest = new FormSendEmailRequest(ids);

		try {

			const items = await APIManager.default.fetch(apiRequest);
			const quickPay = new FormEmailModel(items.data.data)	
			setFormEmailData(quickPay);

			
		} catch (error) {
			//console.log(" error  : ");
		}


	};

	const sendLink = async (ids :string) => {   
		  
		const apiRequest = new SendLinkRequest(formEmailData);
		try {

			const items = await APIManager.default.fetch(apiRequest);
			if(items.data.status == 200){

				swal("success", {
					icon: "success",
				  })
			}			
			
		} catch (error) {
			//console.log(" error  : ");
		}


	};
	
	useEffect( () => {

		getQuickpay(id);		

	}, [])


  return (
	<>
        <FormEmail data={formEmailData}  sendLink={sendLink}></FormEmail> 
    </>
  )


}

export default SendEmail;