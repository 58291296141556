import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import EditContact from 'pages/account/merchant-info/edit';


const Option = (prop) => {

  const {
    merchantData,
    page,
    fileLogoUpload,
    state,
    handleChangeFile,
    handleUpload,
    docData,
    contentTranslate,
    ENV_CLIENT,
    setState
	}  = prop

  let imgEl :any;

  return (
    <>
      <div className="row page-titles mx-0">
        <div className="col-sm-6 p-0">
          <div className="welcome-text">
            <h2 className="text-black font-w600 mb-0">Merchant Information</h2>
          </div>
        </div>
        
        <div className="col-sm-6 p-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to={"#"}>Account</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"#"}>#{merchantData?.merchant_id}</Link>
            </li>
          </ol>
        </div>

      </div>

      <div className="row">

        <div className="col-xl-8">

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h2 className="card-title">Merchant Information</h2>
                </div>
                <div className="card-body border-bottom">
                  <div className="d-flex flex-wrap mb-sm-2 justify-content-between">
                    <div className="pr-3 mb-3">
                      <p className="fs-14 mb-1">Merchant ID</p>
                      <span className="text-black fs-18 font-w500">
                        {merchantData?.merchant_id}
                      </span>
                    </div>

                    <div className="pr-3 mb-3">
                      <p className="fs-14 mb-1">Merchant Code</p>
                      <span className="text-black fs-18 font-w500">
                        {merchantData?.code}
                      </span>
                    </div>

                    <div className="mb-3">
                      <p className="fs-14 mb-1">Trading Name</p>
                      <span className="text-black fs-18 font-w500">
                     
                        {merchantData?.trading_name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {!page && (
              <div className="col-lg-12 col-sm-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h2 className="card-title">Contact Information</h2>
                  </div>
                  <div className="card-body pb-0">

                    <div className="row mb-3">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Merchant Name<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{merchantData?.merchant_name}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Address<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{merchantData?.address}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Mobile<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span> {merchantData?.mobile}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Phone<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span> {merchantData?.phone}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Contact Email<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{merchantData?.contact_email}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Contact Person<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{merchantData?.contact_person}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Support Email<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{merchantData?.support_email}</span>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Fax<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{(merchantData?.fax == '') ? '-' : merchantData?.fax }</span>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Website
                          <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{merchantData?.website}</span>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Facebook<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{merchantData?.facebook}</span>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Instagram<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{merchantData?.instagram}</span>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Line<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{merchantData?.line}</span>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Business Type<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{contentTranslate[merchantData?.business_type?.key_label]}</span>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Channel<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <div className="form-group">                  
                          {merchantData?.payment_method}                          
                        </div>

                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Bank<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{contentTranslate[merchantData?.bank?.key_label]}</span>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-3">
                        <h5 className="f-w-500">
                          {" "}
                          Bank Account<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-9">
                        <span>{merchantData?.bank_account}</span>
                      </div>
                    </div>
              

                    <div className="row mb-3">
                      <div className="col-3"></div>
                      <div className="col-9">
                        <a>
                          <Link to="/account/merchant-info/edit-contact">
                            <a
                              href="#"
                              className="btn btn-primary mb-1 ml-1 btn btn-primary"
                              role="button"
                            >
                              Edit
                            </a>
                          </Link>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {page == "edit-contact" && (
              <EditContact   
                contentTranslate={contentTranslate}
              ></EditContact>
            )}

          </div>

          <div className="row">
            {(
              <div className="col-lg-12 col-sm-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h2 className="card-title">Document</h2>
                  </div>
                  <div className="card-body pb-0">

                  {docData && ( 
                    docData.map( v => (v.name != 'logo') && (

                      <div className="row mb-3">
                        <div className="col-3">
                          <h5 className="f-w-500">
                           {" "}
                            {v.name}<span className="pull-right">:</span>
                          </h5>
                        </div>
                        <div className="col-9">
                          <span>
                            <a href={`${ENV_CLIENT['FILE_ASSET']}/${v?.file}`}>
                              <i className="fa fa-download mb-2"></i>
                            </a>
                          </span>
                        </div>
                      </div>)
                      )
                    )}
                  </div>
                </div>
              </div>
            )}

          </div>

      
        </div>

        <div className="col-xl-4">

          <div className="row">
            <div className="col-xl-12 col-lg-6 col-md-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <div>
                    <h4 className="fs-20 text-black">MERCHANT LOGO</h4>
                    <span className="fs-12">
                      Logo should be 150*50 px in a JPG, PNG and not
                      exceed 200 KB.
                    </span>
                  </div>
                </div>
                <div className="card-body">
                  <div className="text-center mb-4">
                    <div className="profile-photo">
                      <img
                        src={fileLogoUpload}
                        height={'100px'}
                        width={'200px'}
                        ref={el => imgEl = el}
                        onLoad={() => {    
                          
                          if(imgEl.naturalWidth > 200 ||  imgEl.naturalHeight > 100)
                          {
                              state.uploadMessage = "Logo is larger than 200*100";
                              state.uploadValid = false;
                              setState({ ...state });
                              return;
                          }
                            
                        }}                 
                        alt=""
                      />
                    </div>
                  </div>

                  {state.uploadMessage && (
                    <div className="mb-3 error text-center">
                      {state.uploadMessage}
                    </div>
                  )}

                  <div className="input-group mb-3">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        name="logo"
                        onChange={handleChangeFile}
                      />
                      <label className="custom-file-label">Choose file</label>
                    </div>

                    <div
                      className="input-group-append"
                      onClick={handleUpload}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>

      </div>

    </>
  );
};
export default Option;
