import {TransactionStatusEnum} from "common/enumerations/TransactionStatusEnum";

const colorLookup = {
  [TransactionStatusEnum.Pending]: "#F5F5F5",
  [TransactionStatusEnum.Pending_3D]: "#F5F5F5",
  [TransactionStatusEnum.Authorized]: "#80CBC4",
  [TransactionStatusEnum.Accepted]: "#4DB6AC",

  [TransactionStatusEnum.Settled]: "#BA68C8",
  [TransactionStatusEnum.Voided]: "#F8BBD0",
  [TransactionStatusEnum.Refund]: "#F48FB1",

  [TransactionStatusEnum.Cancel]: "#FF6E40",
  [TransactionStatusEnum.Expired]: "#FFD54F",
  [TransactionStatusEnum.Rejected]: "#FF3D00",


  [TransactionStatusEnum.Other]: "#90A4AE",
};

const TransactionColorStatusText = (status: string) => {
  return colorLookup[status] || "#ffffff";
};


const getTransactionStatusName = (value: string): string | undefined  =>{
  for (const key in TransactionStatusEnum) {
    if (TransactionStatusEnum[key] === value) {
      return key;
    }
  }
}

const ColorPeriodLookup = {
  ['1']: "#4DB6AC",
  ['0']: "#FF3D00",
};

const ColorPeriodTextLookup = {
  ['1']: "Paid",
  ['0']: "Not Paid",
};

export { TransactionColorStatusText, getTransactionStatusName, ColorPeriodLookup, ColorPeriodTextLookup};




