import _ from 'lodash'

class FilterModel {

    id:number;
    filters?: any[]
    page: number;
    pagesize: number | 10;
    search?: string;
    orderdirection:string | "asc" | "desc";
    merchant_id:number    

    system_ref : string
    order_ref : string
    status : number				
    credit_paytype : number
    product_name: string

    start_date : string
    end_date : string

    sort : string 
    channel? : number

    card_no? : string
    card_holder? : string
    qr_method? : string

    constructor(json: any) {
        this.id = _.get(json, 'id')
        this.filters = _.get(json, 'filters')
        this.page = _.get(json, 'page')
        this.pagesize = _.get(json, 'pagesize')
        this.search = _.get(json, 'search')
        this.orderdirection = _.get(json, 'orderdirection')
        this.merchant_id = _.get(json, 'merchant_id')
        
        this.system_ref = _.get(json, 'system_ref')
        this.order_ref = _.get(json, 'order_ref')
        this.status = _.get(json, 'status')
        this.credit_paytype = _.get(json, 'credit_paytype')
        this.product_name = _.get(json, 'product_name')
       
        this.start_date = _.get(json, 'start_date')
        this.end_date = _.get(json, 'end_date')

        this.sort = _.get(json, 'sort')
        this.channel = _.get(json, 'channel')

        this.card_no = _.get(json, 'card_no')
        this.card_holder = _.get(json, 'card_holder')
        this.qr_method = _.get(json, 'qr_method')
    
    }
}

export default FilterModel

