import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import FilterModel from "common/models/FilterModel";



class CurrencyRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.GET;
  url: string = "/currency/get";
  param:FilterModel
 

  constructor() {
 
  }

  makeBody() {

  }

  makeQuery() {}
}

export default CurrencyRequest;


