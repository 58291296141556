import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'
import APIRequest from 'common/interface/APIRequest'
import { serverConfig } from 'common/Config/ServerConfig';
import SearchUser from 'common/interface/SearchUser';
import  ServerConfig from 'common/Manager/ServerConfigManager';


class GetUserByIdRequest  {

  method: HTTPMethodEnum = HTTPMethodEnum.POST
  url: string = '/account/user/getbyid'
  param: number
  
  constructor (param:number) {
    this.param = param
  }

  makeBody() { 
    return  {
      id : this.param 
    } 
    
  }
  makeQuery() { }

  makeHeader() { 
    return {
     'API_KEY': ServerConfig.default.getEnvServer('BACKOFFICE_API_KEY')
    }
  }

}

export default GetUserByIdRequest