import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "jsx/layouts/PageTitle";
import { useEffect, useState } from "react";
import GetMenuAllRequest from "modules/account/user/APIRequest/GetMenuListRequest";
import CreateUsersRequest from "modules/account/user/APIRequest/CreateUsersRequest";
import GetUserByIdRequest from "modules/account/user/APIRequest/GetUserByIdRequest";
import APIManager from "common/Manager/APIManager";
import { formatDay2, formatInvert } from "common/Manager/Helper";
import {  useParams } from "react-router-dom";
import swal from "sweetalert";

import UserMenuModel from "modules/usermenu/models/UserMenuModel";
import UserRoleModel from "modules/account/user/models/UserRoleModel";

import UserModel from "modules/account/user/models/UserModel";
import RegularExpression from "utilities/RegularExpression";
import { json } from "body-parser";

let selected = [];
let errors = [];

type QuizParams = {
  id: string;
};

let isFillConfirm = false;

const CreateUser = () => {
  const expireData = new Date();
  expireData.setDate(expireData.getDate() + 90);
  const validation = RegularExpression.default;

  const [menuData, setMenuList] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [userForm, setUserForm] = useState({
    is_superuser: "",
    email: "",
    confirm_email: "",
    fullname: "",
    contact_no: "",
    user_group: "",
    user_role: "",
    expire_date: formatDay2(expireData),
    active: "n",
  });

  const [formEror, setFormEror] = useState([]);

  let { id } = useParams<QuizParams>();
  let users_id;
  if (typeof id != "undefined") {
    users_id = parseInt(id);
  }

  const superuser = "y";
  let actionUser = typeof id != "undefined" ? "Edit" : "Create";

  var obj = {};

  const handleInputChange = (e) => {
    const { target } = e;

    obj[target.name] = target.value;

    let validEmail = validation.emailValidate(target.value, false);
    let validConfirmEmail = validation.emailValidate(target.value, false);
    let validContactNo = validation.phoneNumberValidate(target.value, false);

    if(target.name == 'email' || target.name == 'confirm_email') {
         
      let emailKeyUpValidate = validation.emailKeyUpValidate(String(target.value));
      
      if(!emailKeyUpValidate) {
        return ;
      }    

    }
   
    if (target.name == "email") {

      var index = errors.indexOf("email");
      if (!validEmail) {
        if (index == -1) {
          errors.push("email");
        }
      } else {
        if (index !== -1) {
          errors.splice(index, 1);
        }
      }

      if(isFillConfirm)
      {
        var index = errors.indexOf("confirm_email_not_match");
        if (userForm.confirm_email !== target.value) {

          if (index == -1) {
            errors.push("confirm_email_not_match");
          }
          
        }else{

          if (index !== -1) {
            errors.splice(index, 1);
          }

        }

      }


    }

    if (target.name == "confirm_email") {

      isFillConfirm = true

      var index = errors.indexOf("confirm_email");
      if (!validConfirmEmail) {
        if (index == -1) {
          errors.push("confirm_email");
        }
      } else {
        if (index !== -1) {
          errors.splice(index, 1);
        }
      }

      var index = errors.indexOf("confirm_email_not_match");
      if (userForm.email !== target.value) {

        if (index == -1) {
          errors.push("confirm_email_not_match");
        }

      }else{

        if (index !== -1) {
          errors.splice(index, 1);
        }

      }

    }

    if (target.name == "fullname") {
      var index = errors.indexOf("fullname");
      if (target.value == "") {
        if (index == -1) {
          errors.push("fullname");
        }
      } else {
        if (index !== -1) {
          errors.splice(index, 1);
        }
      }
    }

    if (target.name == "user_group") {
      var index = errors.indexOf("user_group");
      if (target.value === "") {
        if (index == -1) {
          errors.push("user_group");
        }
      } else {
        var index = errors.indexOf("user_group");
        if (index !== -1) {
          errors.splice(index, 1);
        }
      }
    }

    // contact_no
    if (target.name == "contact_no") {
      var index = errors.indexOf("contact_no");
      if (!validContactNo) {
        if (index == -1) {
          errors.push("contact_no");
        }
      } else {
        var index = errors.indexOf("contact_no");
        if (index !== -1) {
          errors.splice(index, 1);
        }
      }
    }

    setFormEror(errors);
    setUserForm({ ...userForm, ...{ [target.name]: target.value } });
  };

  const hasError = (key) => {
    return formEror.indexOf(key) !== -1;
  };

  const changeSubmenu = (m, s_id) => {
    let find = selected.findIndex((item) => item.module_submenu_id == s_id);
    if (find > -1) {
      selected.splice(find, 1);
    } else {
      selected.push({
        module_menu_id: m.id,
        module_submenu_id: s_id,
        module_action_id: null,
        module_menu : m
       
      });

   //   changeMenuClickSub(m , s_id) // select main menu 

    }
    setUserRole([...selected]);

  };

  const changeMenu = (m :UserMenuModel) => {
    if(m.has_child == 'n')
    {
    
        let find = selected.findIndex( (item) => item.module_menu_id == m.id &&  item.module_submenu_id == null &&
        item.module_action_id == null);

        if (find > -1) {  // find success delete

          selected.splice(find, 1);
        
        } else {
          
          selected.push({
            module_menu_id: m.id,
            module_submenu_id: null,
            module_action_id: null,
            module_menu : m
          });

        }

        setUserRole([...selected]);
    }
    else{  
       
      let find = selected.findIndex( (item) => item.module_menu_id == m.id &&  item.module_submenu_id == null &&
      item.module_action_id == null);
      
      if (find > -1) { 

        selected = selected.filter( (x) => x.module_menu_id != m.id  );
        setUserRole([...selected]);
        
      } else {          

          selected.push({
            module_menu_id: m.id,
            module_submenu_id: null,
            module_action_id: null,
            module_menu : m
          });
          
          setUserRole([...selected]);
     }       

    } 

  };

  const changeMenuClickSub = (m :UserMenuModel, s_id :any ) => {
    if(m.has_child == 'n')
    {
        let find = selected.findIndex( (item) => item.module_menu_id == m.id &&  item.module_submenu_id == s_id &&
        item.module_action_id == null);

        if (find > -1) {  // find success delete

          selected.splice(find, 1);
        
        } else {
          
          selected.push({
            module_menu_id: m.id,
            module_submenu_id: s_id,
            module_action_id: null,
            module_menu : m
          });

        }

        setUserRole([...selected]);
    }
    else{  
       
      let find = selected.findIndex( (item) => item.module_menu_id == m.id &&  item.module_submenu_id == s_id &&
      item.module_action_id == null);
      
      if (find > -1) { 

        selected = selected.filter( (x) => x.module_menu_id != m.id  );
        console.log(" result ", selected);
        setUserRole([...selected]);
        
      } else {          

          selected.push({
            module_menu_id: m.id,
            module_submenu_id: s_id,
            module_action_id: null,
            module_menu : m
          });
          
          setUserRole([...selected]);
     }
       

    }
  


  };

  const changeAction = (m, a_id) => {
    let find = selected.findIndex((item) => item.module_action_id == a_id);
    if (find > -1) {
      selected.splice(find, 1);
    } else {
      selected.push({
        module_menu_id: m.id,
        module_menu: m,
        module_submenu_id: null,
        module_action_id: a_id,
      });
    }
    setUserRole([...selected]);
  };
  
  const submenuSelected = (s_id) => {

    let find = userRole.findIndex((item) => item.module_submenu_id == s_id);
    
    if (find > -1) {
      return true;
    }

    //changeMenu()

    return false;
 
  };

  const actionSelected = (a_id) => {
    let find = userRole.findIndex((item) => item.module_action_id == a_id);
    if (find > -1) {
      return true;
    }
    return false;
  };

  const menuSelected = (m) => {

      let find = userRole.findIndex( (item) => item.module_menu_id == m.id
     );

     if (find > -1) {
       
       return true;
     }
     return false;

  };

  const validate = () => {
    errors = [];

    let validEmail = validation.emailValidate(userForm.email, false);
    let validConfirmEmail = validation.emailValidate(
      userForm.confirm_email,
      false
    );

    if (!validEmail) {
      errors.push("email");
    }

    if (!validConfirmEmail) {
      errors.push("confirm_email");
    }

    if (userForm.email !== userForm.confirm_email) {
      errors.push("confirm_email_not_match");
    }

    if (userForm.fullname === "") {
      errors.push("fullname");
    }

    if (userForm.contact_no === "") {
      errors.push("contact_no");
    }

    if (userForm.user_group === "") {
      errors.push("user_group");
    }


    setFormEror(errors);

    //selected = userRole.filter( (x) => x.module_menu_id != m.id  );
    
    for(const i in userRole){

      if(userRole[i]?.module_menu?.has_child == 'y') 
      {       
        const countFilter = userRole.filter( (x) => x.module_menu?.id == userRole[i]?.module_menu?.id 
          &&
          (x?.module_submenu_id !== null || x?.module_action_id !== null)
        )

        if(countFilter.length == 0 ){
          swal(`Please select at least one Submenu on Menu "${userRole[i]?.module_menu?.name}"`, { icon: "error" });
          return false;
        }

      }

    }

    if (errors.length > 0) {
      return false;
    }

    return true;
  };

  const createUser = (e) => {
    e.preventDefault;
    const userModel = new UserModel(userForm);

    if (!validate()) {
      return false;
    }

    userModel.id = users_id;
    userModel.user_role = userRole;
   
    // userModel.email = userForm.email;
    // userModel.fullname = userForm.fullname;
    // userModel.contact_no = userForm.contact_no;
     userModel.expire_date = formatInvert(userForm.expire_date);
    // userModel.user_group = userForm.user_group;
    // userModel.user_role = userRole;
    // userModel.active = userForm.active;
      
    const apiRequest = new CreateUsersRequest(userModel);
    // const items = await APIManager.default.fetch(apiRequest);

    APIManager.default.fetch(apiRequest).then((items) => {
      if (items.data.data.status == 400) {
        swal(items.data.data.message, { icon: "error" });
      } else {
        swal("success", {
          icon: "success",
        }).then(() => {
          window.location.href = "/account/user";
        });
      }
    });

  };

  useEffect(() => {
    const apiRequest = new GetMenuAllRequest();

    selected = []

    const getUserRole = async () => {

      const menuList = await APIManager.default.fetch(apiRequest);     
      setMenuList(menuList.data.data);

      if (superuser == "y" && typeof users_id != "undefined") {
        // actionUser = "edit";
        const getUserRequest = new GetUserByIdRequest(users_id);
        const userData = await APIManager.default.fetch(getUserRequest);

        setUserForm({
          email: userData.data.data.email,
          confirm_email: userData.data.data.email,
          fullname: userData.data.data.fullname,
          contact_no: userData.data.data.contact_no,
          user_group: userData.data.data.user_group,
          expire_date: formatInvert(userData.data.data.expire_date),
          active: userData.data.data.active,
          user_role :"",
          is_superuser: userData.data.data.is_superuser,
        });

        setUserRole(userData.data.data.user_role);
        selected = userData.data.data.user_role;
      }


    };
 
     getUserRole();

  }, []);

  return (
    <Fragment>
      <PageTitle
        activeMenu="User And Role"
        pageHeading=""
        motherMenu={actionUser + " User"}
      />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 order-md-1 ">
                  <h4 className="mb-3"> {actionUser} User</h4>
                  <form className="needs-validation">
                    <div className="row">

                      <div className="col-md-6 mb-3">
                        <label htmlFor="firstName">Email</label>

                        <input
                          type="text"
                          className={
                            hasError("email")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="email"
                          name="email"
                          placeholder=""
                          value={userForm.email}
                          onChange={handleInputChange}
                          disabled={actionUser == "Edit" ? true : false}
                        />
                        <div className="invalid-feedback">
                          Email address is not correct.
                        </div>

                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Confirm Email</label>

                        <input
                          type="text"
                          className={
                            hasError("confirm_email") || hasError("confirm_email_not_match")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="confirm_email"
                          name="confirm_email"
                          placeholder=""
                          value={userForm.confirm_email}
                          onChange={handleInputChange}
                          required
                          disabled={actionUser == "Edit" ? true : false}
                        />
                        <div className="invalid-feedback">

                          {hasError("confirm_email") ? ' Confirm email is not correct.'
                            : ' Confirm email is not match email'}
                         
                        </div>

                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Full name</label>
                        <input
                          type="text"
                          className={
                            hasError("fullname")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="fullname"
                          name="fullname"
                          placeholder=""
                          required
                          value={userForm.fullname}
                          onChange={handleInputChange}
                        />
                        <div className="invalid-feedback">
                          Full name is required.
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Contact no.</label>
                        <input
                          type="text"
                          className={
                            hasError("contact_no")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="contact_no"
                          name="contact_no"
                          placeholder=""
                          required
                          value={userForm.contact_no}
                          onChange={handleInputChange}
                        />
                        <div className="invalid-feedback">
                          Contact not correct.
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">User Group</label>
                        <Form.Control
                          as="select"
                          name="user_group"
                          id="user_group"
                          className={
                            hasError("user_group")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          value={userForm.user_group}
                          onChange={handleInputChange}
                        >
                          <option value=""> Select User Group </option>
                          <option value="ACCOUNT">ACCOUNT</option>
                          <option value="SALE">SALE</option>
                        </Form.Control>

                        <div className="invalid-feedback">
                          User Group is required.
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">
                          Password Expire (90 Days)
                        </label>
                        {/* <div> {dateExpire}</div> */}
                        <div className="input-group clockpicker">
                          {/* <ExpireDate /> */}

                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={userForm.expire_date}
                            />
                            <span className="input-group-append">
                              <span className="input-group-text">
                                <i className="fa fa-clock-o"></i>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>

                      {actionUser == "Edit" && userForm.is_superuser != "y" && (
                        <div className="col-md-6 mb-3">
                          <label htmlFor="lastName">Status</label>
                          <Form.Control
                            as="select"
                            name="active"
                            id="active"
                            className={
                              hasError("active")
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            value={userForm.active}
                            onChange={handleInputChange}
                          >
                            <option value="y">Active </option>
                            <option value="n">In Active</option>
                          </Form.Control>
                        </div>
                      )}
                    </div>
{/* 
                    <div className="mt-4">
                      <a
                        className="btn btn-primary mb-1 ml-1 btn btn-primary"
                        role="button"
                        onClick={createUser}
                      >
                        {actionUser} User
                      </a>
                    </div> */}
                  </form>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12 order-md-1 ">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-group">
                        {/* <p>{JSON.stringify(userRole)}</p> */}

                        <h4 className="card-title">Role</h4>
                        {menuData.map((m, i) => (
                          <ul>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`m_${m.id}`}
                                value=""
                                name={`m_${m.id}`}
                               checked={menuSelected(m)}
                                onChange={() =>
                                  changeMenu(m)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`m_${m.id}`}
                              >
                                {m.name}
                              </label>
                            </div>
                            <li key={m.id}>
                              <ul className="submenu">
                                {m.module_submenu.map((s, j) => (

                                  (s.active == 'y')
                                  &&
                                  (<li key={m.id + "" + s.id}>
                                    <div className="form-check mb-2">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`s_${s.id}`}
                                        value=""
                                        name={`s_${s.id}`}
                                        checked={submenuSelected(s.id)}
                                        onChange={() =>
                                          changeSubmenu(m, s.id)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`s_${s.id}`}
                                      >
                                        {s.name}
                                      </label>
                                    </div>
                                  </li>)

                                ))}
                              </ul>
                              <ul className="submenu">
                                {m.module_action.map((a, j) => (

                                    (a.active == 'y' && a.show_role == 'y')
                                    &&
                                  
                                    (<li key={m.id + "" + a.id}>
                                      <div className="form-check mb-2">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={`a_${a.id}`}
                                          value=""
                                          name={`a_${a.id}`}
                                          // defaultChecked
                                          checked={actionSelected(a.id)}
                                          onChange={() =>
                                            changeAction(m, a.id)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`a_${a.id}`}
                                        >
                                          {a.name}
                                        </label>
                                      </div>
                                    </li>
                                    )

                                ))}
                              </ul>
                            </li>
                          </ul>
                        ))}
                      </div>
                        <div className="mt-4">
                        <a
                          className="btn btn-primary mb-1 ml-1 btn btn-primary"
                          role="button"
                          onClick={createUser}
                        >
                          {actionUser} User
                        </a>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    
    </Fragment>
  );
};

export default CreateUser;
