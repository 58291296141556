import React, { useEffect, useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import ThaiQRPannel from 'modules/myqr/component/ThaiQRPannel';
import GetMyQRRequest  from 'modules/myqr/APIRequest/GetMyQRRequest'
import APIManager from 'common/Manager/APIManager';


const MyQR = (prop:any) => {
  
  const [myQR, setMyQR] = useState<any>();
  const getMyQR = async () => {

    const apiRequest = new GetMyQRRequest();
    const data = await APIManager.default.fetch(apiRequest);
    setMyQR(data.data.data);
    
  };

  useEffect(() => {
    getMyQR()
  }, []);
 
  return (
    <>
    <CssBaseline />   
      <ThaiQRPannel
          isMobile={false}
          myQR={myQR}
      />
    </>    
  )
  
}

export default MyQR
