import React from 'react';
import {Link} from 'react-router-dom';
import pMinDelay from "p-min-delay";
import StatisticDonut from '../Mophy/Transactions/StatisticDonut';
import {formatDateYearEN} from 'common/Manager/Helper';
import NumberFormat from 'utilities/NumberFormat'
// const StatisticCanvas = loadable(() =>
// 	pMinDelay(import("../Mophy/Transactions/StatisticCanvas"), 1000)
// ); 


import TransactionColorBadge from 'common/enumerations/TransactionColorBadge';
 
const TransactionsDetails = (prop)=>{

  const {transaction, merchant} = prop.data
  const { htmlStatus } = prop

  let settled_date;

  if(transaction?.settled_date !=undefined && transaction?.settled_date !='0000-00-00 00:00:00') 
  {
      settled_date = formatDateYearEN(new Date(transaction?.settled_date));
  }

    
	return(
		<>
      <div class= "page-titles form-head d-flex flex-wrap justify-content-between align-items-center mb-4">
				<h2 className="text-black font-w600 mb-0 mr-auto mb-2 pr-3">Billpayment Transactions Details</h2>
			</div>

      <div className="row">

        <div className="col-xl-12">         

          <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h2 className="card-title">Payment Information</h2>
                  </div>
                  <div className="card-body pb-0">
                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          System Ref<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{transaction?.ref1}</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Status<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                      <TransactionColorBadge transactionStatus={transaction?.status} ></TransactionColorBadge>
                      </div>
                      
                    </div>
                    
                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Merchant ID<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{merchant?.merchant_id}</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Merchant Name<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{merchant?.merchant_name}</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Order ID<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{transaction?.ref2}</span>
                      </div>
                    </div>

                    
                    { (merchant?.multiple_product == 'y') && (

                      <div class="row mb-3">
                        <div class="col-6">
                          <h5 class="f-w-500">
                            {" "}
                            Product Type<span class="pull-right">:</span>
                          </h5>
                        </div>
                        <div class="col-6">
                          <span> { (transaction?.product?.name) ? transaction?.product?.name : '-' }</span>
                        </div>
                      </div>

                      )}  

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Product Name<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span> { (transaction?.product_name) ? transaction?.product_name : '-' }</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
						            Amount<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{NumberFormat.default.numberWithCommas(transaction?.amount)}</span>
                      </div>
                    </div>

                        
                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Transaction Fee<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{NumberFormat.default.numberWithCommas(transaction?.transaction_fee)}</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
						            VAT<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{ NumberFormat.default.numberWithCommas(transaction?.vat)}</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
						            Net Amount<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{ NumberFormat.default.numberWithCommas(transaction?.net_amount)}</span>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          MDR<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{ (transaction?.transaction_installment?.configInstallment?.mdr_interest_rate) ? transaction?.transaction_installment?.configInstallment?.mdr_interest_rate : "-" }</span>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Transaction Date/Time<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                      <span>{(transaction?.created_at) ? formatDateYearEN(new Date(transaction?.created_at)) : "-"}</span>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Settlement Date/Time<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">                        
                        <span>{ (settled_date !=undefined) 
                            ? settled_date 
                            : '-'}
                        </span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
						              Settlement Type<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                      <span>{ (transaction?.settled_date !=undefined && transaction?.settled_date !='0000-00-00 00:00:00') 
                          ? 'Auto' 
                          : '-'}
                      </span>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Expired Date<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{(transaction?.expired_at) ? formatDateYearEN(new Date(transaction?.expired_at)) : "-"}</span>
                      </div>
                    </div>
                    
                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Payer name<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{ (transaction?.cardholder) ? transaction?.cardholder :  '-' }</span>
                      </div>
                    </div>
               
                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Bank Provider<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{(transaction?.provider) ?  transaction?.provider : "-"}</span>
                      </div>
                    </div>
          

                  </div>
                </div>
              </div>
            
            
          </div>

		 

        </div>


      </div>
    </>
	)
}
export default TransactionsDetails;