import React, { Fragment, useEffect, useState, useRef } from "react";
import { Container } from "./styles";
import InitializeManager from "common/Manager/InitializeManager";
import NumberFormat from "utilities/NumberFormat";


const Index = (prop) => {
  const isClient = typeof window === "object";

  let ENV_CLIENT: any;

  if (!isClient) {
    ENV_CLIENT = JSON.parse(InitializeManager.default.get()).data?.ENV_CLIENT;
  } else {
    ENV_CLIENT = InitializeManager.default.get().data.ENV_CLIENT;
  }

  const {
    receiptData,
  } = prop;


  return (
    <Fragment>
      <Container>      
       
        <div className="d-flex align-items-center header-top" >
          <div className="p-2">
            <img
              src={ENV_CLIENT["FILE_ASSET"] + "/images/sabuy_logo_mobile.svg"}
              width={"38px"}
              alt=""
            />
          </div>

          <div className="p-1">
            <div className="h-invoice mb-1">
              ใบเสร็จรับเงิน / ใบกำกับภาษี (ต้นฉบับ){" "}
            </div>
            <div className="h-invoice-en">Receipt / Tax Invoice (Original)</div>
          </div>

          <div className="ml-auto p-2">
            <span className="box-span">
              เลขที่ / No. :{" "}
              <span className="span-red">
                {receiptData?.receipt_tax_id}{" "}
              </span>{" "}
            </span>
            <span className="box-span">
              วันที่ / Date :{" "}
              <span className="span-red">{receiptData?.document_date}</span>
            </span>
          </div>
        </div>

        <div className="box-address">
          <div className="d-flex ">
            <div className="p-2 box-left">
              <div className="h-company">
                {receiptData?.sabuypay_profile?.name}
              </div>
              <div className="content-div">
                {receiptData?.sabuypay_profile?.address}
              </div>
            </div>

            <div className="p-2 row-div">

              <div>
                <span className="span-tax-id">
                  เลขประจำตัวผู้เสียภาษี / Tax ID{" "}
                </span>
                : <span className="span-tax-id pad-left"> {receiptData?.sabuypay_profile?.tax_id}{" "}</span>
              </div>
              <div>
                <span className="span-tax-id">โทร. / Tel. </span>
                : {"  "}
                <span className="span-tax-id pad-left">{receiptData?.sabuypay_profile?.tel}</span>
              </div>
              <div>
                <span className="span-tax-id">สาขา / Branch </span> 
                : {"  "}<span className="span-tax-id pad-left"  
                               
                >-</span>
              </div>

            </div>

          </div>
        </div>

        <div className="box-address">
          <div className="d-flex ">
            <div className="p-2 box-left">
              <div className="h-company text-red">{receiptData?.merchant?.trading_name}</div>
              <div className="content-div text-red" >{receiptData?.merchant?.address }</div>
            </div>

            <div className="p-2 row-div">
              <div >
                <span className="span-tax-id ">
                รหัสร้านค้า / Merchant ID{" "}
                </span>
                : <span className="span-tax-id pad-left"> {receiptData?.merchant?.merchant_id}{" "}</span>
              </div>
              <div>
                <span className="span-tax-id">เลขประจำตัวผู้เสียภาษี / Tax ID</span>
                : {" "}
                <span className="span-tax-id pad-left">{receiptData?.merchant?.tax_id}</span>
              </div>
              <div>
                <span className="span-tax-id">สาขา / Branch </span> 
                : <span className="span-tax-id pad-left">-</span>
              </div>           
            </div>
          </div>
        </div>

        <div className="box-content">
          <table width={"100%"}>
            <thead>
              <tr className="border-class">
                <th style={{ width: "5%" }}>
                  ลำดับที่
                  <br />
                  No.
                </th>
                <th style={{ width: "40%" }}>
                  รายการ
                  <br />
                  Description
                </th>
                <th style={{ width: "15%" }}>
                  รหัส
                  <br />
                  Code
                </th>
                <th style={{ width: "10%" }}>
                  จำนวน
                  <br />
                  Qty
                </th>
                <th style={{ width: "15%" }}>
                  ราคาต่อหน่วย
                  <br />
                  Unit Price
                </th>
                <th style={{ width: "15%" }}>
                  จำนวนเงิน (บาท)
                  <br />
                  Total (Baht)
                </th>
              </tr>
            </thead>

            <tbody>
              {
                receiptData?.items?.map( (i:any) => (
                  <tr className="content-detail">
                      <td className="text-center">{i?.no}</td>
                      <td className="text-center"> {i?.description}</td>
                      <td className="text-center">{i?.code}</td>
                      <td className="text-center"> {i?.qty}</td>
                      <td className="text-center"> {i?.unit_price}</td>
                      <td className="text-right text-summary-value"> {NumberFormat.default.numberWithCommas(i?.total)}</td>
                  </tr>
                ))
              }

              <tr>
                <td height={'200px'} ></td>
              </tr>

              <tr className="footer-content">

                <td colSpan={2} rowSpan={4}>
                  <div className="text-desc">จำนวนเงินตัวอักษร / Amount in Word :</div> 
                  <div className="text-desc text-amount">{(receiptData?.total_amount_text)}</div> 
                  <div className="waring-text text-desc">
                    {"กรุณาตรวจสอบความถูกต้อง หากต้องการแก้ไข กรุณาแจ้งภายใน 7 วัน"}
                    <br></br>
                    {"(Any incorrect please contact us within 7 days)"}
                  </div> 
                </td>

                <td colSpan={3}  className="border-content mt-4 mr-8">
                  <div className="text-summary-label" >{"ยอดรวมก่อนภาษีมูลค่าเพิ่ม / Before Vat"}</div>
                </td>
                <td className="border-content text-summary-value">{NumberFormat.default.numberWithCommas(receiptData?.berfore_vat)}</td>

              </tr>

              <tr className="footer-content">

                <td colSpan={3} className="border-content">
                  <div className="text-summary-label" >{"ภาษีมูลค่าเพิ่ม / Vat 7%"}</div>
                </td>
                <td className="border-content text-summary-value">{NumberFormat.default.numberWithCommas(receiptData?.vat)}</td>

              </tr>

              <tr className="footer-content">
                <td colSpan={3} className="border-content">
                  <div className="text-summary-label"  >{"ภาษีหัก ณ จ่าย / W/T 3%"}</div>
                </td>
                <td className="border-content text-summary-value">{NumberFormat.default.numberWithCommas(receiptData?.withholding_tax)}</td>
              </tr>

              <tr className="footer-content">
                <td colSpan={3} className="border-content">
                  <div  className="text-amount-label" >{"จำนวนเงินรวม / Total"}</div>
                </td>
                <td className="border-content text-amount-value">{NumberFormat.default.numberWithCommas(receiptData?.total_amount)}</td>
              </tr>

            </tbody>
          </table>
        </div>
      </Container>
    </Fragment>
  );
};

export default Index;
