import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";


class EditLogoRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string =
    "/account/merchant-info/update-logo";
  param: any;

  constructor(param: any) {
    this.param = param;
  }

  makeBody() {
    return  this.param 
  
  }
  makeQuery() {}
  makeHeader() {
    return { "Content-Type": "multipart/form-data" };
  }
}

export default EditLogoRequest;
