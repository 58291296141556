import _ from 'lodash';
import React, { FunctionComponent } from 'react';

import Box from '@material-ui/core/Box';
import QRCode from  'qrcode.react'
import useStyles from './Style'
import MyQRModel from 'modules/myqr/models/MyQRModel';

import promptpay_logo   from "images/promptpay_logo.png";
import thaiqr_logo      from "images/thaiqr_logo.png";
import thai_qr_image    from "images/thai_qr_image.png";

import ImageManager from 'common/Manager/ImageManager';

type Props = {
    isMobile: boolean;    
    qrData:string;
    memberName:string;
    companyBy:string;

}

const ThaiQRCode = (Ipops :Props) => {

    const classes = useStyles();

    const  { 
        isMobile,
        qrData,
        memberName ,
        companyBy 
    } = Ipops;
   

    return (      
        <div id="myqr">
       <Box  className={classes.thaiqr}>           
            <Box className={classes.header} >
                <Box className={classes.img}>
                    <img src={thai_qr_image} alt="" 
                    width="500px"
                     height='200px'
                    />
                </Box>   
            </Box>

            <Box className={classes.promptpay}>
               <img src={promptpay_logo} alt="" width='250px' />
            </Box>
         
           <Box>
            {(
            <div  >
                <QRCode value={qrData} 
                size={800} 
                   imageSettings={{
                                    src: thaiqr_logo,
                                    height: 95,
                                    width: 125,
                                }}  
                    />
            </div>
            )}
           </Box>

           <div className={classes.paymentto} >  
                <span className={classes.mr}>Pay for {memberName}</span>
           </div>

           <div>  
                <span className={classes.mr}>By {companyBy}</span>                   
           </div>

       </Box>
        
        </div>        
    )





}

export default ThaiQRCode