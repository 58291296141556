import React,{Fragment, useEffect, useState, useRef} from 'react';
import { Redirect, useParams } from "react-router-dom";
import QuickpayDetailRequest from "modules/quickpay/APIRequest/QuickpayDetailRequest";
import APIManager from "common/Manager/APIManager";
import QuickpayDetail from "modules/quickpay/component/QuickpayDetail"
import _ from 'lodash';
import swal from "sweetalert";
import QuickPayResponseModel from 'modules/quickpay/models/QuickPayResponseModel';

type Params = {
	id: string;
  };

const detail = (props: any) => {

    // let  page  = 'edit-contact';

	// let title = 'Credit Card'
	// let clickVoid = false;

	const [quickpayData, setQuickpayData] = useState<QuickPayResponseModel>();
	const { id } = useParams<Params>();
	const textLink = useRef(null);
	const [copyTextLink, setCopyTextLink] = useState('');

	function copyToTextLink() {		
		
		setCopyTextLink('Copied!');		
	};
	
  	const getQuickpay = async (ids :string) => {   
		  
		const apiRequest = new QuickpayDetailRequest(ids);

		try {

			const items = await APIManager.default.fetch(apiRequest);	
			setQuickpayData(items.data.data)			
			
		} catch (error) {
			console.log(" error  : " ,error );
		}

	};
	
	useEffect( () => {

		getQuickpay(id);		

	}, [])


  return (
	<>
        <QuickpayDetail 
			textLink={textLink}
			copyToTextLink={copyToTextLink}
			copyTextLink={copyTextLink}
			data={quickpayData} 
			key={1} 
		>
		</QuickpayDetail> 

    </>
  )


}

export default detail;