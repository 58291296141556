export const QuickPayStatus = {
  ['0']: 'Non paid',
  ['1']: 'Paid',
  ['2']: 'Expired',    
}

export enum QuickPayStatusEnum {
  NON_PAID = '0',
  PAID = '1',
  EXPIRED = '2', 
}
 // "Non Paid", "Paid", "Expired"