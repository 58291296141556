/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

///
// import drump from "../../../images/card/drump.png";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close

    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }

  state = {
    loveEmoji: false,
  };

  render() {
    /// Path
    let fullpath = window.location.pathname;
    fullpath = fullpath.split("/");
    let path = fullpath[fullpath.length - 1];
    //path = path[path.length - 1];

    /// Active menu
    let deshBoard = ["", "my-wallet", "invoices", "cards-center"],
      transactions = [
        "transaction/creditcard",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ];
    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            {this.props.moduleMenu.map((menu, i) => (
              <li
                className={`${
                  "/" + fullpath[1] === menu.route_api ? "mm-active" : ""
                }`}
              >
                { (menu.has_child == "y") ? (
                  <Link
                    className="has-arrow ai-icon"
                    to={menu.route_api}
                    aria-expanded="false"
                  >
                    <i className="flaticon-381-networking"></i>
                    <span className="nav-text">{menu.name}</span>
                  </Link>
                ) : (

                  <Link
                    to={menu.route_api}
                    className="ai-icon"
                    aria-expanded="false"
                    onClick={() => this.props.onClick()}
                  >
                    <i className="flaticon-381-settings-2"></i>
                    <span className="nav-text">{menu.name}</span>
                  </Link>

                    // <a  className={`${
                    //   "/" + path === menu.route_api ? "mm-active" : ""
                    // }`} href={menu.route_api} > 
                    
                    //   <i className="flaticon-381-settings-2"></i> 
                    //   <span className="nav-text">{menu.name}</span>
                    // </a>

                )}
               
                {menu.has_child == "y" && (
                  <ul aria-expanded="false">
                    {menu.module_submenu.map((submenu, j) => (

                      <li>

                        <Link
                          className={`${
                            "/" + path === submenu.route_api ? "mm-active" : ""
                          }`} // path ท้ายสุด
                          to={menu.route_api + submenu.route_api}
                          onClick={() => this.props.onClick()}
                        >
                          {submenu.name}
                        </Link>

{/* 
                        <a  className={`${
                            "/" + path === submenu.route_api ? "mm-active" : ""
                          }`} href={menu.route_api + submenu.route_api} > {submenu.name}
                        </a> */}

                      </li>


                    ))}
                  </ul>
                )}
              </li>
            ))}
          </MM>
        
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
