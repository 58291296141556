import React, { useState } from 'react';

// Model
import RegularExpression from "utilities/RegularExpression";
import { isImage, isImagePdf } from "common/Manager/Helper";
import InitializeManager from 'common/Manager/InitializeManager';

interface IOutput {
  
    setFiledFiles:any;
    setUpdFileStatus:any;
    setUpdLogoStatus: any;
    setFileLogoUpload: any;
    setFileUpload:any;
    setFormEror: any;
    setStepSuccess:any;
    setStep1: any;
    setDoc:any;
    fileLogoUpload:any;
    files:any
    filedFiles:any;
    doc:any;
    step1: any;
    step2: any;
    step3: any;
    stepSuccess: any;
    updFileStatus: any;
    logo: any;
    updLogoStatus:any;
    channelSelected: any;
    hasError:any;
    handleInputChangeStep1: any;
    handleInputChangeStep2: any;
    handleInputChangeStep3: any;
    handleCheckbox: any;
    handleChangeFileLogo: any;
    handleChangeFile: any;
    validateStep:any;
    validateAllInput:any;      
    
}

let cselected = [];

interface file_arr {
    maxLength: number,
    formData: null,
    uploadValid: boolean,
    uploadMessage: string,
 }

const isClient = typeof window === 'object';

const RegisterHook = (): IOutput => {

    let translate: any = {} 
    let ENV_CLIENT: any = {}


    if (!isClient) {    
        translate = JSON.parse(InitializeManager.default.get()).data.register 
        ENV_CLIENT = JSON.parse(InitializeManager.default.get()).data?.ENV_CLIENT

    
      } else {
        translate = InitializeManager.default.get().data?.register;  
        ENV_CLIENT = InitializeManager.default.get().data.ENV_CLIENT

      }
          
    const [formEror, setFormEror] = useState([]);
    const validation = RegularExpression.default;
    const [stepSuccess, setStepSuccess] = useState(false)

    const [fileLogoUpload, setFileLogoUpload] = React.useState("");
    const [logo, setLogo] = React.useState();
    const [files, setFiles] = React.useState([]);
    const [filedFiles, setFiledFiles] = React.useState([]);
    const [doc, setDoc] = useState([]);
    
    const [updLogoStatus, setUpdLogoStatus] = useState({
        maxLength: 0,
        formData: null,
        uploadValid: false,
        uploadMessage: "",
    });

    const [fileUpload, setFileUpload] = React.useState(undefined);
    const [updFileStatus, setUpdFileStatus] = useState<file_arr[]>([]);

    const [step1, setStep1] = React.useState<any>({
        merchant_name: "",
        trading_name: "",
        address: "",
        mobile: "",
        phone: "",
        person_type: "I",
        contact_email: "",
        contact_person: "",
        support_email: "",
        fax: "",
    });

    const [step2, setStep2] = React.useState<any>({
        logo: "",
        website: "",
        facebook: "",
        instagram: "",
        line: "",
        other: "",     
    });

    const [step3, setStep3] = React.useState<any>({
        business_type: "",
        bank_name: "",
        bank_account: "",
        payment_method: []     
    });
  
    const handleInputChangeStep1 = (e) => {
        const { target } = e;
        var obj = {};

        if(target.name == 'contact_email'){

          let validContactEmail =  validation.emailKeyUpValidate(target?.value, true);
          if(!validContactEmail){           
            return false;
          }     
            
        }
        
        if(target.name == 'support_email'){

          let validSupportEmail =  validation.emailKeyUpValidate(target?.value, true);
          if(!validSupportEmail){
            return false;
          }   
     
        }

        obj[target.name] = target.value;                     
          
        setStep1({ ...step1, ...{ [target.name]: target.value } });         
        validateStep(0, target); 
    
        
    };

    const handleInputChangeStep2 = (e) => {
        const { target } = e;
        var obj = {};
        obj[target.name] = target.value;    
        setStep2({ ...step2, ...{ [target.name]: target.value } });
        validateStep(1, target); 
    
    };

    const handleInputChangeStep3 = (e) => {
        const { target } = e;
        var obj = {};
        obj[target.name] = target.value;    
        setStep3({ ...step3, ...{ [target.name]: target.value } });
        validateStep(2, target);     
    };
    
    const hasError = (key) => {
        return formEror.indexOf(key) !== -1;
    };

    const handleCheckbox = (e) => {

        const { target } = e;
        var obj = {};
        const cid  = target.value;
        let find = cselected.findIndex((v) => v == cid);
    
        if (find > -1) {
              cselected.splice(find, 1);		
        } else {
              cselected.push(cid);	
        }       
         
        obj[target.name] = cselected
        setStep3({ ...step3, ...{ [target.name]: cselected } });
        validateStep(2, target); 
    
    };

    const channelSelected = (cid) => {
    
        let find = cselected?.findIndex((v) => v  == cid);
        if (find > -1) {
          return true;
        }
        return false;
    };

    const handleChangeFileLogo = (event) => {

        let fileList:any;
        fileList = event.target.files;
        const { target } = event;

        if (!fileList) return;
        if (fileList.length == 0) return;
    
        const { name: fileName, size: fileSize } = fileList[0];
        
        updLogoStatus.uploadMessage = "";
        updLogoStatus.uploadValid = false;
    
        if (!isImage(fileName)) {
            updLogoStatus.uploadMessage = translate['regis.fileupload.filetype.error'];
            setUpdLogoStatus({ ...updLogoStatus });
            return;
        }
    
        if (fileSize > 200000) {
            
            updLogoStatus.uploadMessage = translate['regis.fileupload.max200.error'];
            setUpdLogoStatus({ ...updLogoStatus });
            return;
        }
      
        updLogoStatus.uploadMessage = translate['regis.fileupload.filename'] +` : ${fileName}`;
        updLogoStatus.uploadValid = true;
        setFileLogoUpload(URL.createObjectURL(fileList[0]));
        setLogo(fileList[0]);

        var obj = {};
        obj[target.name] = fileList[0];    
        setStep2({ ...step2, ...{ [target.name]: target.value } });                       
      
    };

    const handleChangeFile = (event ,i:number) => {

        let fileList:any;
        const objName = event.target.name
        var obj = {};
        
        fileList = event.target.files;     
        if (!fileList) return;
        if (fileList.length == 0) return;
    
        const { name: fileName, size: fileSize } = fileList[0];
       
        updFileStatus[i].uploadMessage = "";
        updFileStatus[i].uploadValid = false;
       
        if (!isImagePdf(fileName)) {
            updFileStatus[i].uploadMessage = translate['regis.fileupload.filetype.error'];
            setUpdFileStatus({ ...updFileStatus });
            return;
        }
    
        if (fileSize > 200000) {
            updFileStatus[i].uploadMessage = translate['regis.fileupload.max200.error'];
            setUpdFileStatus({ ...updFileStatus });
            return;
        }
    
        updFileStatus[i].uploadValid = true;
        updFileStatus[i].uploadMessage = translate['regis.fileupload.filename']+` : ${fileName}`;

        setFileUpload({ ...fileUpload, ...{ [objName]: URL.createObjectURL(fileList[0]) } });  
        setUpdFileStatus({ ...updFileStatus });
        setFiles({ ...files, ...{ [objName]: fileList[0] } });  
      
    };

    const validateStep = (stepNum:number, target :any) =>{

        let errors = formEror

        if(stepNum == 0){

            if(target.name == 'merchant_name'){

              if(target.value == ''){
                errors.push("merchant_name");     
              }
              else{
                const index = errors.indexOf("merchant_name");

                if (index !== -1) {
                  errors.splice(index, 1);
                }
              }

            }

            if(target.name == 'trading_name'){

              if(target.value == ''){
                errors.push("trading_name");     
              }
              else{

                const index = errors.indexOf("trading_name");

                if (index !== -1) {
                  errors.splice(index, 1);
                }

              }
                
            }

            if(target.name == 'address'){

              if(target.value == ''){
                errors.push("address");     
              }
              else{

                const index = errors.indexOf("address");

                if (index !== -1) {
                  errors.splice(index, 1);
                }
              }                
                
            }
            
            if(target.name == 'person_type'){

              if(target.value == ''){
                errors.push("person_type");     
              }
              else{

                const index = errors.indexOf("person_type");

                if (index !== -1) {
                  errors.splice(index, 1);
                }

              }
                
            }

            if(target.name == 'mobile'){

              let validMobile =  validation.phoneNumberValidate(target.value, false);
              const index = errors.indexOf("mobile");

              if(!validMobile){

                if(index == -1)
                  errors.push("mobile");     
              }
              else{

                if (index !== -1) {
                  errors.splice(index, 1);
                }

              }

            }

            if(target.name == 'phone'){

              let validMobile =  validation.phoneNumberValidate(target.value, false);
              const index = errors.indexOf("phone");
              
              if(!validMobile){

                  if(index == -1)
                    errors.push("phone");     
              }
              else{

                if (index !== -1) {
                  errors.splice(index, 1);
                }

              }

            }                   
         
            if(target.name == 'contact_person'){

              if(target.value == ''){
                errors.push("contact_person");     
              }
              else{

                const index = errors.indexOf("contact_person");

                if (index !== -1) {
                  errors.splice(index, 1);
                }

              }
              
            } 

            if(target.name == 'contact_email'){

              let validContactEmail =  validation.emailValidate(target?.value, false);
              const index = errors.indexOf("contact_email");
             
              if(!validContactEmail){
                  if(index == -1)
                    errors.push("contact_email");     
              }
              else{               

                if (index !== -1) {
                  errors.splice(index, 1);
                }

              }
                
            }

            if(target.name == 'support_email'){

              let validSupporEmail =  validation.emailValidate(target?.value, false);
              const index = errors.indexOf("support_email");

              if(!validSupporEmail){

                if(index == -1)
                  errors.push("support_email");  

              }
              else{

                if (index !== -1) {
                  errors.splice(index, 1);
                }

              }
                
            }
           
            if(target.name == 'fax'){

              let validFax =  validation.phoneNumberValidate(target.value, false);
              const index = errors.indexOf("fax");

              if(!validFax){

                if(index == -1)
                  errors.push("fax");  

              }
              else{

                if (index !== -1) {
                  errors.splice(index, 1);
                }

              }
                
            }

            setFormEror(errors);
      
            if(errors.length > 0){
              return false;
            }
      
        }

        if(stepNum == 1){       
          
          const index = errors.indexOf("error-upload-img");
  
          if(!updLogoStatus.uploadValid){


            if(index == -1)
              errors.push("error-upload-img");

            updLogoStatus.uploadMessage = translate['regis.fileupload.empty.error']
            setUpdLogoStatus({ ...updLogoStatus })
                
          }
          else{

            
            if (index !== -1) {
              errors.splice(index, 1);
            }

          }

          if(target.name == 'business_type'){

            if(target.value == ''){
              errors.push("business_type");     
            }
            else{

              const index = errors.indexOf("business_type");

              if (index !== -1) {
                errors.splice(index, 1);
              }

            }
            
          } 

          if(target.name == 'bank_name'){

            if(target.value == ''){
              errors.push("bank_name");     
            }
            else{

              const index = errors.indexOf("bank_name");

              if (index !== -1) {
                errors.splice(index, 1);
              }

            }
            
          } 
                      
          setFormEror(errors);
          if(errors.length > 0){
            return false
          }

          return true

        }

        if(stepNum == 2){         
          
          if(target.name == 'business_type'){

            if(target.value == ''){
              errors.push("business_type");     
            }
            else{

              const index = errors.indexOf("business_type");

              if (index !== -1) {
                errors.splice(index, 1);
              }

            }
            
          } 

          if(target.name == 'bank_name'){

            if(target.value == ''){
              errors.push("bank_name");     
            }
            else{

              const index = errors.indexOf("bank_name");

              if (index !== -1) {
                errors.splice(index, 1);
              }

            }
            
          } 

          if(target.name == 'bank_account'){

            if(target.value == ''){
              errors.push("bank_account");     
            }
            else{

              const index = errors.indexOf("bank_account");

              if (index !== -1) {
                errors.splice(index, 1);
              }

            }
            
          } 

          if(target.name == 'payment_method'){         

            if(cselected.length == 0){
              errors.push("payment_method");     
            }
            else{

              const index = errors.indexOf("payment_method");

              if (index !== -1) {
                errors.splice(index, 1);
              }
            }
            
          } 
                      
          setFormEror(errors);
          if(errors.length > 0){
            return false
          }

          return true
          
        }

    }

    const validateAllInput = (indx:number) => {

      let errors = [];
      
      if(indx == 0){

        let validContactEmail =  validation.emailValidate(step1.contact_email, false);
        let validSupporEmaill =  validation.emailValidate(step1.support_email, false);
  
        let validMobile =  validation.phoneNumberValidate(step1.mobile, false);
        let validPhone =  validation.phoneNumberValidate(step1.phone, false);
  
        if(step1.merchant_name == ''){
          errors.push("merchant_name");
        }
  
        if(step1.trading_name == ''){
          errors.push("trading_name");
        }
  
        if(step1.address == ''){
          errors.push("address");
         
        }
  
        if(step1.person_type == ''){
          errors.push("person_type");
         
        }
  
        if(!validMobile){
          errors.push("mobile");
         
        }
  
        if(!validPhone){
          errors.push("phone");
         
        }
  
        if(!validContactEmail){
          errors.push("contact_email");
         
        }
  
        if(step1.contact_person == ''){
          errors.push("contact_person");
         
        }
  
        if(!validSupporEmaill){
          errors.push("support_email");
         
        }
  
        setFormEror(errors);
  
        if(errors.length > 0){
          return false
        }

        return true
  
      }

      if(indx == 1){
   
            
        if(!updLogoStatus.uploadValid){
  
          errors.push("error-upload-img");
           
          if(!updLogoStatus.uploadMessage){
            updLogoStatus.uploadMessage = translate['regis.fileupload.empty.error']
            setUpdLogoStatus({ ...updLogoStatus })
          }                 
               
        }
                  
        setFormEror(errors);
        if(errors.length > 0){
          return false
        }

        return true
  
      }
  
      if(indx == 2){        
          
        if(step3.business_type == ''){
            errors.push("business_type");     
        }
        else{

            const index = errors.indexOf("business_type");
            if (index !== -1) {
              errors.splice(index, 1);
            }
        }
                   
        if(step3.bank_name  == ''){
            errors.push("bank_name");     
        }
        else{

          const index = errors.indexOf("bank_name");

          if (index !== -1) {
            errors.splice(index, 1);
          }

        }          
         
        if(step3.bank_account  == ''){
            errors.push("bank_account");
        }     
        else{

            const index = errors.indexOf("bank_account");
            if (index !== -1) {
              errors.splice(index, 1);
            }
          
        } 

        if(step3.payment_method){         

          if(cselected.length == 0){
            errors.push("payment_method");     
          }
          else{

            const index = errors.indexOf("payment_method");
            if (index !== -1) {
              errors.splice(index, 1);
            }
          }
          
        } 
                    
        setFormEror(errors);
        if(errors.length > 0){
          return false
        }

        return true

      }  

    }

    return {
        setDoc,
        setFiledFiles,
        setUpdFileStatus,
        setUpdLogoStatus,
        setFileUpload,
        setFileLogoUpload,
        setFormEror,
        setStepSuccess,
        setStep1,
        fileLogoUpload,
        files,
        filedFiles,
        step1,
        step2,
        step3,
        doc,
        stepSuccess,
        updLogoStatus,
        updFileStatus,
        logo,
        channelSelected,
        hasError,
        handleInputChangeStep1,
        handleInputChangeStep2,
        handleInputChangeStep3,
        handleCheckbox,
        handleChangeFileLogo,
        handleChangeFile,
        validateStep,
        validateAllInput    
    }

}

export default RegisterHook