import { useTable, usePagination, useSortBy } from "react-table";
import React,{Fragment, useEffect, useState, useRef} from 'react';
import {formatDateYearEN, formatDateYearNoTimeEN} from 'common/Manager/Helper';
import TransactionColorBadge from 'common/enumerations/TransactionColorBadge';
import NumberFormat from 'utilities/NumberFormat'

import DownloadBtn from 'modules/receipt/component/DownloadBtn'



const Datatable = ({ setPage, columns, data, currentpage, setPerPage, perPage, totalPage, getTransaction, countRow, onView,  setSort}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize  , sortBy },
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state, currentpage]
        );
      },
      initialState: { pageIndex: 0 , pageSize : perPage},
      manualPagination: true,
      manualSortBy: true,
      autoResetSortBy: false,
      autoResetPage: false,
      pageCount: totalPage  //you can handle this calculation in your fetchdata method

    },
    useSortBy,
    usePagination
  );

  // ======================================================================================
  const [dataRow, setDataRow] = useState(
		document.querySelectorAll('#transactions-data tbody tr')
	)

	const activePag = useRef(0)
 
	  // Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < dataRow.length; ++i) {
			if (i >= frist && i < sec) {
				dataRow[i].classList.remove('d-none')
			} else {
				dataRow[i].classList.add('d-none')
			}
		}
	}

  // Active pagginarion
  activePag.current === 0 && chageData(0, perPage)

	// paggination 
  let paggination = Array(Math.ceil(countRow / pageSize))
    .fill()
    .map((_, i) => i + 1)

	const onClick = (i) => {
   
    setPage(i)
		activePag.current = i
    getTransaction(i, pageSize)
    gotoPage(i);
	
	}
 
  useEffect(() => {

    if(sortBy?.length > 0 ){

      setPageSize(pageSize)

      const field = sortBy[0].id;
      const desc = (sortBy[0].desc) ?  'DESC' : 'ASC' ;

      const sort = {
        field : field,
        sortby: desc
      }

      setSort(sort)
      getTransaction(currentpage, pageSize, sort)

    }
    
    
  }, [sortBy]);

  return (
    <> 
      <table 	className='table display mb-4 dataTablesCard  dataTable no-footer' {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                    {column.isSorted
                        ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                        : ''}
                    </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {        
            (page.length > 0 && page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => { 

                    const receipt_tax_id = cell.row.original.receipt_tax_id
                    
                    if(cell.column.id == 'created_at'){

                      return (<td {...cell.getCellProps()}>                        
                        {formatDateYearNoTimeEN(new Date(cell.value))}
                      </td>)

                    }

                    if(cell.column.id == 'receipt_tax_id'){ 

                      return (<td {...cell.getCellProps()}>{(cell.value)}</td>)    

                    } 

                    if(cell.column.id == 'status'){   

                      return (<td {...cell.getCellProps()}><TransactionColorBadge transactionStatus={cell.value}></TransactionColorBadge></td>)     
                    }
                   
                    if(cell.column.id == 'amount'){ 

                      return (<td {...cell.getCellProps()}>{NumberFormat.default.numberWithCommas(cell.value) }</td>)    

                    } 

                    if(cell.column.id == 'transaction_fee' || cell.column.id == 'amount_before_vat'){ 

                      return (<td {...cell.getCellProps()}>{NumberFormat.default.numberWithCommas(cell.value) }</td>)    

                    } 

                    if(cell.column.id == 'net_amount' || cell.column.id == 'withholding_tax' || cell.column.id == 'vat' || cell.column.id == 'total_amount'){ 

                      return (<td {...cell.getCellProps()}>{NumberFormat.default.numberWithCommas(cell.value) }</td>)    

                    } 

                    if(cell.column.id == 'product'){ 

                      return (<td {...cell.getCellProps()}>{cell.value}</td>)    

                    } 

                    if(cell.column.id == 'view'){ 

                      return (<td {...cell.getCellProps()}>
                          <a class="btn btn-primary btn-rounded mb-sm-0 " onClick={() => onView(receipt_tax_id)} ><i class="fa fa-search mr-3"></i>View</a>
                        </td>)    

                    }

                    if(cell.column.id == 'download'){ 

                      return (<td {...cell.getCellProps()}>
                        <DownloadBtn                        
                          receiptTaxId={receipt_tax_id}                   
                        />
                      </td>
                      )    

                    }
                    
                    return (                              
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );


                  })}
                </tr>
              );
            }))

            || 
            
            (page.length == 0 &&   
              <tr>
                <td className="no-data" colspan="20" >No Data</td>
              </tr> 
            ) 

          }
         
        </tbody>
      </table>

      <div className='d-sm-flex text-center justify-content-between align-items-center'>
        <div className='dataTables_info' id='example5_info'>
            Showing { page.length == 0 ? 0 : activePag.current * pageSize + 1} to{' '}
            {countRow > (activePag.current + 1) * pageSize
            ? (activePag.current + 1) * pageSize
            : countRow}{' '}
            of {countRow} entries
        </div>


        <div className='dataTables_paginate paging_simple_numbers' id='example5_paginate'>

          <select
            value={pageSize}
            onChange={(e) => {

              setPageSize(Number(e.target.value));
              getTransaction(0, Number(e.target.value))
              setPage(0)
              setPerPage(Number(e.target.value))
              activePag.current = 0            

            }}

            className="ml-2"
          >
            {[5, 7, 15, 50, 100, 250, 500].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>

          <a className='paginate_button previous pointer' onClick={() => activePag.current > 0 && onClick(activePag.current - 1)}>
            Previous
          </a>
          <span>
            {paggination.map((number, i) => (
              <a key={i}  className={`paginate_button pointer ${ activePag.current === i ? 'current' : '' } `} 
              onClick={() =>{ 
                onClick(i)               
              }
              
              }>
                {number}
              </a>
            ))}
          </span>
          <a className='paginate_button next pointer' onClick={() => activePag.current + 1 < paggination.length && onClick(activePag.current + 1)}>
            Next
          </a>
        </div>
      </div>	

    </>

  );
};

export default Datatable;
