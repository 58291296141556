import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import DropFile from "./DropFile";

import PageTitle from "jsx/layouts/PageTitle";
import { Dropdown } from "react-bootstrap";
import {QuickPayStatusEnum} from "common/enumerations/QuickPayObject";

//QuickPayStatusEnum
const FormEmail = (prop) => {

   const {sendLink, data} = prop
   return (
      
      <Fragment>

        	<div class= "page-titles form-head d-flex flex-wrap justify-content-between align-items-center mb-4">
				<h2 className="text-black font-w600 mb-0 mr-auto mb-2 pr-3">Send email</h2>
			</div>

         <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="card-body">
                     
                     <div className="ml-0 ml-sm-4 ml-sm-0">
                      
                        <div className="compose-content">
                     
                           {(data?.html) && (
                              <div dangerouslySetInnerHTML={{__html: `${data?.html}` }} /> 
                           )}    

                        </div>
                        {(data?.quickpay?.status == QuickPayStatusEnum.NON_PAID) && (
                        <div className="text-left mt-4 mb-5">
                           <button
                              className="btn btn-primary btn-sl-sm mr-2"
                              type="button"
                              onClick={()=> sendLink()}
                           >
                              <span className="mr-2">
                                 <i className="fa fa-paper-plane"></i>
                              </span>
                              Send
                           </button>
                           
                        </div>)}

                        {(data?.quickpay?.status == QuickPayStatusEnum.PAID) && (
                        <div class="mt-4">
                           <div class="alert alert-success left-icon-big alert-dismissible fade show">
                             
                              <div class="media">
                                 <div class="alert-left-icon-big">
                                       <span><i class="mdi mdi-check-circle-outline"></i></span>
                                 </div>
                                 <div class="media-body">
                                       <h5 class="mt-1 mb-2">Payment Successful</h5>
                                       <p class="mb-0">Link Payment Complete Successfully.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        )}

                        {(data?.quickpay?.status == QuickPayStatusEnum.EXPIRED) && (
                         <div class="mt-4">
                              <div class="alert alert-danger left-icon-big alert-dismissible fade show">
                                 <div class="media">
                                    <div class="alert-left-icon-big">
                                          <span><i class="mdi mdi-alert"></i></span>
                                    </div>
                                    <div class="media-body">
                                          <h5 class="mt-1 mb-2">Payment Expired!</h5>
                                          <p class="mb-0">Link payment expired</p>
                                    </div>
                                 </div>
                              </div>
                        </div>
                         )}


                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default FormEmail;
