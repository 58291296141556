import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import APIRequest from "common/interface/APIRequest";
import APISearchRequest from "common/interface/APISearchRequest";
import FilterModel from "common/models/FilterModel";


class GetQuickpayByIdRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/quickpay/getbyid";
  param:string
 

  constructor(param: string) {
    this.param = param   
  }

  makeBody() {

    return {
         id : this.param
    }
  }

  makeQuery() {}
}

export default GetQuickpayByIdRequest;
