import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";


class CheckOrderRefRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/quickpay/check-order-ref";
  product_id:string
  order_ref:string

  constructor(product_id: string, order_ref:string) {
    this.product_id = product_id 
    this.order_ref = order_ref   
  }

  makeBody() {

    return {
      product_id :this.product_id,
      order_ref : this.order_ref

    }
  }

  makeQuery() {}
}

export default CheckOrderRefRequest;
