import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Modal } from 'react-bootstrap';
import { DropdownBlog } from 'jsx/components/Dashboard/Invoices';
import { Redirect, useParams } from "react-router-dom";
import TransactionDetailRequest from "modules/transaction/billpayment/APIRequest/TransactionDetailRequest";
import APIManager from "common/Manager/APIManager";
import FilterModel from "common/models/FilterModel";
//import TransactionHook  from 'modules/transaction/billpayment/Hook/TransactionHook';
// import TransactionsDetail from "jsx/components/backTransactionsBillpayment/details"

import TransactionsDetail from "modules/transaction/billpayment/component/details"

type Params = {
	id: string;
};

const detail = (props: any) => {

	let page = 'edit-contact';
	let title = 'Credit Card'

	const [transactionData, setTransactionData] = useState({});
	const { id } = useParams<Params>();

	const getTransactionDetail = async (ids: string) => {

		const apiRequest = new TransactionDetailRequest(ids);
		const items = await APIManager.default.fetch(apiRequest);
		setTransactionData(items.data.data);

	};

//	const {htmlStatus} = TransactionHook();

	if (page == 'edit-contact') {
		title = 'Edit Contact'
	}

	if (page == 'edit-logo') {
		title = 'Edit Logo'
	}

	useEffect(() => {

		getTransactionDetail(id);

	}, [])

	return (
		<>
			<TransactionsDetail 
				data={transactionData}
				key={1} 
				>
			</TransactionsDetail>
		</>
	)


}

export default detail;