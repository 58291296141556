import PeriodDate from "modules/dashboard/models/PeriodDate";
import moment from "moment";
import NumberFormat from 'utilities/NumberFormat'

export function formatDate(date: Date, getYear: boolean = false) {
  let data = "";
  if (getYear === true) {
    data =
      getDate(date) +
      " " +
      date.toLocaleString("default", { month: "short" }) +
      " " +
      (date.getFullYear() + 543);
  } else {
    data =
      getDate(date) + " " + date.toLocaleString("default", { month: "short" });
  }
  return data;
}

export function getDate(data: Date) {
  return data.getDate() < 10 ? "0" + data.getDate() : data.getDate();
}

export function formatDateForApi(data: Date) {
  const date =
    data.getFullYear() +
    "-" +
    (data.getMonth() + 1 < 10
      ? "0" + (data.getMonth() + 1)
      : data.getMonth() + 1) +
    "-" +
    (data.getDate() < 10 ? "0" + data.getDate() : data.getDate());
  return date;
}

export function formatDateForAutocomplete(data: Date) {
  const date: any =
    getDayTh(data.getDay()) +
    ". " +
    getDate(data) +
    " " +
    subMonthTh(data.getMonth()) +
    " " +
    getYearTH(data.getFullYear());
  return date;
}

export function formatMYForAutocomplete(data: Date) {
  let date;
  date = subMonthTh(data.getMonth()) + " " + getFullYearTH(data.getFullYear());
  if ((date as any) === false) {
    return "-";
  }
  return date;
}

export function formatfullMonth(data: Date) {
  const date = getMonthTh(data.getMonth());
  return date;
}
export function formatsubMonth(data: Date) {
  const date = subMonthTh(data.getMonth());
  return date;
}

export function formatYear(data: Date) {
  const date = getYearTH(data.getFullYear());
  return date;
}

export function formatFullYear(data: Date) {
  const date = getFullYearTH(data.getFullYear());
  return date;
}

export function formatDateShort(data: Date) {
  const date =
    data.getFullYear() +
    "-" +
    (data.getMonth() + 1 < 10
      ? "0" + (data.getMonth() + 1)
      : data.getMonth() + 1) +
    "-" +
    (data.getDate() + 1 < 10 ? "0" + data.getDate() : data.getDate());
  return date;
}

export function getFullYearTH(year: any) {
  const newYears = parseInt(year, undefined) + 543;
  return newYears || year;
}
export function getYearTH(year: any) {
  const Year = parseInt(year, undefined) + 543;
  let newYears = "" + Year;
  newYears = newYears.substring(2, 4);
  return newYears || year;
}

export function getYearAndFutureTH() {
  const year = new Date().getFullYear() as any;
  const newYears = parseInt(year, undefined) + 543;
  const yearFuture = (new Date().getFullYear() + 1) as any;
  const newYearFuture = parseInt(yearFuture, undefined) + 543;
  return newYears + " - " + newYearFuture || year + "-" + yearFuture;
}

export function formatDateYearEN(date: Date) {
  return (
    addZero2digit(date.getDate()) +
    " " +
    subMonthEn(date.getMonth()) +
    " " +
    date.getFullYear() +
    " / " +
    addZero2digit(date.getHours()) +
    ":" +
    addZero2digit(date.getMinutes())
  );
}

export function formatDateYearNoTimeEN(date: Date) {
  return (
    addZero2digit(date.getDate()) +
    " " +
    subMonthEn(date.getMonth()) +
    " " +
    date.getFullYear()
  );
}

export function formatDateYearTH(data: Date) {
  return (
    addZero2digit(data.getDate()) +
    " " +
    subMonthTh(data.getMonth()) +
    " " +
    getFullYearTH(data.getFullYear()) +
    " / " +
    addZero2digit(data.getHours()) +
    ":" +
    addZero2digit(data.getMinutes())
  );
}

export function formatDay(data: Date) {
  return (
    addZero2digit(data.getDate()) +
    "/" +
    addZero2digit(data.getMonth() + 1) +
    "/" +
    data.getFullYear()
  );
}

export function formatTime(data: Date) {
  return (
    addZero2digit(data.getHours()) +
    ":" +
    addZero2digit(data.getMinutes()) +
    ":" +
    addZero2digit(data.getSeconds())
  );
}

export function getMonthTh(month: any) {
  const monthsTH = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];
  return monthsTH[month] || month;
}
export function subMonthTh(month: any) {
  const monthsTH = [
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค.",
  ];
  return monthsTH[month] || month;
}

export function subMonthEn(month: any) {
  const monthsEN = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthsEN[month] || month;
}

export function getDayTh(day: any) {
  const dayTH = ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"];
  return dayTH[day] || day;
}

export function getDiffDate(startDate: Date, endDate: Date) {
  if (startDate && endDate) {
    const Difference_In_Time = endDate.getTime() - startDate.getTime();
    return Math.floor(Difference_In_Time / (1000 * 3600 * 24));
  } else {
    return 0;
  }
}

function addZero2digit(val: number) {
  return val < 10 ? "0" + val : val;
}

export function isImage(filename: string) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "png":
      //etc
      return true;
  }
  return false;
}

export function isImagePdf(filename: string) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "png":
    case "pdf":      
      //etc
      return true;
  }
  return false;
}



function getExtension(filename: string) {
  var parts = filename.split(".");
  return parts[parts.length - 1];
}

export function formatDay2(data: Date) {
  return (
    addZero2digit(data.getDate()) +
    "-" +
    addZero2digit(data.getMonth() + 1) +
    "-" +
    data.getFullYear()
  );
}

export function formatInvert(date: string) {
  const dateArr = date.split("-");
  return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
}

export function markNumber(value:number) {

 
  if(value > 0){
    return  "+" + value.toString()
  }


  return   value
}

export function markNumberFormatPercent(value:number) {


  const valueFormat = NumberFormat.default.numberWithCommasWithOutDot(value?.toString())
 
  if(value > 0){
    return  "+" + valueFormat?.toString()
  }


  return   valueFormat
}

export function generateFileName(name) {

  const nowDate = new Date().getTime()
  const random  = Math.floor(Math.random() * 899) + 100;
  const pattern = (nowDate * random)?.toString()

  const extension = getExtension(name)
  return  pattern.slice(-13)+"."+extension
}

export function padWithLeadingZeros(num :number , totalLength: number) {
  return String(num).padStart(totalLength, '0');
}

export function formatDateISO(data: Date) {
  const date =
    data.getFullYear() +
    "-" +
    (data.getMonth() + 1 < 10
      ? "0" + (data.getMonth() + 1)
      : data.getMonth() + 1) +
    "-" +
    (data.getDate() < 10 ? "0" + data.getDate() : data.getDate());
    return new Date(date).toISOString();
}

export function formatDateUTC(data: Date) {
  const date =
    data.getFullYear() +
    "-" +
    (data.getMonth() + 1 < 10
      ? "0" + (data.getMonth() + 1)
      : data.getMonth() + 1) +
    "-" +
    (data.getDate() < 10 ? "0" + data.getDate() : data.getDate());
    return new Date(date);
}

export function startDateISO(st: Date) {

  st.setHours(0, 0, 0, 0);
  return new Date(st).toISOString()
}

export function endDateISO(end: Date) {
     end.setHours(23, 59, 59, 999);
     return new Date(end).toISOString()
}

export function getPeriodWeekly(data: Date) : PeriodDate {

  // Get the current date
  const today = new Date();

  // Get the day of the week (0 = Sunday, 1 = Monday, etc.)
  const dayOfWeek = today.getDay();

  // Calculate the start and end dates for the current week
  const start = new Date(today);
  start.setDate(start.getDate() - dayOfWeek);
  start.setHours(0, 0, 0, 0);

  const end = new Date(today);
  end.setDate(end.getDate() + (6 - dayOfWeek));
  end.setHours(23, 59, 59, 999);

  const startDate = start.toISOString();
  const endDate = end.toISOString();
 
 return {
     start_date : startDate,
     end_date: endDate
 }
 
}

export function getPeriodToday(date: Date) : PeriodDate {

  return {
    start_date : startDateISO(date),
    end_date: endDateISO(date)
  }

}

export function getPeriodMonthly(today: Date) : PeriodDate {

  const year = today.getFullYear();
  const month = today.getMonth(); // getMonth() returns 0-11, so add 1 to get the actual month
  const startOfMonth =  new Date(year, month, 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  
  startOfMonth.setHours(0, 0, 0, 0); // set time to midnight
  endOfMonth.setHours(23, 59, 59, 999); // set time to last millisecond of day
  
  const startDateIso = startOfMonth.toISOString();
  const endDateIso = endOfMonth.toISOString();
   
  return {
    start_date : startDateIso,
    end_date: endDateIso
  }

}

export function getPeriodTop30Day(today: Date) : PeriodDate {

  let p = 30;
  let backDay = 1;

  const startOfMonth = new Date(new Date().setDate(today.getDate() - p));
  const endOfMonth = new Date(new Date().setDate(today.getDate() - backDay));
  
  startOfMonth.setHours(0, 0, 0, 0); // set time to midnight
  endOfMonth.setHours(23, 59, 59, 999); // set time to last millisecond of day
  
  const startDateIso = startOfMonth.toISOString();
  const endDateIso = endOfMonth.toISOString();
  
  return {
    start_date : startDateIso,
    end_date: endDateIso
  }

}

export function getPeriod30(today: Date) : PeriodDate {

  let p = 30;
  let backDay = 1;
 
  const start30Day = new Date(new Date().setDate(today.getDate() - (p) ));
  const end30Day = new Date(new Date().setDate(today.getDate() - backDay));
  start30Day.setHours(0, 0, 0, 0); // set time to midnight
  end30Day.setHours(23, 59, 59, 999); // set time to last millisecond of day
    
  return {
    start_date : start30Day.toISOString(),
    end_date: end30Day.toISOString()
  }

}

export function getPeriod60(today: Date) : PeriodDate {

  let p2 = 60;
  let p1 = 30;
  let backDay = 1;

  const start60Day =  new Date(new Date().setDate(today.getDate() - (p2) ))  // currentDate.getDate() - 60
  const end60Day = new Date(new Date().setDate(today.getDate() - (p1 + backDay)));

  start60Day.setHours(0, 0, 0, 0); // set time to midnight
  end60Day.setHours(23, 59, 59, 999); // set time to last millisecond of day
  
  return {
    start_date : start60Day.toISOString(),
    end_date: end60Day.toISOString()
  }

}

export function getPeriod1year(today: Date) : PeriodDate {
    
  let p1 = 365;
  let backDay = 1;
 
  const start1Year =  new Date(new Date().setDate(today.getDate() - (p1) ))  // currentDate.getDate() - 60
  start1Year.setHours(0, 0, 0, 0); // set time to midnight

  const end30Day = new Date(new Date().setDate(today.getDate() - backDay));
  end30Day.setHours(23, 59, 59, 999);

  return {
    start_date : start1Year.toISOString(),
    end_date: end30Day.toISOString()
  }

}

export function getPeriod2year(today: Date) : PeriodDate {

  let p2 = 730;
  let p1 = 365;
  let backDay = 1;

  const start2Year =  new Date(new Date().setDate(today.getDate() - (p2) ))  // currentDate.getDate() - 30
  start2Year.setHours(0, 0, 0, 0); // set time to midnight
  
  const end2Year =  new Date(new Date().setDate(today.getDate() - (p1 + backDay)))  // currentDate.getDate() - 60
  end2Year.setHours(23, 59, 59, 999); // set time to last millisecond of day

  return {
    start_date : start2Year.toISOString(),
    end_date: end2Year.toISOString()
  }

}


