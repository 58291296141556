import React,{Fragment, useEffect, useState, useRef} from 'react';

import SearchTransactionRequest from "modules/receipt/APIRequest/SearchTransactionRequest";
import CountTransactionRequest from "modules/receipt/APIRequest/CountTransactionRequest";

import APIManager from "common/Manager/APIManager";
import FilterModel from "common/models/FilterModel";
import {formatDateForApi} from 'common/Manager/Helper'
import Receipt from 'modules/receipt/component';
import ReceiptViewPage from "modules/receipt/component/ReceiptViewPage";
import ReceiptDetailRequest from 'modules/receipt/APIRequest/ReceiptDetailRequest';
import { Link, useParams } from 'react-router-dom';

type Params = {
	receipt_tax_id: string;
};

const View = (prop) => { 

	const [receiptData, setReceiptData] = useState({});
  	const { receipt_tax_id } = useParams<Params>();
 
	const getTransaction = async (receipt_tax_id:string) => {			
	
		const apiRequest = new ReceiptDetailRequest(receipt_tax_id);
		const items = await APIManager.default.fetch(apiRequest);
		setReceiptData(items.data.data);

	};
		
	useEffect( () => {

	getTransaction(receipt_tax_id);	

	}, [])


  return (
    <>
      <div className= "page-titles form-head d-flex flex-wrap justify-content-between align-items-center mb-4">
				<h2 className="text-black font-w600 mb-0 mr-auto mb-2 pr-3">Receipt/Tax Invoice</h2>
			</div>

      {/* <div className="row page-titles mx-0">
				<div className="col-sm-6 p-0">
				<div className="welcome-text">
					<h2 className="text-black font-w600 mb-0">Receipt/Tax Invoice</h2>
				</div>
				</div>
				<div className="col-sm-6 p-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
				<ol className="breadcrumb">
					<li className="breadcrumb-item active">
					<Link to={"/transaction/creditcard"}>Detail</Link>
					</li>
					<li className="breadcrumb-item">
					<Link to={"#"}>#{}</Link>
					</li>
				</ol>
				</div>
			</div> */}

      <div className="row">

        <div className="col-xl-12">

          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <ReceiptViewPage receiptData={receiptData}></ReceiptViewPage>
            </div>
          </div>

        </div>

      </div>
    </>
  );
};
export default View;
