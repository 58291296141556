import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'
import SearchUser from 'common/interface/SearchUser';
import ThaiqrRequestModel from '../models/ThaiqrRequestModel';


class GetMyQRRequest  {

  method: HTTPMethodEnum = HTTPMethodEnum.GET
  url: string = '/myqr/get'
  token: string
  qrRequest: ThaiqrRequestModel
  
  constructor () {  

  }

  makeBody() {
    return this.qrRequest; 

  }
  makeQuery() { }

  makeHeader() { }

}

export default GetMyQRRequest