
import styled from 'styled-components';
import { CssBaseline, Grid, makeStyles, createStyles, Theme, Paper, Hidden, Box } from "@material-ui/core"

export const Container = styled.div`
margin-left: auto;
margin-right: auto;    

color:#000000;

background-color: white;
padding:30px;
font-family: NotoSansRegular, NotoSansRegularThai;

@media only screen and (max-width: 480px) {
    
}

.box-span {  

  font-size: 14px;
  font-weight: 400;

  margin-left:9px;
  padding:7px;
  box-sizing: border-box;
  color: #000000;
  border: 0.5px solid #000000;
  border-radius: 4px;
 
}

.h-invoice {  

  font-size: 15px;
  font-style: normal;
  font-weight: 700;

  line-height: 13.62px;
  color: #0FBED7;
}

.h-invoice-en {
 
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  
  line-height: 13.62px;
  color: #000000;

}

.span-red {
  color :red;
}

.span-tax-id {
  display: inline-block;
  width : 190px;
  font-size: 14px;
}

.row-div {
  line-height: 18px;
}

.text-red {
  color: #FF0000;
}

.box-profile {
  display: flex;         
}

.box-address {
  
  margin-left:9px;
  padding:7px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 0.5px solid #000000;
  border-radius: 4px;
  margin-bottom:13px;
   
}

.box-left {
  width:700px;
  padding-right: 30px;
}

.content-div {
  font-size: 14px;
  font-weight: 400;
  width:390px;
  line-height: 16px;
  text-align: left;
}

.box-content {
  
  margin-left:9px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-left: 0.5px solid #000000;
  border-right: 0.5px solid #000000;
  border-bottom: 0.5px solid #000000;
  border-radius: 4px;
  margin-bottom:13px;
 
}

.h-company {
  color:#000000;
  font-size: 16px;
}

.h-merchant {
  color: #FF0000;
}

thead {  
  background: #EDF2FD;

  text-align:center;
 
}
table, th {
  border: 1px solid #FFFFFF;
  
}

.border-class {
  border: 1px solid #FFFFFF;
}

table .color-content {
  color: #FF0000;
}

table .footer-content {
  background: #EDF2FD;
}

table .content-detail {
  font-size:14px;
  background: #ffffff;
  color: #FF0000;
}

table .border-content {
  border: 1px solid #FFFFFF;
  font-family: NotoSansRegular, NotoSansRegularThai;
  font-size:14px;
}

table .waring-text {
  font-size:14px;
}

table .text-desc {
  font-size:14px;
  padding-left:20px;
}


table .text-summary-label {

  font-weight: 400;
  text-align: right;
  font-size:14px;
  padding-right:20px;
  padding-top:4px;
  padding-bottom:4px;
}

table thead {
  font-size:14px

}

.text-summary-value {
  font-weight: 400;
  color: #FF0000;
  text-align: right;
  font-size:14px;
  padding-right:20px;
  padding-top:4px;
  padding-bottom:4px;
}

.text-amount-label {

  font-weight: 700;
  text-align: right;
  font-size:14px;
  padding-right:20px;
  padding-top:4px;
  padding-bottom:4px;

}

.text-amount-value {

  color: #FF0000;
  font-weight: 700;
  text-align: right;
  font-size:14px;
  padding-right:20px;
  padding-top:4px;
  padding-bottom:4px;

}

table .text-amount {

  font-weight: 700;
  color: #FF0000;
  font-size:14px;
  padding-left:20px;
  padding-top:9px;
  padding-bottom:18px;
  
}

.header-top {  
  margin-bottom:30px;  
}

.pad-left {
  padding-left: 8px;
}

.m-left {
  margin-left: 6px;
}


`;


