import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import FilterModel from "common/models/FilterModel";



class BusinessTypeRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.GET;
  url: string = "/register/business-type";
  param:FilterModel
  locale = 'en'

  constructor(locale: string) {
    this.locale = locale
  }

  

  makeBody() {
    return {
      locale : this.locale
    }

  }

  makeQuery() {
  }
}

export default BusinessTypeRequest;


