import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'


class ForgetRequest  {

  method: HTTPMethodEnum = HTTPMethodEnum.POST
  url: string = '/password/forget'
  param: any
  
  constructor (param :any) {
    this.param = param
  }

  makeBody() { 
    return this.param 
    
  }
  makeQuery() {}
  makeHeader() {}

}

export default ForgetRequest