import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        top: 0,
        background: "#FFFFFF",
        boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.04)",
        position : "fixed",
        width : "100%",
        height: '90px',
        zIndex: 3,
    },
    root_mobile: {
        top: 0,
        background: "#FFFFFF",
        boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.04)",
        position : "fixed",
        width : "100%",
        zIndex: 3,
        
    },
    noPadding: {
        padding: theme.spacing(2, 3, 2, 3),
    },
    logo: {
        width: '150px',
    },
    icon_header: {
        height: '32px',        
    },   
    line: {     
      // borderRight: '1px solid #3E425B',                
        paddingLeft: '30px;',
        paddingRight: '30px;',     
    },

    line_img :{
        verticalAlign:"middle"
    },
    box_img : {
        paddingRight: '18px;',
    },
    box_flag : {
        // paddingLeft: '28px;',
    },
    copyright :{
        position: "absolute",
        bottom: "107px",
        // left: 0;
    },   
    container: {         
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '1440px',
        height: '90px',
        paddingLeft: '166px',
        paddingRight: '166px',
        display:"flex",
        alignItems:"center",  
    },     
    container_mobile: {
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '80px',
        paddingLeft: '15px',
        paddingRight: '15px',
        display:"flex",
        alignItems:"center", 
    }, 
    btn_flag : {
        padding: 0,
    },
    img_flag :{
        weight: 40,
        height: 40,
        background: "#FFFFFF",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
        borderRadius: "50%",
        padding: "3px"
    },
    text_language : {
        
        fontStyle: "normal",
        fontFamily: `'NotoSansSemiBold','NotoSansSemiBoldThai'`,    
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "14px",
        /* identical to box height, or 100% */
        textAlign: "center",
        color: "#3E425B",
        marginLeft: "17px"
    },    
   
    "@media only screen and (max-width: 992px) ": {
        root: {
            display: "none",
        },
    }, 
    "@media only screen and (min-width: 992px) ": {
        root_mobile: {
            display: "none",
        },
        container: {         
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            height: '90px',
            paddingLeft: '5%',
            paddingRight: '5%',       
            display:"flex",
            alignItems:"center",  
            position : "relative"
        },  
    },     
    
    "@media only screen and (min-width: 1200px) ": {
        container: {         
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            height: '90px',
            paddingLeft: '11.5%',
            paddingRight: '11.5%',     
            display:"flex",
            alignItems:"center",  
        },  
    },   
    
      
 

   


  

}));

export default useStyles