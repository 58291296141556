import React, { useState } from "react";
import axios from "axios";
import ReceiptPdfRequestRequest from "modules/receipt/APIRequest/ReceiptPDFRequest";

const DownloadBtn = (prop) => {
    
  const { receiptTaxId } = prop;  
  const [isLoading, setIsLoading] = useState(false)
  const onPDF = async (id:any) => {
      
      const apiRequest = new ReceiptPdfRequestRequest(id);
      const formData = new FormData();
      formData.append("receipt_tax_id", id);

      setIsLoading(true);
      
      axios
      .post(
          apiRequest.url,
          {
              receipt_tax_id: id,
          },
          {
          responseType: "blob",
          }
      )
      .then((response) => {
              
          const url = window.URL.createObjectURL(
          new Blob([response.data], {
              type: "application/pdf",
          })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
          "download",
          `Receipt-Tax-Invoice-${id}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();

          setIsLoading(false);
      })
      .catch((error) => {
          console.log(error);
      });    
    
  }
  
  return (
    <a
      className="btn btn-primary btn-rounded mb-sm-0 "
      onClick={() => onPDF(receiptTaxId)}
    >
      {isLoading ? (
        <i className="fa fa-spinner fa-spin mr-3"></i>
      ) : (
        <i className="las la-download scale5 mr-3"></i>
      )}
      Download
    </a>
  );

};

export default DownloadBtn;
