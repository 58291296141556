import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import APIRequest from "common/interface/APIRequest";
import APISearchRequest from "common/interface/APISearchRequest";
//import TransactionAction from "common/models/TransactionAction";
import TransactionAction  from "models/TransactionActionModel";


export interface OptionTypeInterface {
  filters: any[],
  columnOrder: number,
  groupSort: string,
  id: number,
  orderDirection: string,
  page: number,
  pageSize: number,
  totalCount: number,
  orderBy?:any,
  search?:string
}

class VoidTransactionRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/transaction/creditcard/void";
  param:TransactionAction

 

  constructor(param : TransactionAction) {
    this.param = param   
  }

  makeBody() {

    return {...this.param}
   
  }

  makeQuery() {}
}

export default VoidTransactionRequest;
