import React, { useState, useEffect,  } from "react";

/// React router dom
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import useToken from "Hook/useToken";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";
import "./sabuypay-custom.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
// import Home from "./components/Dashboard/Home";
import MyWallet from "./components/Dashboard/MyWallet";
import Invoices from "./components/Dashboard/Invoices";
import CardsCenter from "./components/Dashboard/CardsCenter";
import Home from "pages/dashboard/index"
import Transactions from "pages/transaction/creditcard/index";
import TransactionsDetails from "pages/transaction/creditcard/detail";
import TransactionsBillpayment from "pages/transaction/billpayment/index";
import TransactionsBillpaymentDetail from "pages/transaction/billpayment/detail";
import TransactionsThaiqr from "pages/transaction/thaiqr/index";
import TransactionThaiqrDetail from "pages/transaction/thaiqr/detail";

// User and role
// import SearchUser from "./components/Account/UserRole/SearchUser";
//import SearchUser from "pages/account/user/list";

// account
import MerchantInfo from "pages/account/merchant-info";
import User from "pages/account/user/index";
import CreateUser from "modules/account/user/component/CreateUser";
import Credential from "pages/account/credential/index";

import Quickpay from "pages/quickpay/index";
import QuickpayDetail from "pages/quickpay/detail";
import SendEmail from "pages/quickpay/send-email";

//import CreateQuickpay from "modules/quickpay/component/CreateQuickpay";

import CreateQuickpay from "pages/quickpay/create";
import Register from "pages/register/create";
import Myqr from "pages/myqr";

/// Pages
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ExpiredPassword from "./pages/ExpiredPassword";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import CreateAccount from "./pages/CreateAccount";
import Error404 from "./pages/Error404";
import SessionExpire from "./pages/SessionExpire";

// Report
import TransferHistory from "pages/report/transfer-history";
import SalesSettlement from "pages/report/sales-settlement";

//receipt
import Receipt from "pages/receipt/index";
import ReceiptView from "pages/receipt/view"




/// Charts
import RechartJs from './components/charts/rechart'
import ChartJs from './components/charts/Chartjs'
import Chartist from './components/charts/chartist'
import SparklineChart from './components/charts/Sparkline'
import ApexChart from './components/charts/apexcharts'

const Markup = () => {
  let path = window.location.pathname;
  let pathArr = path.split("/");
  path = pathArr[pathArr.length - 1];
  let pagePath = path.split("-").includes("page");

  const [activeEvent, setActiveEvent] = useState(!path);

  const { setToken, getToken } = useToken();

  const routes = [
    /// Dashboard
    // { url: "", component: Home },
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "my-wallet", component: MyWallet },
    { url: "invoices", component: Invoices },
    { url: "cards-center", component: CardsCenter },
    { url: "transaction/creditcard", component: Transactions },
    { url: "transaction/creditcard/:id", component: TransactionsDetails },
    { url: "transaction/billpayment", component: TransactionsBillpayment },    
    { url: "transaction/billpayment/:id", component: TransactionsBillpaymentDetail },  
    { url: "transaction/thaiqr", component: TransactionsThaiqr },
    { url: "transaction/thaiqr/:id", component: TransactionThaiqrDetail },
    //{ url: "transaction/:type", component: Transactions },
    // { url: "transaction/:page", component: Transactions },
    // { url: "transactions-details", component: TransactionsDetails },
    
    /// Chart
    { url: 'chart-sparkline', component: SparklineChart },
    { url: 'chart-chartjs', component: ChartJs },
    { url: 'chart-chartist', component: Chartist },
    { url: 'chart-apexchart', component: ApexChart },
    { url: 'chart-rechart', component: RechartJs },
    
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },

    { url: "page-reset-password", component: ResetPassword },
    { url: "page-create-account", component: CreateAccount },
    

    { url: "page-session-expire", component: SessionExpire },
    { url: "page-error-verify", component: Error404 },
    { url: "change-password", component: ChangePassword },

    //  Account
    { url: "account/", component: User },
    { url: "account/merchant-info", component: MerchantInfo },
    { url: "account/merchant-info/:page", component: MerchantInfo },
    { url: "account/user", component: User },
    { url: "account/user/create", component: CreateUser },
    { url: "account/user/create/:id", component: CreateUser },
    { url: "account/credential", component: Credential },

    // page-create-account

    //Quickpay
    { url: "quickpay", component: Quickpay },
    { url: "quickpay/detail/:id", component: QuickpayDetail },
    { url: "quickpay/create", component: CreateQuickpay },
    { url: "quickpay/send-email/:id", component: SendEmail },
    // { url: 'form-wizard', component: Wizard },
     // 
    { url: "page-register", component: Register },

    //myqr
    { url: "myqr", component: Myqr },
    
    //receipt
    { url: "receipt", component: Receipt },   
    { url: "receipt/view/:receipt_tax_id", component: ReceiptView },
 

  //report
    { url: "report/transfer-history", component: TransferHistory },
    { url: "report/sales-settlement", component: SalesSettlement },
    

    { url: "*", component: Error404 },
  ];


 


  return (
    <Router basename="/">
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      > 
    
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            // activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}

        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>             

              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>

        {!pagePath && <Footer />}

      </div>
    </Router>
  );
};

export default Markup;
