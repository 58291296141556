import { Dropdown } from 'react-bootstrap'
function DropdownAction(prop){

	const {onView, onEdit , id, sendEmail} = prop

	return(
		<>
			<Dropdown className='dropdown mb-auto'>
				<Dropdown.Toggle className='i-false p-0' data-toggle='dropdown' variant=''>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10 11.9999C10 13.1045 10.8954 13.9999 12 13.9999C13.1046 13.9999 14 13.1045 14 11.9999C14 10.8954 13.1046 9.99994 12 9.99994C10.8954 9.99994 10 10.8954 10 11.9999Z" fill="black"></path>
						<path d="M10 4.00006C10 5.10463 10.8954 6.00006 12 6.00006C13.1046 6.00006 14 5.10463 14 4.00006C14 2.89549 13.1046 2.00006 12 2.00006C10.8954 2.00006 10 2.89549 10 4.00006Z" fill="black"></path>
						<path d="M10 20C10 21.1046 10.8954 22 12 22C13.1046 22 14 21.1046 14 20C14 18.8954 13.1046 18 12 18C10.8954 18 10 18.8954 10 20Z" fill="black"></path>
					</svg>
				</Dropdown.Toggle>
				<Dropdown.Menu className='dropdown-menu dropdown-menu-right' alignRight={true}>
					{/* <Dropdown.Item className=''>View</Dropdown.Item> */}
					<Dropdown.Item className=''onClick={() => onView(id) }>view</Dropdown.Item>
					<Dropdown.Item className=''onClick={() => sendEmail(id) }>send email</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	)
} 
export {DropdownAction};