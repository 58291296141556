import React, { useRef, useEffect, useState } from 'react';
import APIManager from "common/Manager/APIManager";
import GetMerchantInfoByIdRequest from "modules/account/merchant-info/APIRequest/GetMerchantInfoByIdRequest";
import ViewRequest from "modules/account/credential/APIRequest/ViewRequest";
import MerchantModel from "modules/account/merchant-info/models/MerchantModel";
import Credentials from 'modules/account/credential/component/Credentials';
import CredentialsMultiple from 'modules/account/credential/component/CredentialsMultiple';
import InitializeManager from 'common/Manager/InitializeManager';

const index = () => {

    let contentTranslate: any = {} 
    let ENV_CLIENT: any = {}

    const isClient = typeof window === 'object';
  
    if (!isClient) {
      
      contentTranslate = JSON.parse(InitializeManager.default.get()).data.contentTranslation 
      ENV_CLIENT = JSON.parse(InitializeManager.default.get()).data?.ENV_CLIENT
 
    } else {
  
      contentTranslate = InitializeManager.default.get().data?.contentTranslation;   
      ENV_CLIENT = InitializeManager.default.get().data.ENV_CLIENT
     
    }

  const [merchantData, setMerchantData] = useState<MerchantModel>();
  const [credentials, setCredentials] = useState<any>([]);

  const getMerchantInfo = async () => {

    const apiRequest = new GetMerchantInfoByIdRequest();
    const items = await APIManager.default.fetch(apiRequest);
    const merchantData  = items.data.data.merchantData
    setMerchantData(merchantData);

  };

  const viewCredential = async () => {
    const apiRequest = new ViewRequest();
    const items = await APIManager.default.fetch(apiRequest);
    setCredentials(items.data.data);
  };

 
  useEffect(() => {

    getMerchantInfo()
    viewCredential()
      
  }, []);


  return (
    <>
      <div className="row page-titles mx-0">
        <div className="col-sm-12 p-0">
          <div className="welcome-text">
            <h2 className="text-black font-w600 mb-0">Credential</h2>
          </div>
        </div>
      </div>

      <div className="row">

        <div className="col-xl-12">

          {(merchantData?.multiple_product == 'n') && (
              <Credentials
                 credentials={credentials}                                 
             >
             </Credentials>
          )}

            {merchantData?.multiple_product == 'y' && (
              <CredentialsMultiple
                credentials={credentials}   
             >
             </CredentialsMultiple>
          )}
           
        </div>
   
      </div>

    </>
  );


}

export default index;