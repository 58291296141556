import { useTable, usePagination, useSortBy } from "react-table";
import React,{Fragment, useEffect, useState, useRef} from 'react';

import Sort from 'common/interface/Sort';
import {formatDateYearEN} from 'common/Manager/Helper';
import {DropdownAction} from './DropdownAction';
import { Dropdown } from 'react-bootstrap'
import { Route, Redirect } from 'react-router'
import { useHistory } from 'react-router-dom'
import {TransactionStatusEnum} from "common/enumerations/TransactionStatusEnum";
import TransactionStatus from 'common/enumerations/TransactionColorBadge';
import NumberFormat from 'utilities/NumberFormat'

const Datatable = ({ setPerPage, setPage, columns, data, currentpage, perPage, totalPage, getTransaction, countRow, onDownload, setSort }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize , sortBy },
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state, currentpage]
        );
      },
      initialState: { pageIndex: 0 , pageSize : perPage},
      manualPagination: true,
      manualSortBy: true,
      autoResetSortBy: false,
      autoResetPage: false,
      pageCount: totalPage  //you can handle this calculation in your fetchdata method

    },
    useSortBy,
    usePagination
  );

  // ======================================================================================
  const [dataRow, setDataRow] = useState(
		document.querySelectorAll('#transactions-data tbody tr')
	)

	const activePag = useRef(0)
 
	  // Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < dataRow.length; ++i) {
			if (i >= frist && i < sec) {
				dataRow[i].classList.remove('d-none')
			} else {
				dataRow[i].classList.add('d-none')
			}
		}
	}

  // Active pagginarion
  activePag.current === 0 && chageData(0, perPage)

	// paggination 
  let paggination = Array(Math.ceil(countRow / pageSize))
    .fill()
    .map((_, i) => i + 1)

	const onClick = (i) => {
   
    setPage(i)
		activePag.current = i
    getTransaction(i, pageSize)
    gotoPage(i);

	
	}

  useEffect(() => {

    if(sortBy?.length > 0 ){

      setPageSize(pageSize)
      const field = sortBy[0].id;
      const desc = (sortBy[0].desc) ?  'DESC' : 'ASC' ;

      const sort = {
        field : field,
        sortby: desc
      }

      setSort(sort)     
      getTransaction(currentpage, pageSize, sort)

    }
    
  }, [sortBy]);

  return (
    <>

      <table 	className='table display mb-4 dataTablesCard  dataTable no-footer' {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                    {column.isSorted
                        ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                        : ''}
                    </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          { page.length > 0 && page.map((row, i) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {                 
                
                  if(cell.column.id == 'created_at' ){

                    return (<td {...cell.getCellProps()}>{formatDateYearEN(new Date(cell.value))}</td>)

                  }

                  if(cell.column.id == 'status'){   

                    return (<td {...cell.getCellProps()}>
                      
                      <a class="btn btn-primary btn-rounded mb-sm-0 mb-2" href="/react/demo/transactions-details"><i class="las la-download scale5 mr-3"></i>Download</a>
                    
                    </td>)     
                  }

                  if(cell.column.id == 'transaction_date'){ 

                    const transaction_date = cell.row.original.created_at

                    return (<td width="200px">

                        <a class="btn btn-primary btn-rounded mb-sm-0 mb-2"   onClick={ (e) => {

                            e.preventDefault();
                            onDownload(transaction_date)

                          }

                        }   

                        >
  
                      <i class="las la-download scale5 mr-3"></i>Download</a>                      
                 
                    </td>)    

                   } 
                   
                   if(cell.column.id == 'amount' || cell.column.id == 'net_amount' || cell.column.id == 'transaction_fee' || cell.column.id == 'withholding_tax' || cell.column.id == 'vat' ){ 

                    return (<td {...cell.getCellProps()}>{NumberFormat.default.numberWithCommas(cell.value) }</td>)    

                  } 
              
                  return (                              
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                  
                })}
              </tr>
            );
          })}

         
          {(page.length == 0 &&  
            <tr>
              <td className="no-data" colspan="20" >No Data</td>
            </tr> 
          )}

        </tbody>
      </table>

      <div className='d-sm-flex text-center justify-content-between align-items-center'>
        <div className='dataTables_info' id='example5_info'>
            Showing { page.length == 0 ? 0 : activePag.current * pageSize + 1} to{' '}
            {countRow > (activePag.current + 1) * pageSize
            ? (activePag.current + 1) * pageSize
            : countRow}{' '}
            of {countRow} entries
        </div>


        <div className='dataTables_paginate paging_simple_numbers' id='example5_paginate'>

          <select
            value={pageSize}
            onChange={(e) => {

              setPageSize(Number(e.target.value));
              getTransaction(0, Number(e.target.value))
              setPage(0)
              setPerPage(Number(e.target.value))
              activePag.current = 0            

            }}

            className="ml-2"
          >
            {[5, 7, 15, 50, 100, 250, 500].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>

          <a className='paginate_button previous pointer' onClick={() => activePag.current > 0 && onClick(activePag.current - 1)}>
            Previous
          </a>
          <span>
            {paggination.map((number, i) => (
              <a key={i}  className={`paginate_button pointer ${ activePag.current === i ? 'current' : '' } `} 
              onClick={() =>{ 
                onClick(i)               
              }
              
              }>
                {number}
              </a>
            ))}
          </span>
          <a className='paginate_button next pointer' onClick={() => activePag.current + 1 < paggination.length && onClick(activePag.current + 1)}>
            Next
          </a>
        </div>
      </div>	

    </>

  );
};

export default Datatable;
