import React, { useState } from "react";
import { Link } from "react-router-dom";
import { serverConfig } from 'common/Config/ServerConfig'
import APIManager from 'common/Manager/APIManager'
import swal from "sweetalert";
import ForgetRequest  from 'modules/password/forget/APIRequest/ForgetRequest'
import RegularExpression from "utilities/RegularExpression";
import InitializeManager from "common/Manager/InitializeManager";

const isClient = typeof window === "object";

const ForgotPassword = () => {

  const [email, setEmail] = useState('');  
  const onSubmit = async (e) => { 

    e.preventDefault();
    
    if(email == ''){
      swal('', ' Please fill you email' , "error");
      return false
    }

    const validation = RegularExpression.default;
    let validEmail = validation.emailValidate(email);  
    if (!validEmail) {

      swal('', ' Email format not valid' , "error");
      return false
    }
  
    const apiRequest = new ForgetRequest({
      email : email
    });

    const items = await APIManager.default.fetch(apiRequest);
      
    if (items.data.status == 200) {
      
      swal("Send email success", {
        icon: "success",
      })
      
    }  

  };

  let ENV_CLIENT: any = {};

  if (!isClient) {
    ENV_CLIENT = JSON.parse(InitializeManager.default.get()).data?.ENV_CLIENT;
  } else {
    ENV_CLIENT = InitializeManager.default.get().data.ENV_CLIENT;
  }
  
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={`${ENV_CLIENT['MERCHANT_BACKOFFICE_ASSET']}/images/sabuypay_logo_login.svg`}  width="60%" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 text-white">
                      Forgot Password
                    </h4>
                    <form onSubmit={(e) => onSubmit(e)}>

                      <div className="form-group">
                        <label className="text-white">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue=""
                          onChange ={(e)=> setEmail(e.target.value) }

                        />
                      </div>
                      <div className="text-center">
                        <input
                          type="submit"
                          value="SUBMIT"
                          className="btn bg-white text-primary btn-block"
                        />
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
