import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import APIRequest from "common/interface/APIRequest";
import APISearchRequest from "common/interface/APISearchRequest";
import FilterModel from "common/models/FilterModel";


class CountTransactionRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/transaction/thaiqr/count";
  param:FilterModel
 

  constructor(param: FilterModel) {
    this.param = param   
  }

  makeBody() {

    return {
        ...this.param
    }
  }

  makeQuery() {}
}

export default CountTransactionRequest;
