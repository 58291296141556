import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import APIRequest from "common/interface/APIRequest";
import APISearchRequest from "common/interface/APISearchRequest";
import FilterModel from "common/models/FilterModel";


export interface OptionTypeInterface {
  filters: any[],
  columnOrder: number,
  groupSort: string,
  id: number,
  orderDirection: string,
  page: number,
  pageSize: number,
  totalCount: number,
  orderBy?:any,
  search?:string
}

class CountTransactionRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/transaction/billpayment/count";
  param:FilterModel
 

  constructor(param: FilterModel) {
    this.param = param   
  }

  makeBody() {

    return {
        ...this.param
    }
  }

  makeQuery() {}
}

export default CountTransactionRequest;
