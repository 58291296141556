import { Box } from '@material-ui/core'
import ImageManager from 'common/Manager/ImageManager'
import React from 'react'
import { BtnLogin, BtnRegister } from '../Button'
import Navbar from './Styles'
const SideNav = (props: any) => {

    const { translate, ENV_CLIENT } = props
    return (

        <Navbar bars={props.bars}>
            <ul><li><a href={`${ENV_CLIENT['SABUYPAY_FRONT_ENDPOINT']}/register`}><img src={ImageManager.default.images.sabuypay.register.app_registration_black_18dp} alt="" />{ translate['register.regis.header.btn']}</a></li></ul>
            <ul><li><a href={ENV_CLIENT['BACKOFFICE_FRONT_ENDPOINT']}><img src={ImageManager.default.images.sabuypay.register.person_black_18dp} alt="" /> {translate['register.login.header.btn']}</a></li></ul>          
            <ul><li><a href={ENV_CLIENT['BACKOFFICE_FRONT_ENDPOINT']}><img src={ImageManager.default.images.sabuypay.register.call_black_18dp} alt="" /> Contact</a></li></ul>
        </Navbar>
    )

    

}

export default SideNav