import React,{Fragment, useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, Modal} from 'react-bootstrap';
import {DropdownBlog} from 'jsx/components/Dashboard/Invoices';
import { Redirect, useParams } from "react-router-dom";

import SearchQuickpayRequest from "modules/quickpay/APIRequest/SearchQuickpayRequest";
import CountQuickpayRequest from "modules/quickpay/APIRequest/CountQuickpayRequest";

import APIManager from "common/Manager/APIManager";
import FilterModel from "modules/quickpay/models/FilterModel";

import Quickpay from "modules/quickpay/component/Quickpay"

import {endDateISO, formatDateForApi, startDateISO} from 'common/Manager/Helper'



const index = (props: any) => {	


	const [orderRef, setOrderRef] = useState("")
	const [customerName, setCustomerName] = useState("")
	const [mobile, setMobile] = useState('')
	const [email, setEmail] = useState('')
	const [createDate, setCreateDate] = useState('')

	const [pageCount, setPageCount] = useState(0)
	const [page, setPage] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [totalPage, setTotalPage] = useState(0)

	const [status, setStatus] = useState('')
	

	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')

	const [sort, setSort] =  useState({	
			field : 'created_at',
			sortby: 'DESC'  
	})
	
	const [transaction, setTransaction] = useState([])
	const getTransaction = async (current_page = page, per_page = perPage, sort_data = sort ) => {

		const filterModel = new	FilterModel(
		{		
			order_ref : orderRef,
			customer_name : customerName,
			mobile : mobile,
			email : email,
			status: status,
			created_at: createDate,
			start_date: startDate,
			end_date: endDate,
			
			page: current_page,
			pagesize: per_page,	
			sort: sort_data,		
			orderdirection: "DESC"		
		})

		const apiRequest = new SearchQuickpayRequest(filterModel);
		const countApiRequest = new CountQuickpayRequest(filterModel);
	
		const items = await APIManager.default.fetch(apiRequest);				
		const count = await APIManager.default.fetch(countApiRequest);
		
		setTransaction(items.data.data);
		setPageCount(count.data.data)
		setTotalPage(Math.ceil(count.data.data / perPage))	

	};
		
	useEffect( () => {
		getTransaction(page, perPage);	
		
	}, [])

	const handleDateChange = (st, end) =>{


		if(st){
			setStartDate(startDateISO(st))
		}

		if(end){
			setEndDate(endDateISO(end))
		}

	}
	const onView = (id:any) =>{
		window.location.href = `/quickpay/detail/${id}`;
	}

	// const onEdit = (id:any) =>{
	// 	window.location.href = `/quickpay/create/${id}`;
	// }

	const sendEmail = (id:any) =>{
		window.location.href = `/quickpay/send-email/${id}`;
	}

  	return (
		<>
				<Quickpay
				
					setOrderRef={setOrderRef}
					setCustomerName={setCustomerName}		
					setMobile={setMobile}
					setEmail={setEmail}
					setCreateDate={setCreateDate}

					handleDateChange={handleDateChange} 
					getTransaction={getTransaction}
			
					data={transaction}
					key={1}		

					setSort={setSort}

					setPage={setPage}
					setPerPage={setPerPage}
					setTotalPage={setTotalPage}
					setStatus={setStatus}	

					page={page}
					perPage={perPage}
					countRow={pageCount}
					totalPage={totalPage}	
					onView={onView}	
					sendEmail={sendEmail}		
				>

				</Quickpay>

    	</>
  	)


}

export default index;