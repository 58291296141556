import React, { Component } from "react";
import { Line } from "react-chartjs-2";

type MyProps = {
	data_list: any[];
	chart_name:string

  }

class WeeklyChart extends Component<MyProps> {
   render() {
	   /* const widgetChart3gradientStroke = widgetChart3.createLinearGradient(0, 1, 0, 500);
			widgetChart3gradientStroke.addColorStop(0, "rgba(30, 170, 231, 0.7)");
			widgetChart3gradientStroke.addColorStop(1, "rgba(30, 170, 231, 0)"); */

		const data_list_label = this.props?.data_list?.map(x=>x.label);
		const data_list_data = this.props?.data_list?.map(x=>   x.value);
		const chart_name = this.props?.chart_name;
			
      const data = {
         defaultFontFamily: "Poppins",
			labels: data_list_label,

			datasets: [{
				label: chart_name,
				borderColor: 'transparent',
				pointBorderColor: '#1EAAE7',
				borderWidth:4,
				borderRadius:10,
				pointHoverBackgroundColor: '#1EAAE7',
				pointHoverBorderColor: '#1EAAE7',
				backgroundColor: "rgba(30, 170, 231, 0.7)",
				pointBackgroundColor: "rgba(30, 170, 231, 0)",
				data: data_list_data
			}]
      };
      const options = {
			title: {
				display: !1
			},
			tooltips: {
				intersect: !1,
				mode: "nearest",
				xPadding: 10,
				yPadding: 10,
				caretPadding: 10
			},
			legend: {
				display: !1
			},
			responsive: !0,
			maintainAspectRatio: !1,
			hover: {
				mode: "index"
			},
			scales: {
				xAxes: [{
					display: !1,
					gridLines: !1,
					scaleLabel: {
						display: !0,
						labelString: "Month"
					}
				}],
				yAxes: [{
					display: !1,
					gridLines: !1,
					scaleLabel: {
						display: !0,
						labelString: "Value"
					},
					ticks: {
						beginAtZero: !0
					}
				}]
			},
			elements: {
				point: {
					radius: 0,
					borderWidth: 0
				}
			},
			layout: {
				padding: {
					left: 0,
					right: 0,
					top: 5,
					bottom: 0
				}
			}
      };

      return (
         <>
            <Line data={data} height={80} options={options} />
         </>
      );
   }
}

export default WeeklyChart;
