import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import _ from 'lodash'
import InitializeManager from 'common/Manager/InitializeManager';



const data = _.get(window, '__STATE');
InitializeManager.default.rehydrate(data);
/*     */
document.getElementById('__STATE')?.remove()


hydrate(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
