import _ from "lodash";

import LinkModel from "./LinkModel"

class FooterMenuModel {

    label: string;
    sort: number;
    active: string;
    locale: string;
    links: LinkModel[];
    
  constructor(json: any) { 
    this.label = _.get(json, "label"); 
    this.sort = _.get(json, "sort");
    this.active = _.get(json, "active");
    this.locale = _.get(json, "locale");
    this.links = (_.get(json, "links") || [] ).map( (x:any) => new LinkModel(x))  ;
   
   // (_.get(json, 'payment_fee') || []).map((v: any) => new PaymentFeeModel(v))

  }

  // validate(): ValidationModel {
  //   const validationModel = new ValidationModel()
  //   validationModel.isValid = true

  //   if (this.channelId == undefined) {
  //       validationModel.isValid = false
  //       validationModel.message = "Please specify `channel Id`"
  //   }

  //   if (this.paymentProviderId == undefined) {
  //     validationModel.isValid = false
  //     validationModel.message = "Please specify `paymentProvider Id`"
  //   }

  //   if (this.langCode == undefined) {
  //     validationModel.isValid = false
  //     validationModel.message = "Please specify `paymentProvider Id`"
  //   }

  //   return validationModel
  // }



}

export default FooterMenuModel;
