
import LocalizationStore from "stores/LocalizationStore";



class RootStore {
  static default: RootStore;

  static isServer: boolean = typeof window == "undefined";

  static create() {
    RootStore.default = new RootStore();
      
  }



  LocalizationStore: LocalizationStore;


  private constructor() {


    this.LocalizationStore = new LocalizationStore();
  }

  // setThemeStore(themeStore: ThemeStore) {
  //   this.ThemeStore = themeStore;
  // }

  async init() {
    this.LocalizationStore.init();

    // await i18n.hotellang();
    // i18n.init();
    // this.UIStore.isPageLoading = true;
    // await Promise.all([
    //   ConfigurationManager.default.fetch(),
    // ])
    // this.UIStore.isPageLoading = false;
    
  }
}

export default RootStore;
