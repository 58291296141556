import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
// import logo from "../../../images/logo.png";
import logo from "../../../images/SabuyPay-Horizontal.svg";
import logoMobile from "../../../images/SabuyPay-only-logo.svg";
import logoText from "../../../images/logo-text.png";

const NavHader = () => {
   const [toggle, setToggle] = useState(false);
   return (
      <div className="nav-header">
         <Link to="/" className="brand-logo">
            <img className="logo-abbr d-none d-xl-block" src={logo} alt="" />
            <img className="logo-abbr-mobile d-xl-none" src={logoMobile} alt="" />
            <img className="logo-compact" src={logoMobile} alt="" />
            {/* <img className="brand-title" src={logoMobile} alt="" /> */}
         </Link>

         <div className="nav-control" onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
         
      </div>
   );
};

export default NavHader;
