import React, { Fragment } from "react";
import swal from "sweetalert";
import { useEffect, useState } from "react";
import QuickpayRequest from "modules/quickpay/APIRequest/QuickpayRequest";
import GetQuickpayByIdRequest from "modules/quickpay/APIRequest/GetQuickpayByIdRequest";
import CheckOrderRefRequest from "modules/quickpay/APIRequest/CheckOrderRefRequest";
import GetLocalesAPIRequest from "common/APIRequests/GetLocalesAPIRequest";
import CurrencyRequestAPIRequest from "common/APIRequests/CurrencyRequest";
import APIManager from "common/Manager/APIManager";
import { padWithLeadingZeros} from "common/Manager/Helper";
import { useParams } from "react-router-dom";
import QuickPayModel from "modules/quickpay/models/QuickPayModel";
import FormCreateQuickpay from "modules/quickpay/component/FormCreateQuickpay";
import RegularExpression from "utilities/RegularExpression";
import _ from "lodash";
import MerchantModel from "modules/account/merchant-info/models/MerchantModel";
import dayjs from "dayjs";
import InitializeManager from "common/Manager/InitializeManager";
import ProductListModel from "modules/quickpay/models/ProductListModel";

let cselected = [];

const isClient = typeof window === "object";

const CreateQuickpay = () => {
  type QuizParams = {
    id: string;
  };

  let { id } = useParams<QuizParams>();

  let ids: number;
  if (typeof id != "undefined") {
    ids = parseInt(id);
  }

  let actionUser = typeof id != "undefined" ? "Edit" : "Create";

  const validation = RegularExpression.default;

  const [channel, setChannel] = useState([]);
  const [locales, setLocales] = useState([]);
  const [currencies, setCurrency] = useState([]);
  const [products, setProducts] = useState<ProductListModel[]>();

  const [nowDate, setNowDate] = useState(new Date());

  const d = new Date();
  const [minutes, setMinutes] = useState(
    padWithLeadingZeros(d.getMinutes(), 2)
  );
  const [hour, setHour] = useState(padWithLeadingZeros(d.getHours(), 2));

  let productList: any[];
  let channelList: any[];

  const [form, setForm] = useState<QuickPayModel>({
    id: ids,
    order_ref: "",
    customer_name: "",
    mobile: "",
    email: "",
    detail: "",
    amount: null,
    expiry: null,
    expired_at: null,
    lang_code: "",
    currency: "THB",
    channel: [],
    business_type: "",
    status: "0",
    is_sendemail: "n",
    product_id: null,
    product_name: "",
  });

  const [formEror, setFormEror] = useState([]);
  const [merchantData, setMerchantData] = useState<MerchantModel>();

  if (!isClient) {

    productList = JSON.parse(InitializeManager.default.get()).data?.productList;
    channelList = JSON.parse(InitializeManager.default.get()).data?.channelList;

  } else {
   
    productList = InitializeManager.default.get().data?.productList;
    channelList = InitializeManager.default.get().data?.channelList;

  }

  const handleInputChange = (e) => {
    const { target } = e;
    var obj = {};

    obj[target.name] = target.value;

    if (target.name == "email") {
      let emailKeyUpValidate = validation.emailKeyUpValidate(target.value);
      if (!emailKeyUpValidate) {
        return 0;
      }
    }

    if (target.name == "minutes") {
      setMinutes(target.value);
    }

    if (target.name == "hour") {
      setHour(target.value);
    }

    setForm({ ...form, ...{ [target.name]: target.value } });
  };

  const hasError = (key) => {
    return formEror.indexOf(key) !== -1;
  };

  const handleCheckbox = (e) => {
    const { target } = e;
    var obj = {};
    const cid = target.value;
    let find = cselected.findIndex((v) => v == cid);

    if (find > -1) {
      cselected.splice(find, 1);
    } else {
      cselected.push(cid);
    }

    obj[target.name] = cselected;
    setForm({ ...form, ...{ [target.name]: cselected } });
  };

  const channelSelected = (cid) => {
    let find = cselected?.findIndex((v) => v == cid);
    if (find > -1) {
      return true;
    }
    return false;
  };

  const createQuickpay = async (e) => {
    e.preventDefault;

    let errors = [];
    const expression = /\S+@\S+/;
    form.expired_at = new Date(nowDate.setHours(Number(hour), Number(minutes)));
    const date1 = dayjs(form.expired_at);
    const date2 = dayjs();

    let diffMinutes = date1.diff(date2, "minutes");
    form.expiry = diffMinutes;
    let validEmail = validation.emailValidate(form.email, false);
    let validMobile = validation.phoneNumberValidate(form.mobile, false);
    let validOrderRef = validation.numbericValidate(form.order_ref, false);
    let validAmount = validation.amountValidate(String(form.amount), false);

    if (!validEmail || form.email.length > 50) {
      errors.push("email");
    }

    if (!validOrderRef) {
      errors.push("order_ref");
    }

    if (!(form.order_ref?.length == 12)) {
      errors.push("order_ref");
    }

    if (form.customer_name == "") {
      errors.push("customer_name");
    }

    if (!validMobile || form.mobile.length > 20) {
      errors.push("mobile");
    }

    if (!validAmount || form.amount < 1) {
      errors.push("amount");
    }

    if (form.lang_code === "") {
      errors.push("lang_code");
    }

    if (form.channel?.length == 0) {
      errors.push("channel");
    }

    if (form.currency === "") {
      errors.push("currency");
    }

    if (form.product_id === null) {
      errors.push("product_id");
    }

    if (form.product_name === "") {
      errors.push("product_name");
    }

    setFormEror(errors);

    if (errors?.length > 0) {
      return false;
    }

    if (diffMinutes < 15) {
      swal("", "Please choose expired time more than 15 minute!", "error");
      return false;
    }

    if (diffMinutes >= 44640) {
      swal("", "Please choose expired time less than 1 month", "error");
      return false;
    }
   
    if(cselected.indexOf("7") !==  -1){

      if(Number(form.amount) > 49000) {

        swal("", "Amount on counter service and 7-eleven must not be more than 49,000 ", "error");
        return false;
      }
      
    }
     
    /// call api check
    const checkOrderRefRequest = new CheckOrderRefRequest(
      form.product_id,
      form.order_ref
    );
    const checkOrderRef = await APIManager.default.fetch(checkOrderRefRequest);

    const { isCreate } = checkOrderRef.data.data;
    if (!isCreate) {
      swal(
        "",
        `Order Ref ${form.order_ref} due to waiting for payment. \n Please fill another Order Ref. `,
        "error"
      );
      return false;
    }

    const quickPayModel = new QuickPayModel(form);
    const apiRequest = new QuickpayRequest(quickPayModel);
    const items = await APIManager.default.fetch(apiRequest);

    const status = _.get(items.data, "status");
    const id = _.get(items.data.data.data, "quick_pay.id");

    if (status == 200) {
      swal("Success!", {
        icon: "success",
      }).then(() => {
        window.location.href = `/quickpay/detail/${id}`;
      });
    }

  };

  const getChannel = async () => {
  
    setChannel(channelList);

  };

  const getLocales = async () => {
    const apiRequest = new GetLocalesAPIRequest();
    const locales = await APIManager.default.fetch(apiRequest);
    setLocales(locales.data.data);
  };

  const getCurrency = async () => {
    const apiRequest = new CurrencyRequestAPIRequest();
    const currency = await APIManager.default.fetch(apiRequest);
    setCurrency(currency.data.data);
  };

  const getById = async () => {
    const apiRequest = new GetQuickpayByIdRequest(id);
    const editData = await APIManager.default.fetch(apiRequest);

    setForm(editData.data.data);
    cselected = editData.data.data.channel?.split(",");
  };

  const handleDateChange = async (date: any) => {
    setNowDate(date.toDate());
  };

  useEffect(() => {
    cselected = [];
    getChannel();
    getLocales();
    getCurrency();

    if (productList?.length == 1) {
      setForm({ ...form, ...{ ["product_id"]: productList[0].id } });
    }

    setProducts(productList);

    if (id) {
      getById();
    }
  }, []);

  return (
    <Fragment>
      <FormCreateQuickpay
        locales={locales}
        channel={channel}
        products={productList}
        currencies={currencies}
        form={form}
        actionUser={actionUser}
        handleInputChange={handleInputChange}
        hasError={hasError}
        handleCheckbox={handleCheckbox}
        createQuickpay={createQuickpay}
        channelSelected={channelSelected}
        handleDateChange={handleDateChange}
        minutes={minutes}
        hour={hour}
        nowDate={nowDate}
      />
    </Fragment>
  );
};

export default CreateQuickpay;
