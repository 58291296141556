import React from 'react';
import {Link} from 'react-router-dom';


import {TransactionStatusEnum} from "common/enumerations/TransactionStatusEnum";
import {SettlementEnum} from "common/enumerations/SettlementEnum";
import {formatDateYearEN} from 'common/Manager/Helper';

import TransactionColorBadge from 'common/enumerations/TransactionColorBadge';
import Datetime from 'utilities/Datetime'
import NumberFormat from 'utilities/NumberFormat'
 
// const StatisticCanvas = loadable(() =>
// 	pMinDelay(import("../Mophy/Transactions/StatisticCanvas"), 1000)
// ); 
 
const TransactionsDetails = (prop)=>{

  const {transaction, merchant} = prop.data
  const {voidTransaction, isShowVoid} = prop

  const brandIndentifier =  (transaction?.credit_card_payment_type == 'full') ? 'SABUYPAY' : 'SABUYPAY_INSTALLMENT'

 let settled_date;

  if(transaction?.settled_date !=undefined && transaction?.settled_date !='0000-00-00 00:00:00') 
  {
      settled_date = formatDateYearEN(new Date(transaction?.settled_date));
  }

	return(
		<>
      <div className="row">

      <div class= "page-titles form-head d-flex flex-wrap justify-content-between align-items-center mb-4">
				<h2 className="text-black font-w600 mb-0 mr-auto mb-2 pr-3">Credit Card Transactions Details</h2>
			</div>

      </div>

      <div className="row">

        <div className="col-xl-12">         

          <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h2 className="card-title">Payment Information</h2>
                  </div>
                  <div className="card-body pb-0">
                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          System Ref<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{transaction?.ref1}</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Status<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <TransactionColorBadge transactionStatus={transaction?.status}></TransactionColorBadge>
                      </div>
                      
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Merchant ID<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{merchant?.merchant_id}</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Merchant Name<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{merchant?.merchant_name}</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Order ID<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{transaction?.ref2}</span>
                      </div>
                    </div>

                    { (merchant?.multiple_product == 'y') && (

                        <div class="row mb-3">
                          <div class="col-6">
                            <h5 class="f-w-500">
                              {" "}
                              Product Type<span class="pull-right">:</span>
                            </h5>
                          </div>
                          <div class="col-6">
                            <span> { (transaction?.product?.name) ? transaction?.product?.name : '-' }</span>
                          </div>
                      </div>

                    )}  
                      

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Product Name<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span> { (transaction?.product_name) ? transaction?.product_name : '-' }</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Payment Channel<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{(transaction?.channel?.code == 'credit') ? 'CREDIT CARD' : (transaction?.channel?.code == 'installment') ?  'INSTALLMENT' : '' }</span>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Type<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{ (transaction?.credit_card_payment_type) ? transaction?.credit_card_payment_type : "-" }</span>
                      </div>
                    </div>
                    
                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
						            Amount<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{ NumberFormat.default.numberWithCommas(transaction?.amount)}</span>
                      </div>
                    </div>
                    
                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Transaction Fee<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{NumberFormat.default.numberWithCommas(transaction?.transaction_fee)}</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
						            VAT<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{ NumberFormat.default.numberWithCommas(transaction?.vat)}</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
						            Net Amount<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{ NumberFormat.default.numberWithCommas(transaction?.net_amount)}</span>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          MDR<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{  (transaction?.transaction_installment?.configInstallment?.mdr_interest_rate) ? transaction?.transaction_installment?.configInstallment?.mdr_interest_rate
                        : '-'
                        
                        }</span>
                      </div>
                    </div>	

                                   <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Transaction Date/Time<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{ (transaction?.transaction_date) ? formatDateYearEN(new Date(transaction?.transaction_date)) : '-' }</span>                        
                        
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Void Date/Time<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                      
                      <span>{ (transaction?.voided_date !=undefined && transaction?.voided_date !='0000-00-00 00:00:00') 
                          ? formatDateYearEN(new Date(transaction?.voided_date)) 
                          : '-'}</span>
                      </div>
                    </div>
                          
                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Settlement Date/Time<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                      <span>{ (settled_date !=undefined) 
                          ? settled_date 
                          : '-'}
                      </span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
						              Settlement Type<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                      <span>{ (transaction?.settled_date !=undefined && transaction?.settled_date !='0000-00-00 00:00:00') 
                          ? 'Auto' 
                          : '-'}
                      </span>
                      </div>
                    </div>	

                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Expired Date<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{(transaction?.expired_at) ? formatDateYearEN(new Date(transaction?.expired_at)) : "-"}</span>
                      </div>
                    </div>
                    
                    <div class="row mb-2">
                      <div class="col-6">
                      </div>
                      <div class="col-6 mb-2"> 
                            {(transaction?.status == TransactionStatusEnum.Accepted) && 
                            (isShowVoid == true) &&
                                (<a
                                      className="btn btn-primary "
                                      onClick={(e) => voidTransaction(brandIndentifier, transaction?.ref1, transaction?.uuid )}
                                    >
                                      Void 
                                    </a>
                            )}   
                          </div>
                    </div>

             
			

                  </div>
                </div>
              </div>
            
            
          </div>

		  <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h2 className="card-title">Card Information</h2>
                  </div>
                  <div className="card-body pb-0">

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Cardholder name<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{ (transaction?.cardholder) ? transaction?.cardholder :  '-' }</span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Card Number<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>
                            { (transaction?.card_first6digits) ? transaction?.card_first6digits+' XXXX XXXX '+transaction?.card_last4digits  : '-' }
                        </span>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Card Type<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span> {transaction?.card_type ? transaction?.card_type : '-'}</span>
                      </div>
                    </div>
                                   
                

                    <div class="row mb-2">
                      <div class="col-6">
                        <h5 class="f-w-500">
                          {" "}
                          Verify By<span class="pull-right">:</span>
                        </h5>
                      </div>
                      <div class="col-6">
                        <span>{ (transaction?.card_type == 'VISA') ? 'Verify By Visa' : (transaction?.card_type == 'MASTER') ? 'MasterCard Secure Code'
                        : (transaction?.card_type == 'JCB') ? 'JCB J/Secure' : (transaction?.card_type == 'UNIONPAY') ? 'UnionPay 3-D Secure(3DS)' : (transaction?.card_type) ? 'American Express SafeKey' : '-'
                          }
                        </span>
                      </div>
                    </div>


                    </div>


                </div>
              </div>
            
            
          </div>

        </div>


      </div>
    </>
	)
}
export default TransactionsDetails;