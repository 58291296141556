import React,{Fragment, useEffect, useState, useRef} from 'react';
import { RangeDatePicker, DatePicker } from "@y0c/react-datepicker";
import Datatable from './Datatable';
import { Link } from 'react-router-dom';


const Receipt = (prop) => {

	const {
		handleDateChange,
		data,
		countRow,
		getTransaction,
		setPage,
		setPerPage,
		setTotalPage,		
		page,
		perPage,
		totalPage,
		onView,
		setSort,
	}  = prop

	const columns = [
		{
		  	Header: 'Date',
		  	accessor: 'created_at'
		},
		{
			Header: 'No.',
			accessor: 'receipt_tax_id'
	  	}
		, {
			Header: 'Transaction Fee',
			accessor: 'amount_before_vat'
		}, {
			Header: 'VAT',
			accessor: 'vat'
		}, 
				{
			Header: 'W/T',
			accessor: 'withholding_tax'
		}, 
				
		{
			Header: 'Total',
			accessor: 'total_amount'
		}, 
		{
			Header: 'View',
			accessor: 'view'
		},		
		{
			Header: 'Download',
			accessor: 'download'
		}	
	]

	return(
		<Fragment>

			<div className= "page-titles form-head d-flex flex-wrap justify-content-between align-items-center mb-4">
				<h2 className="text-black font-w600 mb-0 mr-auto mb-2 pr-3">Receipt/Tax Invoice</h2>
			</div>
												
			<div className="row">
				<div className="col-xl-12">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-md-12 order-md-1 ">
								<h4 className="mb-3">Search</h4>
								<form className="needs-validation">
									<div className="row">
										
										<div className="col-md-4 mb-3">
											<div className="example rangeDatePicker">
											<p className="mb-1">Date</p>
											<RangeDatePicker
												startText="Start"
												endText="End"
												startPlaceholder="Start Date"
												endPlaceholder="End Date"
												onChange={handleDateChange}
											
											/>
											</div>
										</div>
										
									</div>

									<div className="mt-4">
									<a
										className="btn btn-primary mb-1 mr-1"
										onClick={(e) => 											
											getTransaction(0)									
										
										}
									>
										Search 
									</a>
									
									</div>
								</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			
			</div>
			
			<div className="row">
				<div className="col-lg-12">
					<div className="table-responsive table-hover fs-14 card-table dataTables_wrapper" id="transactions-data">	
						<Datatable
						 	data={data}
						 	columns={columns}

							setPage={setPage}
						 	setPerPage={setPerPage}
							totalPage={setTotalPage}

						 	currentpage={page}
						 	perPage={perPage}
							countRow={countRow}

							getTransaction={getTransaction}

							onView={onView}	
							setSort={setSort}	
						/>
					</div>
				</div>
			</div>	
		
		</Fragment>
	)
}



export default Receipt;