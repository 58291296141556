import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, Grid, TextField, MenuItem, Hidden, Menu, Select, Button } from '@material-ui/core';
import { AppImage } from 'common/components';
import ImageManager from 'common/Manager/ImageManager';
import RootStore from 'stores';
//import i18n from "utilities/I18n";
import LanguageCodeEnum from 'common/enumerations/LanguageCodeEnum';
import _ from 'lodash';
import useStyles from './Styles'
import FooterMenuModel from 'modules/register/models/FooterMenuModel'
import LinkModel from 'modules/register/models/LinkModel'

const Footer = (props: any) => {

    const classes = useStyles();
    const {footerMenu, translate} = props
    
  
    return (
        <div className={classes.footer} >  

            {/* <Box className={classes.container}> */}

                 <Grid container >
                     
                     <Grid item md={6} xs={12} > 

                        <div className={classes.top_footer} >
                          
                            <img src={ImageManager.default.images.sabuypay.register.sabuypay_log_horizontal} className={classes.logo_footer} alt=""   />
                            <div className={classes.label_comp} >{translate['register.companyname.footer.placeholder']}</div>
                            <div className={classes.label_comp_detail}>

                                <div >{translate['register.address1.footer']}</div>
                                <div  className={classes.label_address2} >{translate['register.address2.footer']}</div>
                                <div>{translate['register.phone.footer.title']} {translate['register.phone.footer.value']}</div>
                                <div>{translate['register.email.footer.title']} {translate['register.email.footer.value']}</div>
                            </div>


                        </div>

                        <div className={classes.top_footer_m} >

                            <img src={ImageManager.default.images.sabuypay.register.sabuypay_log_horizontal_mobile} className={classes.logo_footer} alt=""   />
                            <div className={classes.label_comp} >{translate['register.companyname.footer.placeholder']}</div>
                           
                            <div className={classes.label_comp_detail_m}>

                                <div >{translate['register.address1.mobile.footer']}</div>
                                <div  className={classes.label_address2} >{translate['register.address2.mobile.footer']}</div>
                                <div>{translate['register.phone.footer.title']} {translate['register.phone.footer.value']}</div>
                                <div>{translate['register.email.footer.title']} {translate['register.email.footer.value']}</div>
                            </div>

                        </div>                                

                         <div className={classes.copyright}>
                            <div>
                                <img src={ImageManager.default.images.sabuypay.register.pcidss} alt=""   />
                            </div>
                            <div className={classes.copyright_text}>{translate['register.copyright.footer']} </div>

                        </div>

                    </Grid>

                    {footerMenu.map( (x:FooterMenuModel) =>

                            <Grid item md={2} sm={12} xs={12} >

                                <Box className={classes.label_title}>{x.label}</Box>
                                <Box className={classes.label} >
                                
                                    {x.links.map( (l:LinkModel) => (
                                            <Box  >
                                                <a  className={classes.a_link}href={l.url}>{l.label}</a>
                                            </Box>  
                                    ))}
                                                                        
                                </Box>

                            </Grid>

                    )}

                    <div className={classes.copyright_m}>                         

                        <div>
                            <img src={ImageManager.default.images.sabuypay.register.pcidss} alt=""  width={"100px"} height={"38px"}  />
                        </div>
                        <div className={classes.copyright_text}>{translate['register.copyright.footer'] } </div>

                    </div>

                 </Grid>
    
{/*                     
            </Box> */}
        </div>

    )
}

export default Footer