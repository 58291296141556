import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import { Accordion, Card } from "react-bootstrap";
import classnames from "classnames";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { accordionBlog, IconRed, PendingBlog, AccordBox } from "./TabData";

import card1 from 'images/card/1.jpg';
import card2 from 'images/card/2.jpg';
import card3 from 'images/card/3.jpg';
import card4 from 'images/card/4.jpg';

// import ChartPie from "./pie";
import PieCustom from "./PieCustom";
import { Row, Col } from "react-bootstrap";
import {TransactionColorStatusText, getTransactionStatusName , ColorPeriodLookup, ColorPeriodTextLookup} from "common/enumerations/TransactionColorStatus";
import {TransactionStatusEnum} from "common/enumerations/TransactionStatusEnum";
import PaymentStatusModel from "modules/dashboard/models/PaymentStatusModel";
import PaymentStatusTabsModel from "modules/dashboard/models/PaymentStatusTabsModel";
import DataListModel from "../models/DataListModel";
import GetPaymentStatusRequest from 'modules/dashboard/APIRequest/GetPaymentStatusRequest';
import { PaymentStatusPeriodEnum } from "common/enumerations/PaymentStatusPeriodEnum";
import APIManager from "common/Manager/APIManager";
import PaymentStatusChart from "./PaymentStatusChart";
import PieNoData from "./PieNoData";

const PieChart = loadable(() =>
	pMinDelay(import("jsx/components/Mophy/CardsCenter/PieChart"), 1000)
);

const cardBlog = [
	{image: card1, type: 'Primary',		 numb: '2256', bank: 'SBI Bank', },	
	{image: card2, type: 'Secondary', 	 numb: '6551', bank: 'IOB', },
	{image: card3, type: 'Primary',		 numb: '6783', bank: 'AXIS Bank', },
	{image: card4, type: 'Secondary', 	 numb: '8843', bank: 'BOB', },
];

// type Tprop = {
// 	paymentStatusTab : PaymentStatusTabsModel;

// }


const PaymentStatusTab = ({paymentStatusTab}) => {
	
  const [activeTab, setActiveTab] = useState("1");

  const [tabMonth, setTabMonth] = useState();
  const [tabWeek, setTabWeek] = useState();
  const [tabDay, setTabDay] = useState();
  const [period, setPeriod] = useState(PaymentStatusPeriodEnum.MONTHLY);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  	const [activeDefault, setActiveDefault] = useState(0);
		
	const month = paymentStatusTab?.monthly
	const week = paymentStatusTab?.weekly
	const today = paymentStatusTab?.today

	const apvLabel1 = month?.approve_status?.map(x=> getTransactionStatusName(x?.label));
	const apvColor1 = month?.approve_status?.map(x=> TransactionColorStatusText(x?.label));
	const apvData1 = month?.approve_status?.map(x=> Number(x?.value));

	const paidLabel1 = month?.paid_status?.map(x=> ColorPeriodTextLookup[x?.label]);
	const paidColor1 = month?.paid_status?.map(x=> ColorPeriodLookup[x?.label]);
	const paidData1 = month?.paid_status?.map(x=> Number(x?.value));	

	const apvLabel2 = week?.approve_status?.map(x=> getTransactionStatusName(x?.label));
	const apvColor2 = week?.approve_status?.map(x=> TransactionColorStatusText(x?.label));
	const apvData2 = week?.approve_status?.map(x=> Number(x?.value));

	const paidLabel2 = week?.paid_status?.map(x=> ColorPeriodTextLookup[x?.label]);
	const paidColor2 = week?.paid_status?.map(x=> ColorPeriodLookup[x?.label]);
	const paidData2 = week?.paid_status?.map(x=> Number(x?.value));

	const apvLabel3 = today?.approve_status?.map(x=> getTransactionStatusName(x?.label));
	const apvColor3 = today?.approve_status?.map(x=> TransactionColorStatusText(x?.label));
	const apvData3 = today?.approve_status?.map(x=> Number(x?.value));

	const paidLabel3 = today?.paid_status?.map(x=> ColorPeriodTextLookup[x?.label]);
	const paidColor3 = today?.paid_status?.map(x=> ColorPeriodLookup[x?.label]);
	const paidData3 = today?.paid_status?.map(x=> Number(x?.value));

	useEffect(() => {
	
	}, []);

  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header d-block d-sm-flex border-0">
		  	<div>
				<h4 className="fs-20 text-black">Payment Status</h4>
				<p className="mb-0 fs-13">Transaction Count Group By Status</p>
			</div>
            <div className="card-action card-tabs mt-3 mt-sm-0">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <Link
                    to={"#"}
                    className={
                      classnames({ active: activeTab === "1" }) + " nav-link"
                    }
                    onClick={() => {
                      toggle("1");
					 
                    }}
                  >
                    Monthly
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"#"}
                    className={
                      classnames({ active: activeTab === "2" }) + " nav-link"
                    }
                    onClick={() => {
                      toggle("2");					  
                    }}
                  >
                    Weekly
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"#"}
                    className={
                      classnames({ active: activeTab === "3" }) + " nav-link"
                    }
                    onClick={() => {

                      toggle("3");

                    }}
                  >
                    Today
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="card-body payment-bx tab-content p-0">
            <div
              className="tab-pane active show fade"
              id="monthly"
              role="tabpanel"
            >
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
			
                  <Accordion
				  
                    className="accordion accordion-primary"
                    defaultActiveKey="-1"
                    id="accordion-one"
                  >
                    <div className="accordion__item border-bottom mb-0">
                      <Accordion
                       // variant=""
                        className={` ${
                          activeDefault === 0
                            ? "align-items-center accordion__header  rounded collapsed"
                            : "align-items-center accordion__header  rounded"
                        }`}
						
                      >
                        <div className="mb-lg-0 mb-3 ">
                          <div className="row">

							<div className="col-xl-6 col-sm-6">
								<div className="col-xl-12 col-sm-12">
								<div className=""> Approved Status </div>
									<div className="card-body">
									{apvData1?.length == 0 ? (<PieNoData></PieNoData>) : 	

										<PieCustom 
											list_labels={apvLabel1}
											list_datas={apvData1}
											list_colors={apvColor1}
										/>
									}
									</div>
								</div>
								<div className=" col-xl-12 col-sm-12">

									<div className="d-flex flex-wrap text-black fs-12 mt-4">


										{month?.approve_status?.map( (x:DataListModel) =>

											(<span className="mr-4 mb-3">
												<svg
													className="mr-2"
													width="19"
													height="19"
													viewBox="0 0 19 19"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<rect
													width="19"
													height="19"
													rx="9.5"
													fill={TransactionColorStatusText(x?.label)}
													/>
												</svg>
												{getTransactionStatusName(x?.label)}
											</span>)
										)}

									</div>
									
								</div>

							</div>

							<div className="col-xl-6 col-sm-6">

								<div className="col-xl-12 col-sm-12">
								<div> Paid / Not Paid  </div>
									<div className="card-body">

									{paidData1?.length == 0 ? (<PieNoData></PieNoData>) : 	
										<PieCustom 

											list_labels={paidLabel1}
											list_datas={paidData1}
											list_colors={paidColor1}		
										/>
									}
									</div>
								</div>
								<div className=" col-xl-12 col-sm-12">

									<div className="d-flex flex-wrap text-black fs-12 mt-4">
										
									{month?.paid_status?.map( (x:DataListModel) =>

									(<span className="mr-4 mb-3">
										<svg
											className="mr-2"
											width="19"
											height="19"
											viewBox="0 0 19 19"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect
											width="19"
											height="19"
											rx="9.5"
											fill={ColorPeriodLookup[x?.label]}
											/>
										</svg>
										{ColorPeriodTextLookup[x?.label]}
									</span>)
									)}

								

																	

									</div>
								</div>

							</div>

							
                          </div>
                        </div>
                      </Accordion>
                    
                    </div>
                  </Accordion>

                </TabPane>

                <TabPane tabId="2">

				<Accordion
				  
				  className="accordion accordion-primary"
				  defaultActiveKey="-1"
				  id="accordion-one"
				>
				  <div className="accordion__item border-bottom mb-0">
					<Accordion
					 // variant=""
					  className={` ${
						activeDefault === 0
						  ? "align-items-center accordion__header  rounded collapsed"
						  : "align-items-center accordion__header  rounded"
					  }`}
					  
					>
					  <div className="mb-lg-0 mb-3 ">
						<div className="row">

						  <div className="col-xl-6 col-sm-6">
							  <div className="col-xl-12 col-sm-12">
							  <div className=""> Approved Status </div>
								  <div className="card-body">
								  	{apvData2?.length == 0 ? (<PieNoData></PieNoData>) : 	
									  <PieCustom 
										  list_labels={apvLabel2}
										  list_datas={apvData2}
										  list_colors={apvColor2}
									  />
									}

								  </div>
							  </div>
							  <div className=" col-xl-12 col-sm-12">

								  <div className="d-flex flex-wrap text-black fs-12 mt-4">


									  {week?.approve_status?.map( (x:DataListModel) =>

										  (<span className="mr-4 mb-3">
											  <svg
												  className="mr-2"
												  width="19"
												  height="19"
												  viewBox="0 0 19 19"
												  fill="none"
												  xmlns="http://www.w3.org/2000/svg"
											  >
												  <rect
												  width="19"
												  height="19"
												  rx="9.5"
												  fill={TransactionColorStatusText(x?.label)}
												  />
											  </svg>
											  {getTransactionStatusName(x?.label)}
										  </span>)
									  )}

								  </div>
								  
							  </div>

						  </div>

						  <div className="col-xl-6 col-sm-6">

							  <div className="col-xl-12 col-sm-12">
							  <div> Paid / Not Paid  </div>
								  <div className="card-body">
								  	{paidData2?.length == 0 ? (<PieNoData></PieNoData>) : 
									  <PieCustom 

										  list_labels={paidLabel2}
										  list_datas={paidData2}
										  list_colors={paidColor2}		
									  />
									}
								  </div>
							  </div>
							  <div className=" col-xl-12 col-sm-12">

								  <div className="d-flex flex-wrap text-black fs-12 mt-4">
									  
								  {week?.paid_status?.map( (x:DataListModel) =>

								  (<span className="mr-4 mb-3">
									  <svg
										  className="mr-2"
										  width="19"
										  height="19"
										  viewBox="0 0 19 19"
										  fill="none"
										  xmlns="http://www.w3.org/2000/svg"
									  >
										  <rect
										  width="19"
										  height="19"
										  rx="9.5"
										  fill={ColorPeriodLookup[x?.label]}
										  />
									  </svg>
									  {ColorPeriodTextLookup[x?.label]}
								  </span>)
								  )}

							  

																  

								  </div>
							  </div>

						  </div>

						  
						</div>
					  </div>
					</Accordion>
				  
				  </div>
				</Accordion>
						
                </TabPane>

                <TabPane tabId="3">

				<Accordion
				  
				  className="accordion accordion-primary"
				  defaultActiveKey="-1"
				  id="accordion-one"
				>
				  <div className="accordion__item border-bottom mb-0">
					<Accordion
					 // variant=""
					  className={` ${
						activeDefault === 0
						  ? "align-items-center accordion__header  rounded collapsed"
						  : "align-items-center accordion__header  rounded"
					  }`}
					  
					>
					  <div className="mb-lg-0 mb-3 ">
						<div className="row">

						  <div className="col-xl-6 col-sm-6">
							  <div className="col-xl-12 col-sm-12">
							  <div className=""> Approved Status </div>
								  <div className="card-body">
					  			 
								  {apvData3?.length == 0 ? (<PieNoData></PieNoData>) : <PieCustom 
										  list_labels={apvLabel3}
										  list_datas={apvData3}
										  list_colors={apvColor3}
									  /> }

									

								  </div>
							  </div>
							  <div className=" col-xl-12 col-sm-12">

								  <div className="d-flex flex-wrap text-black fs-12 mt-4">


									  {today?.approve_status?.map( (x:DataListModel) =>

										  (<span className="mr-4 mb-3">
											  <svg
												  className="mr-2"
												  width="19"
												  height="19"
												  viewBox="0 0 19 19"
												  fill="none"
												  xmlns="http://www.w3.org/2000/svg"
											  >
												  <rect
												  width="19"
												  height="19"
												  rx="9.5"
												  fill={TransactionColorStatusText(x?.label)}
												  />
											  </svg>
											  {getTransactionStatusName(x?.label)}
										  </span>)
									  )}

								  </div>
								  
							  </div>

						  </div>

						  <div className="col-xl-6 col-sm-6">

							  <div className="col-xl-12 col-sm-12">
							  <div> Paid / Not Paid  </div>
								  <div className="card-body">

								  	{paidData3?.length == 0 ? (<PieNoData></PieNoData>) : 
									  <PieCustom 

										  list_labels={paidLabel3}
										  list_datas={paidData3}
										  list_colors={paidColor3}		
									  />

									}
								  </div>
							  </div>
							  <div className=" col-xl-12 col-sm-12">

								  <div className="d-flex flex-wrap text-black fs-12 mt-4">
									  
								  {today?.paid_status?.map( (x:DataListModel) =>

								  (<span className="mr-4 mb-3">
									  <svg
										  className="mr-2"
										  width="19"
										  height="19"
										  viewBox="0 0 19 19"
										  fill="none"
										  xmlns="http://www.w3.org/2000/svg"
									  >
										  <rect
										  width="19"
										  height="19"
										  rx="9.5"
										  fill={ColorPeriodLookup[x?.label]}
										  />
									  </svg>
									  {ColorPeriodTextLookup[x?.label]}
								  </span>)
								  )}

								  </div>
							  </div>

						  </div>

						  
						</div>
					  </div>
					</Accordion>
				  
				  </div>
				</Accordion>
                  
                </TabPane>
				
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentStatusTab;
