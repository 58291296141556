import React from 'react'
import Header from 'modules/register/component/Header'
import Footer from 'modules/register/component/Footer'
import CssBaseline from '@material-ui/core/CssBaseline'

import FooterMenuModel from 'modules/register/models/FooterMenuModel'
import InitializeManager from 'common/Manager/InitializeManager'
import FormRegister from 'modules/register/component/FormRegister'

const isClient = typeof window === 'object';
let footerMenu: FooterMenuModel;

const Register = (prop:any) => {
  
  let translate: any = {} 
  let token:string, id:string, locale:string;

  if (!isClient) {
    
    const footerMenuArr = JSON.parse(InitializeManager.default.get()).data?.footerMenu
    footerMenu = footerMenuArr.map( (x:any) => new FooterMenuModel(x) ) 
    translate = JSON.parse(InitializeManager.default.get()).data.register  

    token = JSON.parse(InitializeManager.default.get()).data.token  
    id = JSON.parse(InitializeManager.default.get()).data.id 
    locale = JSON.parse(InitializeManager.default.get()).data.locale 

  } else {

    const footerMenuArr = InitializeManager.default.get().data?.footerMenu;     
    footerMenu = footerMenuArr.map( (x:any) => new FooterMenuModel(x) ) 
    translate = InitializeManager.default.get().data?.register;  

    token = InitializeManager.default.get().data?.token;  
    id = InitializeManager.default.get().data?.id;  
    locale = InitializeManager.default.get().data?.locale;  
   
  }
 
  return (
    <>
    <CssBaseline />
    
    <Header  
      translate={translate} 
      token={token}
      id={id}    
      locale={locale}      
      /> 
      
    <FormRegister  
      translate={translate} 
      token={token}
      id={id}  
      locale={locale}   
    />
      
    <Footer 
        translate={translate}
        footerMenu={footerMenu}
     />

    </>
    
  )
  
}

export default Register
