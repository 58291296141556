import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'
import APIRequest from 'common/interface/APIRequest'
import LoginInterface from 'common/interface/LoginInterface';


class LoginRequest  {

  method: HTTPMethodEnum = HTTPMethodEnum.POST
  url: string = '/auth/login'
  param: LoginInterface
  
  constructor (param:LoginInterface) {
    this.param = param
  }

  makeBody() { 
    return this.param 
    
  }
  makeQuery() { }

  makeHeader() { }

}

export default LoginRequest