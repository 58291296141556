import _ from "lodash";

class LinkModel {
   
    id:number;
    label: string;
    sort: number;
    url: string;
    active: string;
    
  constructor(json: any) { 
    this.id = _.get(json, "id"); 
    this.label = _.get(json, "label");
    this.sort = _.get(json, "sort");
    this.url = _.get(json, "url");
    this.active = _.get(json, "active");
   
   

  }

  // validate(): ValidationModel {
  //   const validationModel = new ValidationModel()
  //   validationModel.isValid = true

  //   if (this.channelId == undefined) {
  //       validationModel.isValid = false
  //       validationModel.message = "Please specify `channel Id`"
  //   }

  //   if (this.paymentProviderId == undefined) {
  //     validationModel.isValid = false
  //     validationModel.message = "Please specify `paymentProvider Id`"
  //   }

  //   if (this.langCode == undefined) {
  //     validationModel.isValid = false
  //     validationModel.message = "Please specify `paymentProvider Id`"
  //   }

  //   return validationModel
  // }



}

export default LinkModel;
