import React from "react";
import MerchantInfoHook from 'modules/register/Hook/RegisterHook';
import { useState } from "react";


const StepOne = (prop) => {

  const {step1, handleInputChange, hasError, translate} = prop

  return (
    <section>
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">{translate['regis.step1.merchant_name']} </label>
            <input
              type='text'
              name='merchant_name'
             className={
                hasError("merchant_name")
                  ? "form-control is-invalid"
                  : "form-control"
              }
              placeholder=''
              onChange={handleInputChange}
              value={step1.merchant_name}
              autoFocus              

            />
            <div className="invalid-feedback text-left">
              {translate['regis.step1.merchant_name.error']}
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">{translate['regis.step1.trading_name']}</label>
            <input
              type="text"
              name="trading_name"
              className={
                hasError("trading_name")
                  ? "form-control is-invalid"
                  : "form-control"
              }
              placeholder=""
              value={step1.trading_name}
              onChange={handleInputChange}
            />
            <div className="invalid-feedback text-left">
            {translate['regis.step1.trading_name.error']}
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">{translate['regis.step1.address']}</label>
            <input
              type='address'
              name='address'
              className={
                hasError("address")
                  ? "form-control is-invalid"
                  : "form-control"
              }
              placeholder=''
              value={step1.address}
              onChange={handleInputChange}
            />
            <div className="invalid-feedback text-left">
            {translate['regis.step1.address.error']}
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">{translate['regis.step1.person_type']}</label>
            <div className="mb-0">
              <div className="radio register-align">
                <label>
                <input type="radio" name="person_type"                  
                  onChange={handleInputChange}             
                  checked={step1.person_type == 'I'} 
                  value={'I'}
                  className="radio" 
                 />
                {translate['regis.step1.individual']}</label>
              </div>
             <div className="radio register-align">
                <label>
                <input type="radio" name="person_type" 
                 onChange={handleInputChange} 
                 checked={step1.person_type == 'C'} 
                 value={'C'}
                 className="radio" 
                />
                {translate['regis.step1.corporate']}</label>
              </div>

            </div>

             { hasError("contact_email") } 
              <div className="invalid-feedback text-left">
                {translate['regis.step1.person_type']}
              </div>
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">{translate['regis.step1.mobile']}</label>
            <input
              type='number'
              name='mobile'
              className={
                hasError("mobile")
                  ? "form-control is-invalid"
                  : "form-control"
              }
              placeholder=''
              value={step1.mobile}
              onChange={handleInputChange}

            />
            <div className="invalid-feedback text-left">
            {translate['regis.step1.mobile.error']}
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="text-label">{translate['regis.step1.phone']}</label>

            <input
              // type='text'
              type='number'
              name='phone'
              className={
                hasError("phone")
                  ? "form-control is-invalid"
                  : "form-control"
              }
              placeholder=''
              value={step1.phone}
              onChange={handleInputChange}
            />
            <div className="invalid-feedback text-left">
            {translate['regis.step1.phone.error']}
            </div>

          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">{translate['regis.step1.contact_email']}</label>
            <input
              type='text'
              name='contact_email'
              className={
                hasError("contact_email")
                  ? "form-control is-invalid"
                  : "form-control"
              }
              placeholder=''
              value={step1.contact_email}
              onChange={handleInputChange}
            />
            <div className="invalid-feedback text-left">
              {translate['regis.step1.contact_email.error']}
            </div>

          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="text-label">{translate['regis.step1.contact_person']}</label>
            <input
              type='text'
              name='contact_person'
              className={
                hasError("contact_person")
                  ? "form-control is-invalid"
                  : "form-control"
              }
              placeholder=''
              value={step1.contact_person}
              onChange={handleInputChange}
            />
            {hasError("contact_person")}
            <div className="invalid-feedback text-left">
              {translate['regis.step1.contact_person.error']}
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">{translate['regis.step1.support_email']}</label>
            <input
              type='text'
              name='support_email'
              className={
                hasError("support_email")
                  ? "form-control is-invalid"
                  : "form-control"
              }
              placeholder=''
              value={step1.support_email}
              onChange={handleInputChange}
            />
            <div className="invalid-feedback text-left">
            {translate['regis.step1.support_email.error']}
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="text-label"> {translate['regis.step1.fax']}</label>
            <input 
            
            type='number'
            
            name="fax" className={
                hasError("fax")
                  ? "form-control is-invalid"
                  : "form-control"
              }  
              value={step1.fax}
              onChange={handleInputChange}
              />
          </div>
          <div className="invalid-feedback text-left">
              {translate['regis.step1.fax.error']}
            </div>
        </div>


      </div>
    </section>
  );
};

export default StepOne;
