import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'
import  ServerConfig from 'common/Manager/ServerConfigManager';


class CreatePasswordRequest  {

  method: HTTPMethodEnum = HTTPMethodEnum.POST
  url: string = '/password/create'
  param: any
  
  constructor (param:any) {
    this.param = param
  }

  makeBody() { 
    return this.param 
  }

  makeQuery() { }

  makeHeader() { 
    return {
     'API_KEY': ServerConfig.default.getEnvServer('BACKOFFICE_API_KEY')
    }
  }

}

export default CreatePasswordRequest