
import _ from 'lodash'
import DataListModel from 'modules/dashboard/models/DataListModel';
import PeriodDate from './PeriodDate';

class MainBalanceDatePeriodModel {

 
    period30 :PeriodDate;
    period60 :PeriodDate;
    period1year :PeriodDate;
    period2year :PeriodDate;

    constructor(json: any) {

        this.period30 = _.get(json, 'period30');
        this.period60 = _.get(json, 'period60');  
        this.period1year = _.get(json, 'period1year');     
        this.period2year = _.get(json, 'period2year');  
            
    }
}

export default MainBalanceDatePeriodModel