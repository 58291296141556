import React, { useRef, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import APIManager from "common/Manager/APIManager";
import { isImage } from "common/Manager/Helper";

import GetMerchantInfoByIdRequest from "modules/account/merchant-info/APIRequest/GetMerchantInfoByIdRequest";
import EditLogoRequest from "modules/account/merchant-info/APIRequest/EditLogoRequest";
import ChannelRequest from "modules/account/merchant-info/APIRequest/ChannelRequest";

import Option from "modules/account/merchant-info/component/Option";
import MerchantModel from "modules/account/merchant-info/models/MerchantModel";

import swal from "sweetalert";
import BusinessTypeRequest from 'modules/register/APIRequest/BusinessTypeRequest';
import InitializeManager from 'common/Manager/InitializeManager';


const index = () => {

    type OptionParams = {
        page: string;
    };

    let contentTranslate: any = {} 
    let ENV_CLIENT: any = {}

    const isClient = typeof window === 'object';
  
    if (!isClient) {
      
      contentTranslate = JSON.parse(InitializeManager.default.get()).data.contentTranslation 
      ENV_CLIENT = JSON.parse(InitializeManager.default.get()).data?.ENV_CLIENT
 
    } else {
  
      contentTranslate = InitializeManager.default.get().data?.contentTranslation;   
      ENV_CLIENT = InitializeManager.default.get().data.ENV_CLIENT
     
    }

  const [merchantData, setMerchantData] = useState<MerchantModel>();
  const [docData, setDoctData] = useState<any>();

  const [fileLogoUpload, setFileLogoUpload] = React.useState<any>();
  const [logo, setLogo] = React.useState();
  const [state, setState] = useState({
    maxLength: 0,
    formData: null,
    uploadValid: false,
    uploadMessage: "",
  });

  const [dimension, setDimension] = useState({
    naturalHeight: null,
    naturalWidth: null      
  });

  const [channel, setChannel] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [bank, setBank] = useState([]); 
  const { page } = useParams<OptionParams>();

  let cselected = [];
  const channelSelected = (cid) => {
    
    let find = cselected?.findIndex((v) => v  == cid);
    if (find > -1) {
      return true;
    }
    return false;

  };
 
  const getMerchantInfo = async () => {

    const apiRequest = new GetMerchantInfoByIdRequest();
    const items = await APIManager.default.fetch(apiRequest);

    const {merchantData , documentData} = items.data.data
       
    if(merchantData){

      const qchannel:String[] = []; 
      const apiChannelRequest = new ChannelRequest('en');
      const channelList = await APIManager.default.fetch(apiChannelRequest);
      const channelArr = merchantData?.payment_method.split(",") || [];

      for(const x of channelList.data.data) {

        if(channelArr.indexOf((String(x.id))) > -1){
            qchannel.push(` ${x.name}`)
        }       
      }
      
      merchantData.payment_method = qchannel?.toString();

    }
    const URL = ENV_CLIENT['MERCHANT_BACKOFFICE_ASSET'] +"/"+ merchantData?.logo

    setMerchantData(merchantData);
    setFileLogoUpload(URL)
    setDoctData(documentData)

  };

  useEffect(() => {
    getMerchantInfo();
    getBusinessType();      
  }, []);

  const handleChangeFile = (event) => {

    let fileList:any;
    fileList = event.target.files;
 
    if (!fileList) return;
    if (fileList.length == 0) return;

    const { name: fileName, size: fileSize } = fileList[0];
    state.uploadMessage = "";
    state.uploadValid = false;

    if (!isImage(fileName)) {
      state.uploadMessage = "files type not correct!";
      setState({ ...state });
      return;
    }

    if (fileSize > 200000) {
      state.uploadMessage = "files size not exceed 200KB!";
      setState({ ...state });
      return;
    }

  
    state.uploadValid = true;
    setFileLogoUpload(URL.createObjectURL(fileList[0]));
    setLogo(fileList[0]);

  };

  const handleUpload = async (event) => {   
    

    if (state.uploadValid) {
      const formData = new FormData();

      formData.append("logo", logo);

      const apiRequest = new EditLogoRequest(formData);
      const dataResponse = await APIManager.default.fetch(apiRequest);

      if (dataResponse) {
        if (dataResponse.data.status == 200) {
          swal("Success!", {
            icon: "success",
          });
        }
      }
    }


  };

  const getBusinessType = async () => {

    const apiRequest = new BusinessTypeRequest('en');
    const businessTypeList = await APIManager.default.fetch(apiRequest);
    setBusinessType(businessTypeList.data.data)

  };

  return (
  <>
      <Option	
      
        merchantData={merchantData}
        page={page}
        fileLogoUpload={fileLogoUpload}
        logo={logo}
        state={state}
        handleChangeFile={handleChangeFile}
        handleUpload={handleUpload}
        channel={channel}
        businessType={businessType}
        bank={bank}
        channelSelected={channelSelected}
        docData={docData}
        contentTranslate={contentTranslate}
        ENV_CLIENT={ENV_CLIENT}
        setState={setState}

      >
      </Option>
    </>
  )

}

export default index;