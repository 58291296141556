
import _ from 'lodash'
import DataListModel from 'modules/dashboard/models/DataListModel';
import PeriodDate from './PeriodDate';

class PaymentStatusDatePeriodModel {

 
    monthly :PeriodDate;
    weekly :PeriodDate;
    today :PeriodDate;

    constructor(json: any) {

        this.monthly = _.get(json, 'monthly')   
        this.weekly = _.get(json, 'weekly')  
        this.today = _.get(json, 'today')      
    
        
    }
}

export default PaymentStatusDatePeriodModel