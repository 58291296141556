import React,{Fragment, useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, Modal} from 'react-bootstrap';
import {DropdownBlog} from 'jsx/components/Dashboard/Invoices';
import { Redirect, useParams } from "react-router-dom";
import TransactionDetailRequest from "modules/transaction/creditcard/APIRequest/TransactionDetailRequest";
import APIManager from "common/Manager/APIManager";
import UserMenuModel from "modules/usermenu/models/UserMenuModel";
import TransactionActionModel from "models/TransactionActionModel";
import TransactionsDetail from "modules/transaction/creditcard/component/TransactionsDetail"
import VoidTransactionlRequest from "modules/transaction/creditcard/APIRequest/VoidTransactionRequest";
import _ from 'lodash';
import swal from "sweetalert";
import Datetime from 'utilities/Datetime'
import InitializeManager from 'common/Manager/InitializeManager';

type Params = {
	id: string;
  };

const detail = (props: any) => {

    let  page  = 'edit-contact';

	let title = 'Credit Card'
	let clickVoid = false;
	
	const [transactionData, setTransactionData] = useState({});
	const { id } = useParams<Params>();
	
	let translate: any = {} 
    let userMenuModel: any[]
    let ENV_CLIENT: any = {}

	let isShowVoid =false
	
    const isClient = typeof window === 'object';
  
    if (!isClient) {
		userMenuModel =  (JSON.parse(InitializeManager.default.get()).data.moduleMenu) 
    } else {

		userMenuModel =  (InitializeManager.default.get().data?.moduleMenu); 
		const findVoid = userMenuModel.findIndex( (x:UserMenuModel) =>
			x.module_action.find(a => a.route_api == '/void')
		)

		if(findVoid != -1){
			isShowVoid = true
		}
		 
    }


  	const getTransaction = async (ids :string) => {    
	
		const apiRequest = new TransactionDetailRequest(ids);

		try {

			const items = await APIManager.default.fetch(apiRequest);
			setTransactionData(items.data.data);
			
		} catch (error) {
			console.log(" error  : ");
		}


	};

	const voidTransaction = async (brandIdentifier :string, Ref :string, uuid :string) => {    	


		if(clickVoid)
		{
			return ;
		}
		const transactionAction = new  TransactionActionModel({brandIdentifier: brandIdentifier, Ref: Ref, uuid: uuid })
		const apiRequest = new VoidTransactionlRequest(transactionAction);

		try {

			clickVoid = true
			const items = await APIManager.default.fetch(apiRequest);
			const status  = _.get(items.data, 'status')

			if(status == 200){

				swal("success", {
					icon: "success",
				  }).then(()=>{

					window.location.href = "/transaction/creditcard";   

				  });

			}
			else{
				clickVoid = false
				swal('', 'void error ' , "error");
			}

			//setTransactionData(items.data.data);
			
		} catch (error) {
			swal("Oops", "Something went wrong!", "error")
			console.log(" error  : ");
		}


	};

	
	if(page == 'edit-contact'){
		title = 'Edit Contact'		
	}

	if(page == 'edit-logo'){
		title = 'Edit Logo'
	}

	useEffect( () => {

		getTransaction(id);		

	}, [])


  return (
	<>
        <TransactionsDetail 
			data={transactionData} 
			voidTransaction={voidTransaction}
			key={1} 
			userMenuModel={userMenuModel}
			isShowVoid={isShowVoid}
		>
		</TransactionsDetail> 
    </>
  )


}

export default detail;