import _ from 'lodash'
import UserRoleModel from './TransactionModel'


class QuickPayModel {

    id?:number
    order_ref:string
    url?:string
    customer_name:string
    mobile:string
    email:string
    detail:string
    amount:number
    expiry:number
    lang_code:string
    currency:string

    channel : number[]
    merchant_id? :number;
    created_at? :Date
    expired_at?:Date
    
    html?:string
    business_type:string

    status:string

    is_sendemail:string

    product_id?:string
    product_name?:string

    constructor(json: any) {
        
        this.id = _.get(json, 'id')
        this.order_ref = _.get(json, 'order_ref')
        this.url = _.get(json, 'url')
        this.customer_name = _.get(json, 'customer_name')
        this.mobile = _.get(json, 'mobile')
        this.email = _.get(json, 'email')
        this.detail = _.get(json, 'detail')
        this.amount = _.get(json, 'amount')
        this.expiry = _.get(json, 'expiry')
        this.lang_code = _.get(json, 'lang_code')
        this.currency = _.get(json, 'currency')
        this.url = _.get(json, 'url')
        this.created_at = _.get(json, 'created_at')
        this.expired_at = _.get(json, 'expired_at')
        
        this.merchant_id = _.get(json, 'merchant_id')
        this.html = _.get(json, 'html')

        this.business_type = _.get(json, 'business_type')

        this.status = _.get(json, 'status')

        this.channel = _.get(json, 'channel')

        this.is_sendemail = _.get(json, 'is_sendemail')
        this.product_id = _.get(json, 'product_id')
        this.product_name = _.get(json, 'product_name')
      
    }

  
}

export default QuickPayModel