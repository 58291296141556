import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import QuickPayModel from "modules/quickpay/models/QuickPayModel";
import FormEmailModel from "modules/quickpay/models/FormEmailModel";




class SendEmailRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/quickpay/sendlinkpayment";
  param: FormEmailModel
 

  constructor(formEmailModel : FormEmailModel) {
    this.param = formEmailModel
  }

  makeBody() {

    return {
        ...this.param
    }
  }

  makeQuery() {}
}

export default SendEmailRequest;


