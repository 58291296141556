import React, { useState, Fragment, useEffect, useRef } from 'react'
import _ from 'lodash';
import useStyles from './Styles'

import Multistep from "../MultiStep/index";
import StepOne from "../Forms/Wizard/StepOne";
import StepTwo from "../Forms/Wizard/StepTwo";
import StepThree from "../Forms/Wizard/StepThree";
import StepFour from "../Forms/Wizard/StepFour";

import RegisterHook from 'modules/register/Hook/RegisterHook';

import CreateMerchantTemp from "modules/register/APIRequest/CreateMerchantTemp";
import DocumentSettingRequest from "modules/register/APIRequest/DocumentSettingRequest";
import APIManager from "common/Manager/APIManager";

import { json } from 'body-parser';

const FormRegister = (props: any) => {

  const { translate, token, id, locale   } = props
  const classes = useStyles();
  const isClient = typeof window === 'object';
  
  const {      
          setDoc,      
          setUpdFileStatus,
          setStep1, channelSelected, handleCheckbox, hasError,
          setFileUpload,
          step1,
          step2, 
          step3,
          doc,
          logo,
          handleInputChangeStep1, 
          handleInputChangeStep2, 
          handleInputChangeStep3, 
          setFormEror,
          fileLogoUpload,
          files,
          handleChangeFileLogo,
          handleChangeFile,
          setUpdLogoStatus,
          updLogoStatus, 
          updFileStatus,
          validateStep,
          validateAllInput   
    
  } = RegisterHook();

  const steps = [
    { name: "Merchant Informtion", component:  
    <StepOne 
      step1={step1} 
      setStep1={setStep1}  
      hasError={hasError}
      handleInputChange={handleInputChangeStep1}   
      translate={translate}  
      /> 
    },
    { name: "Upload Document", component: 
    <StepTwo
      step2={step2} 
      step1={step1} 
      hasError={hasError}
      handleInputChange={handleInputChangeStep2}   
      fileLogoUpload={fileLogoUpload} 
      handleChangeFileLogo={handleChangeFileLogo}
      handleChangeFile={handleChangeFile}
      setUpdLogoStatus={setUpdLogoStatus}
      setUpdFileStatus={setUpdFileStatus}
      updLogoStatus={updLogoStatus}
      updFileStatus={updFileStatus}
      translate={translate}  
      doc={doc} 
      setDoc={setDoc} 
      setFileUpload={setFileUpload}  
    
    /> 

    },
    { name: "3", component: 
    <StepThree
      step3={step3} 
      hasError={hasError}
      channelSelected={channelSelected}
      handleInputChange={handleInputChangeStep3}  
      handleCheckbox={handleCheckbox}  
      locale={locale}
      translate={translate}
    />   
    },
    { name: "4", component: <StepFour translate={translate} /> },
  
  ];

  const prevStyle = {
    background: "#F7FAFC",
    borderWidth: "0px",
    color: "#333333",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "600",
    padding: "12px 18px",
    border: "1px solid #EEEEEE",
    marginRight: "1rem",
  };

  const nextStyle = {
    background: "#1eaae7",
    borderWidth: "0px",
    color: "#fff",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "600",
    padding: "12px 18px",
  };

  const getDocSetting = async () => {

    const apiRequest = new DocumentSettingRequest();
    const docSetting = await APIManager.default.fetch(apiRequest);
    
    const docData = docSetting.data.data

    for(let i = 0; i < docData.length; i++)
    {
      updFileStatus.push({
        maxLength: 0,
        formData: null,
        uploadValid: false,
        uploadMessage: "",
      })

    }
 
    setUpdFileStatus(updFileStatus)
    setDoc(docData)

  };

  const createMerchantTemp = async () => {
    
    const formData = new FormData();   
    formData.append("merchant_name",step1.merchant_name);
    formData.append("trading_name",step1.trading_name);	
    formData.append("logo",logo);	
    formData.append("contact_person",step1.contact_person);	
    formData.append("phone",step1.phone);	
    formData.append("mobile",step1.mobile);	
    formData.append("fax",step1.fax);	
    formData.append("contact_email",step1.contact_email);	
    formData.append("support_email",step1.support_email);	
    formData.append("address",step1.address);	
    formData.append("website",step2.website);	
    formData.append("facebook",step2.facebook);	
    formData.append("instagram",step2.instagram);	
    formData.append("line",step2.line);	
    formData.append("business_type", step3.business_type);
    formData.append("bank", step3.bank_name);	
    formData.append("bank_account", step3.bank_account);	
    formData.append("payment_method", step3.payment_method);
    formData.append("token", token);	
    formData.append("ref_id", id);

    for (var key in files) {
      if (files.hasOwnProperty(key)) {
          formData.append(key, files[key]);	
         
      }
    }
   
    doc.map( (v:any) => {
      const doc_data = JSON.stringify(v)
      formData.append("docs[]", doc_data)  
    })
     
    const apiRequest = new CreateMerchantTemp(formData);
    const data = await APIManager.default.fetch(apiRequest);
        
    if(data.data.status !== 200){
      return;
    }
   
      
  };

  useEffect(() => {
    getDocSetting();   
  }, []);

  return (

    <Fragment>
      {/* <PageTitle activeMenu="Wizard" motherMenu="Home" /> */}
      <div className={classes.container} >

      <div className= "page-register-titles form-head d-flex flex-wrap justify-content-between align-items-center mb-4">
				<h2 className="text-black font-w600 mb-0 mr-auto mb-2 pr-3">{translate['regis.merchant.info']}</h2>
			</div>
          
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">{translate['regis.fill.merchant']}</h4>
              </div>
              <div className="card-body">
                <form
                     onSubmit={(e) => { e.preventDefault()}
                  }

                  id="step-form-horizontal"
                  className="step-form-horizontal"
                >
                  <Multistep
                      activeStep={0}
                      showNavigation={true}
                      steps={steps}
                      
                      step1={step1} 
                      step2={step2} 
                      step3={step3} 
                      
                      hasError ={hasError}
                      setFormEror={setFormEror}

                      setUpdLogoStatus={setUpdLogoStatus}
                      updLogoStatus={updLogoStatus}
                      updFileStatus={updFileStatus}

                      createMerchantTemp={createMerchantTemp}

                      prevStyle={prevStyle}
                      nextStyle={nextStyle}

                      translate={translate}  
                      // validateStep={validateStep}
                      validateAllInput={validateAllInput}
                  /> 
                  
                </form>                
         
              </div>
            </div>
          </div>
        </div>
     
      </div>

    </Fragment>
     

  )
}

export default FormRegister