import _ from 'lodash'
import moment from 'moment'

class TransactionActionModel {

    
    brandIdentifier: string
    Ref: string
    uuid: string
  

    constructor(json: any) {
        this.brandIdentifier = _.get(json, 'brandIdentifier')
        this.Ref = _.get(json, 'Ref')
        this.uuid = _.get(json, 'uuid')
   
 

    }
}

export default TransactionActionModel