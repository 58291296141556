import React from "react";

const UpTrend = () => {
  return (
    <>
      <svg
        className=""
        width="21"
        height="15"
        viewBox="0 0 21 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.999939 13.5C1.91791 12.4157 4.89722 9.22772 6.49994 7.5L12.4999 10.5L19.4999 1.5"
          stroke="#2BC155"
          stroke-width="2"
        />
        <path
          d="M6.49994 7.5C4.89722 9.22772 1.91791 12.4157 0.999939 13.5H19.4999V1.5L12.4999 10.5L6.49994 7.5Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="10.2499"
            y1="3"
            x2="10.9999"
            y2="13.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#2BC155" stop-opacity="0.73" />
            <stop offset="1" stop-color="#2BC155" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default UpTrend;
