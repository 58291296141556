import { makeStyles } from '@material-ui/core/styles';


import styled from "styled-components";
import { background } from 'styled-system';

const style = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),       
      },
    },
    appBar: {
      position: 'relative',
    },    
    instruction2: {   
        fontFamily: "DBHeaventRoundedMedv32"  
    },
    paper2: {
      backgroundColor: theme.palette.background.paper,
      padding: "20px"
    },  
    thaiqr:{
      border: "1px solid #02416d",
      textAlign:'center',
      width:'850px',
      height:'1260px'
    },
     header :{    
       background:'#113566',  
       height:'230px',
       display:'flex',
       alignItems:'center'   ,
       justifyContent: 'center'
    },
    img:{
      // width:'140px',
    //   height:'200px'
    },
    promptpay :{  
      paddingTop:'22px',
      paddingBottom:'22px',
    },
    paymentto :{
      marginTop:'2px',
      marginBottom:'-6px',
     
    },
    mr:{
      marginRight:'2px',
      fontSize:'28px'
    }
  
  

}));

export default style