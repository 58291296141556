import React, { Fragment, useState } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "jsx/layouts/PageTitle";
import NumberFormat from "utilities/NumberFormat";
import MetarialDate from "jsx/components/Forms/Pickers/MetarialDate";
import { DatePicker } from "@y0c/react-datepicker";
import TimeList from "./TimeList";
import dayjs from 'dayjs';
import QuickPayModel from "modules/quickpay/models/QuickPayModel";


const FormCreateQuickpay = (props) => {
  const {
    locales,
    channel,
    currencies,
    form ,
    actionUser,
    handleInputChange,
    hasError,
    handleCheckbox,
    createQuickpay,
    channelSelected,
    products,
    handleDateChange,
    minutes,
    hour,
    nowDate
  } = props;

  const [startDate, setStartDate] = useState(new Date());

  return (
    <Fragment>
      <PageTitle
        activeMenu="Linkpayment"
        pageHeading=""
        motherMenu={actionUser}
      />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 order-md-1">
                  <h4 className="mb-3"> {actionUser} Linkpayment </h4>
                  <form className="needs-validation">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Order Ref.</label>
                        <input
                          type="text"
                          className={
                            hasError("order_ref")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="order_ref"
                          name="order_ref"
                          placeholder=""
                          required
                          disabled={
                            form?.status != 0 || form?.is_sendemail == "y"
                              ? true
                              : false
                          }
                          value={form?.order_ref}
                          onChange={handleInputChange}
                        />
                        <div className="invalid-feedback">
                          Order ref is not valid or length must 12.
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Customer name</label>
                        <input
                          type="text"
                          className={
                            hasError("customer_name")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="customer_name"
                          name="customer_name"
                          placeholder=""
                          required
                          value={form?.customer_name}
                          onChange={handleInputChange}
                          disabled={
                            form?.status != 0 || form?.is_sendemail == "y"
                              ? true
                              : false
                          }
                        />
                        <div className="invalid-feedback">
                          Customer name is not valid.
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Mobile</label>
                        <input
                          type="text"
                          className={
                            hasError("mobile")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="mobile"
                          name="mobile"
                          placeholder=""
                          required
                          value={form?.mobile}
                          onChange={handleInputChange}
                          disabled={
                            form?.status != 0 || form?.is_sendemail == "y"
                              ? true
                              : false
                          }
                        />
                        <div className="invalid-feedback">
                          Mobile is not valid.
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="firstName">Email</label>
                        <input
                          type="text"
                          className={
                            hasError("email")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="email"
                          name="email"
                          placeholder=""
                          value={form?.email}
                          onChange={handleInputChange}
                          disabled={
                            form?.status != 0 || form?.is_sendemail == "y"
                              ? true
                              : false
                          }
                        />
                        <div className="invalid-feedback">
                          email address is not valid.
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Detail</label>
                        <textarea
                          className="form-control"
                          rows={4}
                          id="detail"
                          name="detail"
                          value={form?.detail}
                          onChange={handleInputChange}
                          disabled={
                            form?.status != 0 || form?.is_sendemail == "y"
                              ? true
                              : false
                          }
                        ></textarea>
                        <div className="invalid-feedback">
                          Detail email is not valid.
                        </div>
                      </div>
                      {products?.length > 1 && (
                        <div className="col-md-6 mb-3">
                          <label htmlFor="lastName">Product Type</label>

                          <div className="form-row">
                            <div className="col-sm-12">
                              <Form.Control
                                as="select"
                                id="product_id"
                                name="product_id"
                                className={
                                  hasError("product_id")
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                value={form?.product_id}
                                onChange={handleInputChange}
                              >
                                <option value="option">Select Product</option>
                                {products?.map((c) => (
                                  <option value={c.id}>{c.name}</option>
                                ))}
                              </Form.Control>

                              <div className="invalid-feedback">
                                Product Type is not valid.
                              </div>

                            </div>
                          </div>
                        </div>
                      )}

                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Product Name</label>

                        <div className="form-row">
                          <div className="col-sm-12">
                            <input
                              type="text"
                              className={
                                hasError("product_name")
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              id="product_name"
                              name="product_name"
                              placeholder=""
                              value={form?.product_name}
                              onChange={handleInputChange}
                            />

                            <div className="invalid-feedback">
                              Product Name is not valid.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Amount</label>

                        <div className="form-row">
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className={
                                hasError("amount")
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              id="amount"
                              name="amount"
                              placeholder=""
                              required
                              value={form?.amount}
                              onChange={handleInputChange}
                              disabled={
                                form?.status != 0 || form?.is_sendemail == "y"
                                  ? true
                                  : false
                              }
                            />
                            <div className="invalid-feedback">
                              Amount type is not valid.
                            </div>
                          </div>
                          <div className="col mt-2 mt-sm-0">
                            <Form.Control
                              as="select"
                              id="currency"
                              name="currency"
                              className={
                                hasError("currency")
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={form?.currency}
                              onChange={handleInputChange}
                              disabled={
                                form?.status != 0 || form?.is_sendemail == "y"
                                  ? true
                                  : false
                              }
                            >
                              <option value="option">Select Currency</option>
                              {currencies.map((c) => (
                                <option value={c.code}>{c.code}</option>
                              ))}
                            </Form.Control>

                            <div className="invalid-feedback">
                              Currency is not valid.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="lastName">Expire Date</label>
                            <div >
                              <DatePicker 
                                className="picker-input-w-1"  
                                onChange={handleDateChange}                             
                                initialDate={dayjs(nowDate)}
                                startDay={dayjs(nowDate)}                            
                              />


                            </div>

                            <div className="invalid-feedback">
                              Expiry is not valid.
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="lastName">Expire Time</label>
                            <TimeList 
                                handleInputChange={handleInputChange}  
                                minutes={minutes}
                                hour={hour}                              
                            />
                            <div className="invalid-feedback">
                              Expiry is not valid.
                            </div>
                          </div>
                        </div>
                      </div>                      

                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Language </label>
                        <Form.Control
                          as="select"
                          name="lang_code"
                          id="lang_code"
                          className={
                            hasError("lang_code")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          value={form?.lang_code}
                          onChange={handleInputChange}
                          disabled={
                            form?.status != 0 || form?.is_sendemail == "y"
                              ? true
                              : false
                          }
                        >
                          <option value="">Select Language</option>
                          {locales.map((l) => (
                            <option value={l.code}>{l.code}</option>
                          ))}
                        </Form.Control>

                        <div className="invalid-feedback">
                          Language is not valid.
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Payment method</label>

                        <div className="form-group">
                          {channel.map((c) => (
                            <div className="form-check form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  value={c.id}
                                  name="channel"
                                  id="channel"
                                  checked={channelSelected(c.id)}
                                  className={
                                    hasError("channel")
                                      ? "form-check-input is-invalid"
                                      : "form-check-input"
                                  }
                                  onChange={handleCheckbox}
                                  disabled={
                                    form?.status != 0 || form?.is_sendemail == "y"
                                      ? true
                                      : false
                                  }
                                />
                                {c.name}
                              </label>
                            </div>
                          ))}

                          <div
                            className={
                              hasError("channel")
                                ? "invalid-channel"
                                : "valid-channel"
                            }
                          >
                            Payment method is not valid.
                          </div>
                        </div>
                      </div>
                    </div>
                    {form?.status == "0" && form?.is_sendemail == "n" && (
                      <div className="mt-4">
                        <a
                          className="btn btn-primary mb-1 ml-1 btn btn-primary"
                          role="button"
                          onClick={createQuickpay}
                        >
                          {actionUser} Linkpayment
                        </a>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormCreateQuickpay;
