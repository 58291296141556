
import React, { useEffect, useState } from 'react';
import {QuickPayStatusEnum} from "common/enumerations/QuickPayObject";


const QuickpayStatus = (prop) =>{

    const {status} = prop

    if(status == QuickPayStatusEnum.NON_PAID)
    {
        return (<span className='badge badge-rounded badge-light'>Non Paid</span>)                      
    }  
      
    if(status == QuickPayStatusEnum.PAID)
    {
        return  (<span className='badge badge-rounded badge-success'>Paid</span>)                      
    }  

    if(status == QuickPayStatusEnum.EXPIRED)
    {
        return (<span className='badge badge-rounded badge-danger'>Expired</span>)
    }


    return (<></>)

}

export default QuickpayStatus