import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'
import APIRequest from 'common/interface/APIRequest'
import { serverConfig } from 'common/Config/ServerConfig';
import SearchUser from 'common/interface/SearchUser';


class GetMerchantInfoByIdRequest  {

  method: HTTPMethodEnum = HTTPMethodEnum.POST
  url: string = '/account/merchant-info'
  param: number
  
  constructor () {  }

  makeBody() {}
  makeQuery() {}
  makeHeader() {}

}

export default GetMerchantInfoByIdRequest