import { makeStyles } from '@material-ui/core/styles';
import ImageManager from 'common/Manager/ImageManager';
import { marginBottom } from 'styled-system';


const useStyles = makeStyles((theme) => ({

    label_title : {
        fontStyle: "normal",
        fontFamily: `'NotoSansSemiBold','NotoSansSemiBoldThai'`,    
        fontWeight: 600,
        fontSize: "18px",
       // lineHeight: "18px",
        lineHeight: "15px",
        marginBottom:"33px",
        color: "#3E425B"
    },
    label : {
        fontFamily: `'NotoSansRegular','NotoSansRegularThai'`, 
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "34px",
        color:"#3E425B",
    },
    label_comp : {
        fontStyle: "normal",
        fontFamily: `'NotoSansSemiBold','NotoSansSemiBoldThai'`,    
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "14px",
        color: "#3E425B",
        marginBottom: "7px"
    },
    label_comp_detail : {
        fontStyle: "normal",
        fontFamily: `'NotoSansRegular','NotoSansRegularThai'`,    
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "18px",
        color: "#3E425B",
       
    },
    label_comp_detail_m : {
      display:'none'       
    },
    label_address2 : {
        marginBottom: "16px"
    },
     
    grid_content :{
        lineHeight: "34px"
    },
    copyright_m : {
      
    // position: "absolute",
        paddingTop : "37px",
        fontStyle: "normal",
        fontFamily: `'NotoSansRegular','NotoSansRegularThai'`,    
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "14px",
        color: "#3E425B",
        display: "none"
    },
    footer: {
        // position: "relative",
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: '90px',
        paddingBottom: '90px',
        paddingLeft: '166px',
        paddingRight: '166px', 
        width: "100%",
        height: '560px',
        background: "#FFFFFF",
        backgroundImage: `url(${ImageManager.default.images.sabuypay.register.bg_footer})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 230px",
        backgroundPosition: "bottom",     
    },
    pcidss :{
        height: "126px"
    },
    copyright :{
       // position: "absolute",
        paddingTop : "37px",
        fontStyle: "normal",
        fontFamily: `'NotoSansRegular','NotoSansRegularThai'`,    
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "18px",
        color: "#3E425B"
    },
    copyright_text :{
        paddingTop : "43px",
    },
     top_footer : {
        height: "239px"
    },
    top_footer_m :{
        display: "none"
    },
    logo_footer : {
        marginBottom: "29px"
    },
    container: {          
        paddingLeft: '10%',
        paddingRight: '10%',    
    },
    // background: "#F3F2FA",

    a_link :{
        // padding: 8px 8px 8px 32px;
        "textDecoration": "none",
         color: "#3E425B",
        '&:hover': {
            color: "#0fbed7"
         },
      
    }  ,    

    // a:hover {
    //     color: "#0fbed7"
    // },

    "@media only screen and (max-width: 768px)" : {
    },
    "@media only screen and (max-width: 992px) ": {         

        label_comp_detail: {
            display :'none',
        },
        top_footer : {
            display :'none',        
        }, 
        top_footer_m: {
            paddingBottom: '38px',
            display :'block',
        },
        label_comp_detail_m : {
            display :'block',
            fontStyle: "normal",
            fontFamily: `'NotoSansRegular','NotoSansRegularThai'`,    
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "18px",
            color: "#3E425B",
        },
        copyright :{
            display :'none',
        },  
        logo_footer : {
          
           marginBottom: "18px",           
        },  
        label_title : {        
            marginBottom: '21px'
        },
        label : {
            marginBottom: '38px'
        },
        copyright_m :{
            paddingTop : "29px",
            display:'block'
        },
        copyright_text :{
            paddingTop : "21px",
        },
        footer: {

            // position: "relative",
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingLeft: '15px',
            paddingRight: '15px',  
            paddingTop: '38px',
            paddingBottom: '38px',
            width: "100%",
            height: '1247px',
            background: "#FFFFFF",
            backgroundImage: `url(${ImageManager.default.images.sabuypay.register.bg_footer})`,
           backgroundRepeat: "no-repeat",
            //backgroundSize: "cover",
           backgroundSize: "885px 141px",
           backgroundPosition: "-58px 1106px"
        },


      
    },
    "@media only screen and (min-width: 992px) ": {   

        footer: {
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: '90px',
            paddingBottom: '90px',
            // paddingLeft: '166px',
            // paddingRight: '166px', 
            paddingLeft: '5%',
            paddingRight: '5%',     
            width: "100%",
            height: '560px',
            background: "#FFFFFF",
            backgroundImage: `url(${ImageManager.default.images.sabuypay.register.bg_footer})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 230px",
            backgroundPosition: "bottom",     
        },

    },
    "@media only screen and (min-width: 1200px) ": { 

        footer: {
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: '90px',
            paddingBottom: '90px',
            // paddingLeft: '166px',
            // paddingRight: '166px', 
            paddingLeft: '11.5%',
            paddingRight: '11.5%',     
            width: "100%",
            height: '560px',
            background: "#FFFFFF",
            backgroundImage: `url(${ImageManager.default.images.sabuypay.register.bg_footer})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 230px",
            backgroundPosition: "bottom",     
        },
        

      
    },

  


  

}));

export default useStyles