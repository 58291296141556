import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import APIRequest from "common/interface/APIRequest";
import APISearchRequest from "common/interface/APISearchRequest";
import FilterModel from "common/models/FilterModel";
import PeriodDate from "../models/PeriodDate";


class GetTopAmountProductRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/dashboard/top-amount-product/get";
  param:FilterModel;
  period:PeriodDate;

  constructor(period :PeriodDate) {   
    this.period = period
  }

  makeBody() { 
    return {
      period : this.period
    }
   }

  makeQuery() {}
}

export default GetTopAmountProductRequest;
