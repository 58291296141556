import React,{ useEffect, useState, useRef} from 'react';
import SearchTransactionRequest from "modules/transaction/thaiqr/APIRequest/SearchTransactionRequest";
import CountTransactionRequest from "modules/transaction/thaiqr/APIRequest/CountTransactionRequest";
import APIManager from "common/Manager/APIManager";
import FilterModel from "common/models/FilterModel";
import TransactionsThaiqr from "modules/transaction/thaiqr/component/index"
import {endDateISO, startDateISO} from 'common/Manager/Helper'


const index = (props: any) => {	

	const [systemRef, setSystemRef] = useState("")
	const [orderRef, setOrderRef] = useState("")
	const [qrMethod, setQrMethod] = useState("")
	const [cardHolder, setCardHolder] = useState("")
	
	const [status, setStatus] = useState(0)

	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	
	const [pageCount, setPageCount] = useState(0)
	const [page, setPage] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [totalPage, setTotalPage] = useState(0)

	const [sort, setSort] =  useState({	
			field : 'created_at',
			sortby: 'DESC'
		  
	})
	
	const [productName, setProductName] = useState("")

	const [transaction, setTransaction] = useState([])
	const getTransaction = async (current_page = page, per_page = perPage, sortData = sort) => {
			
		const filterModel = new	FilterModel(
		{
			system_ref : systemRef,
			order_ref : orderRef,
			status : status,	
			start_date: startDate,
			end_date: endDate,
			product_name: productName,
			page: current_page,
			pagesize: per_page,	
			sort: sortData,
			qr_method: qrMethod,	
			card_holder : cardHolder,	
			orderdirection: "DESC"		
		})
	
		const apiRequest = new SearchTransactionRequest(filterModel);
		const countApiRequest = new CountTransactionRequest(filterModel);
	
		const items = await APIManager.default.fetch(apiRequest);						
		const count = await APIManager.default.fetch(countApiRequest);
					
		setTransaction(items.data.data);		
		setPageCount(count.data.data)
		setTotalPage(Math.ceil(count.data.data / perPage))	

	};


	useEffect( () => {
		
		 getTransaction(page, perPage);	

	}, [])

	const handleDateChange = (st, end) =>{

		if(st){
			setStartDate(startDateISO(st))		
		}

		if(end){
			setEndDate(endDateISO(end))
		}

	}

  const onView = (id:any) =>{	window.location.href = `/transaction/thaiqr/${id}`; }

  return (
		<>
			<TransactionsThaiqr 
				setSystemRef={setSystemRef}
				setOrderRef={setOrderRef}		
				setQrMethod={setQrMethod}
				setStatus={setStatus}
				setCardHolder={setCardHolder}
				handleDateChange={handleDateChange} 
				getTransaction={getTransaction}
				//pageCount={pageCount}
				data={transaction}
				key={1}		

				setSort={setSort}
				setPage={setPage}
				setPerPage={setPerPage}
				setTotalPage={setTotalPage}

				setProductName={setProductName}

				page={page}
				perPage={perPage}
				countRow={pageCount}
				totalPage={totalPage}	
				onView={onView}			
			>

			</TransactionsThaiqr>			

    </>
  )


}

export default index;