export enum TransactionStatusEnum {
    Pending = '0',
    Authorized = '1',
    Accepted = '2',
    Voided = '3',
    Settled = '4', 
    Rejected = '5',
    Refund = '6',
    Pending_3D = '7',
    Expired = '9',
    Other = '10',
    Cancel = '11'  
  }
