import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import FilterModel from "common/models/FilterModel";



class DocumentSettingRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.GET;
  url: string = "/register/document-setting";
  param:FilterModel
  locale = 'en'

  constructor() {
  }

  

  makeBody() {
    return {
      locale : this.locale
    }

  }

  makeQuery() {
  }
}

export default DocumentSettingRequest;


