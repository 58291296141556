import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";

class DownloadTransferRequest {
  // method: HTTPMethodEnum = HTTPMethodEnum.POST;

  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/report/transfer-history/download";
  param:string
 

  constructor(param: string) {
    this.param = param   
  }

  makeBody() {

    return {
      transaction_date : this.param
    }
  }

  makeQuery() {}
}

export default DownloadTransferRequest;
