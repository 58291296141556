import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import APIManager from "common/Manager/APIManager";
import swal from "sweetalert";
import ChangeRequest from "modules/password/change/APIRequest/ChangeRequest";
import RegularExpression from "utilities/RegularExpression";

let errors = [];
let check = false;

const ChangePassword = () => {
  const vali = RegularExpression.default;
  const [form, setForm] = useState({
    password: "",
    newpassword: "",
    confirmpassword: "",
  });

  const [formEror, setFormEror] = useState([]);
  const [checkList, setCheckList] = useState({
    is_eight: false,
    is_upper: false,
    is_lower: false,
    is_numberic: false,
    is_special: false,
  });

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const changePassword = async (e) => {
    e.preventDefault();

    if (errors.length) {
      return false;
    }

    if( checkList.is_eight == false || checkList.is_upper == false || checkList.is_lower == false || checkList.is_numberic == false || checkList.is_special == false )
    {
      return false;
    } 

    const apiRequest = new ChangeRequest({
      password: form.password,
      newpassword: form.newpassword,
    });

    const items = await APIManager.default.fetch(apiRequest);

    if (items.data.status == 200) {
      swal("success", {
        icon: "success",
      }).then(() => {
        window.location.href = "/page-login";
      });
    }
  };

  const handleInputChange = (e) => {
   
    const { target } = e;
    var obj = {};

    if (!vali.passwordCaseThai(target.value, false)) {
      obj[target.name] = target.value;
      setForm({ ...form, ...{ [target.name]: target.value } });
    }

    if (target.name == "password") {
      var index = errors.indexOf("password");
      if (target.value == "") {
        if (index == -1) {
          errors.push("password");
        }
      } else {
        if (index !== -1) {
          errors.splice(index, 1);
        }
      }
      ///
      var index = errors.indexOf("newpassword");
      if (form.newpassword == target.value) {
        if (index == -1) {
          errors.push("newpassword");
          errors.push("passdup");
        }
      } else {
        if (index !== -1) {
          errors.splice(index, 1);
        }
      }
    }

    if (target.name == "newpassword") {
      var index = errors.indexOf("newpassword");
      checkList.is_lower = vali.validPassLowercase(target.value, false);
      checkList.is_upper = vali.validPassUppercase(target.value, false);
      checkList.is_numberic = vali.validPassNumberic(target.value, false);
      checkList.is_special = vali.validPassSpecialchar(target.value, false);
      checkList.is_eight = target.value.length > 7 ? true : false;

      if (target.value == "") {
        if (index == -1) {
          errors.push("newpassword");
        }
      } else {
        if (index !== -1) {
          errors.splice(index, 1);
        }
      }

      ///

      if (target.name == "newpassword") {
        var index = errors.indexOf("passdup");
        if (form.password == target.value) {
          if (index == -1) {
            errors.push("newpassword");
            errors.push("passdup");
          }
        } else {
          if (index !== -1) {
            errors.splice(index, 1);
          }
        }

        /* */
      }
    }

    if (target.name == "confirmpassword") {
      var index = errors.indexOf("confirmpassword");

      if (target.value == "" || target.value != form.newpassword) {
        if (index == -1) {
          errors.push("confirmpassword");
        }
      } else {
        if (index !== -1) {
          errors.splice(index, 1);
        }
      }
    }

    setFormEror(errors);
  };

  const hasError = (key) => {
    return formEror.indexOf(key) !== -1;
  };

  const handleEyeCurrentPasswordClick = () => {
    if (showCurrentPassword == false) {
      setShowCurrentPassword(true);
    } else {
      setShowCurrentPassword(false);
    }
  };

  const handleEyePasswordClick = () => {
    if (showPassword == false) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const handleEyeConfirmPasswordClick = () => {
    if (showConfirmPassword == false) {
      setShowConfirmPassword(true);
    } else {
      setShowConfirmPassword(false);
    }
  };
  return (
    <>
      <div className="row page-titles mx-0">
        <div className="col-sm-6 p-0">
          <div className="welcome-text">
            <h2 className="text-black font-w600 mb-0">Change Password</h2>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-8">
          <div className="col-lg-12 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Change Password</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Current Password
                      </label>
                      <div className="col-sm-9">
                        <div style={{ display: "flex" }}>
                          <input
                            type={`${
                              showCurrentPassword ? "text" : "password"
                            }`}
                            className={
                              hasError("password")
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="password"
                            name="password"
                            onChange={(e) => handleInputChange(e)}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />

                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={() => handleEyeCurrentPasswordClick()}
                              style={{
                                marginLeft: "-35px",
                                zIndex: 1,
                                cursor: "pointer",
                              }}
                            >
                              {showCurrentPassword && (
                                <i
                                  className="fa fa-eye"
                                  style={{ display: "block" }}
                                ></i>
                              )}
                              {!showCurrentPassword && (
                                <i className="fa fa-eye-slash"> </i>
                              )}
                            </span>
                          </div>
                          
                                             
                        </div>

                        {hasError("password") && (
                               <div className="error" >
                               Password is required.
                             </div>
                         )}
                      
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        New password
                      </label>
                      <div className="col-sm-9">

                        <div style={{ display: "flex" }}>
                          <input
                            name="newpassword"
                            type={`${showPassword ? "text" : "password"}`}
                            className={
                              hasError("newpassword")
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder=""
                            value={form.newpassword}
                            onChange={handleInputChange}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                        
                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={() => handleEyePasswordClick()}
                              style={{
                                marginLeft: "-35px",
                                zIndex: 1,
                                cursor: "pointer",
                              }}
                            >
                              {showPassword && (
                                <i
                                  className="fa fa-eye"
                                  style={{ display: "block" }}
                                ></i>
                              )}
                              {!showPassword && (
                                <i className="fa fa-eye-slash"> </i>
                              )}
                            </span>
                          </div>

                        </div>

                        {hasError("newpassword") && (
                          <div className="error">
                            {hasError("passdup")
                              ? "New Password is same Password."
                              : "New Password is required."}{" "}
                          </div>
                         )}

                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Confirm New Password
                      </label>
                      <div className="col-sm-9">

                        <div style={{display:"flex"}} >   
                          <input
                                type={`${showConfirmPassword ? "text" : "password" }`}
                                name="confirmpassword"
                            className={
                              hasError("confirmpassword")
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder=""
                            value={form.confirmpassword}
                            onChange={handleInputChange}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                            <div   
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center'
                                    }}                          
                            >
                              <span 
                                  onClick={() => handleEyeConfirmPasswordClick() }                                        
                                  style={{
                                      marginLeft: '-35px',
                                      zIndex: 1,
                                      cursor: 'pointer'                                        
                                      }}
                                  >
                                    {showConfirmPassword  && (<i className="fa fa-eye" style={{display:'block'}}></i>)}   
                                    {!showConfirmPassword  && ( <i className="fa fa-eye-slash"> </i>  )} 
                              </span>
                              
                            </div>

                        </div> 
                        {hasError("confirmpassword") && (
                        <div className="error">
                          Confirm New Password is required or not match New
                          Password.
                        </div>)
                        }

                        <div
                          role="alert"
                          class="fade alert-dismissible alert alert-primary show mt-4"
                        >
                          <div class="media">
                            <div class="media-body">
                              <div class="form-group">
                                <div class="form-check">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="check1"
                                    disabled=""
                                    checked={checkList.is_eight}
                                  />
                                  <label class="form-check-label" for="check1">
                                    Password must at least 8 character.
                                  </label>
                                </div>

                                <div class="form-check">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="check2"
                                    disabled=""
                                    checked={checkList.is_upper}
                                  />
                                  <label class="form-check-label" for="check2">
                                    Password must contain at least 1 uppercase
                                    letter.
                                  </label>
                                </div>

                                <div class="form-check">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="check2"
                                    disabled=""
                                    checked={checkList.is_lower}
                                  />
                                  <label class="form-check-label" for="check2">
                                    Password must contain at least 1 lowercase
                                    letter.
                                  </label>
                                </div>

                                <div class="form-check disabled">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="check3"
                                    disabled=""
                                    checked={checkList.is_numberic}
                                  />
                                  <label class="form-check-label" for="check3">
                                    Password must contain at least 1 numberic
                                    character.
                                  </label>
                                </div>

                                <div class="form-check disabled">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="check3"
                                    disabled=""
                                    checked={checkList.is_special}
                                  />
                                  <label class="form-check-label" for="check3">
                                    Password must contain at least 1 special
                                    character.
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-3"></div>
                      <div class="col-9">
                        <a
                          onClick={changePassword}
                          className="btn btn-primary mb-1 ml-1 btn btn-primary"
                          role="button"
                        >
                          Save
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
