
import { padWithLeadingZeros } from 'common/Manager/Helper';
import React from 'react';
import { width } from 'styled-system';

type Props = {   
    selectList :any;
    id :string;
    name :string;
    hasError? :any;
    defaultValue?:any;
    handleInputChange:any;
}

const Select = ({selectList, id, name, hasError,defaultValue ,handleInputChange } : Props) => {

    

        return (
      
            <select 
                defaultValue={defaultValue} 
                className={
                 "form-control"
                }
                id={id}
                name={name}
                onChange={handleInputChange}
            
            >
            {selectList.map((option) => (
                <option value={option.value}>{option.label}</option>
            ))}    

        </select>
        
        )
     

    
}

  

export default Select