import React from 'react';
import {Link} from 'react-router-dom';

//Images
import {TransactionStatusEnum} from "common/enumerations/TransactionStatusEnum";
import {SettlementEnum} from "common/enumerations/SettlementEnum";
import {formatDateYearEN} from 'common/Manager/Helper';

import QuickpayStatus from 'modules/quickpay/component/QuickpayStatus';
import QuickPayResponseModel from 'modules/quickpay/models/QuickPayResponseModel';

import NumberFormat from 'utilities/NumberFormat';
 
// const StatisticCanvas = loadable(() =>
// 	pMinDelay(import("../Mophy/Transactions/StatisticCanvas"), 1000)
// ); 
 
const QuickpayDetail = (prop)=>{

  const {textLink, copyToTextLink, copyTextLink} =prop

  const quickPayResponse = prop.data as QuickPayResponseModel
	return(
		<>
      <div className="row page-titles mx-0">
        <div className="col-sm-6 p-0">
          <div className="welcome-text">
            <h2 className="text-black font-w600 mb-0">Link payment details</h2>
          </div>
        </div>
        <div className="col-sm-6 p-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to={"/transaction/creditcard"}>Link payment detail</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"#"}>#{}</Link>
            </li>
          </ol>
        </div>
      </div>

      <div className="row">

        <div className="col-xl-12">      

          <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h2 className="card-title">Link payment detail</h2>
                  </div>
                  <div className="card-body pb-0">

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Order ref<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>{quickPayResponse?.quickpay.order_ref}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Product Type<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>{quickPayResponse?.product.name}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Product Name<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>{quickPayResponse?.quickpay?.product_name}</span>
                      </div>
                    </div>
                    
                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Customer name<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>{quickPayResponse?.quickpay.customer_name}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Mobile<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>{quickPayResponse?.quickpay.mobile}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Email<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>{quickPayResponse?.quickpay.email}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Amount<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>{NumberFormat.default.numberWithCommas(quickPayResponse?.quickpay.amount?.toString())}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Currency<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>{quickPayResponse?.quickpay.currency}</span>
                      </div>
                    </div>
                    
                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Language<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>{quickPayResponse?.quickpay.lang_code}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Channel <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>
                          {/* {quickPayResponse?.quickpay.channel} */}
                          {quickPayResponse?.channel}
                        </span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Detail<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>{quickPayResponse?.quickpay.detail}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          URL<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">

                          <span className="mr-1" ref={textLink} >{quickPayResponse?.quickpay.url}</span>
                          <button type="button" className="mr-2 btn btn-primary btn-xs"
                             onClick={(e)=> { 
                              e.preventDefault()
                              navigator.clipboard.writeText(quickPayResponse?.quickpay.url)
                              copyToTextLink()

                            }}                          
                          > Copy</button>

                           {copyTextLink}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Status<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span><QuickpayStatus status={quickPayResponse?.quickpay.status}></QuickpayStatus></span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Created date<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>{formatDateYearEN(new Date(quickPayResponse?.quickpay.created_at))}</span>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-2">
                        <h5 className="f-w-500">
                          {" "}
                          Expired date<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-10">
                        <span>{formatDateYearEN(new Date(quickPayResponse?.quickpay.expired_at))}</span>
                      </div>
                    </div>

               
                  </div>
                </div>
              </div>
            
            
          </div>

		      
        </div>


      </div>
    </>
	)
}
export default QuickpayDetail;