import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";

class TransactionDetailRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/transaction/thaiqr/getbyid";
  param:string
 

  constructor(param: string) {
    this.param = param   
  }

  makeBody() {

    return {
         id : this.param
    }
  }

  makeQuery() {}
}

export default TransactionDetailRequest;
