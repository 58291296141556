import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import FilterModel from "common/models/FilterModel";



class RegisterChannelRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.GET;
  url: string = "/register/channel";
  param:FilterModel
  locale:any

  constructor(locale: string) {
    this.locale = locale
  }
  
  makeBody() {}

  makeQuery() {
    return {
      locale: this.locale
    }

  }
}

export default RegisterChannelRequest;


