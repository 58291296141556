import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import APIRequest from "common/interface/APIRequest";
import APISearchRequest from "common/interface/APISearchRequest";
import FilterModel from "common/models/FilterModel";


export interface OptionTypeInterface {
  filters: any[],
  columnOrder: number,
  groupSort: string,
  id: number,
  orderDirection: string,
  page: number,
  pageSize: number,
  totalCount: number,
  orderBy?:any,
  search?:string
}

class ReceiptDetailRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/receipt/view";
  param:string
 
  constructor(param: string) {
    this.param = param   
  }

  makeBody() {

    return {
      receipt_tax_id : this.param
    }
  }

  makeQuery() {}  

}

export default ReceiptDetailRequest;
