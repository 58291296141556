import _ from 'lodash'

class RegularExpression {
  static default: RegularExpression = new RegularExpression()

  private constructor() { }
  //var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");  @.-_
  regEx = {
    name: /^[a-zA-Z ]+$/,
    fullName: /^[a-zA-Z ]+$/,
    fullNameThEn: /^[a-zA-Zก-ฮะ-์- ]+$/,
    firstLastName: /^[a-zA-Z .]+$/,        
    usernameIncludeThai: /^[a-zA-Zก-ฮะ-์]+$/,
    passportNumber: /^[a-zA-Z0-9]+$/,
    citizenNumber: /^[0-9]\d{12}$/,
    //   email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    email: /^(([^<>()\[\]\\.,;!@#$%^&*=/:\s@"]+(\.[^<>()\[\]\\.,;!@#$%^&*=/:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    emailKeyUp: /^[a-zA-Z0-9._\-@]+$/,
    fax:  /^\+?[0-9]{6,}$/,
    thai: /^[ก-๛]+$/,
    phoneNumber: /^[0-9]+$/,
    thaiPhoneNumber: /^[0-9]{10}$/,
    creditCard: /^[0-9]\d{15}$/,
    cvv: /^[0-9]{3,4}$/,
    charactersAndNumbers: /^[a-zA-Z0-9ก-ฮะ-์- ]+$/,
    nationality: /^[a-zA-Z0-9- ]+$/,
    any: /^[\s\S]/,
    numbers: /^[0-9]+$/,
    amount: /^[0-9.]+$/,
    flightBooking: /^[0-9]{10}$/,
    charactersENAndNumbers: /^[a-zA-Z0-9]+$/,
    /*  password verify  onkey */
    expiry: /^[0-9.]+$/,
    uppercase: /[A-Z]/g,
    numberic: /[0-9]/g,
    specialchar: /[!@#$%^&*]/g,
    lowercase: /[a-z]/g,
    passwordCaseThai: /[ก-๙]+$/,
        
    validPassLowercase: /^(?=.*[a-z])/,
    validPassUppercase: /^(?=.*[A-Z])/,
    validPassNumberic: /^(?=.*[0-9])/,
    validPassSpecialchar: /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/,



   }


   
  validate(regex: RegExp, text: string, allowEmpty: boolean): boolean {
    const trimmedText = (text || '')
   
    if (trimmedText.length == 0 && allowEmpty) return true
    //if (trimmedText.length == 0 && allowEmpty == false) return false
    return regex.test(trimmedText)
  }

  validatePassword(regex: RegExp, text: string, allowEmpty: boolean): boolean {
    const rs = text.match(regex)
    if (rs == null) 
      return false
    return true
  
  }

  NameValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.name, text, allowEmpty)
  }

  fullNameValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.fullName, text, allowEmpty)
  }

  fullNameThEnValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.fullNameThEn, text, allowEmpty)
  }

  firstLastNameValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.firstLastName, text, allowEmpty)
  }

  UserNameValidateIncludeThai(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.usernameIncludeThai, text, allowEmpty)
  }

  PassportNumberValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.passportNumber, text, allowEmpty)
  }

  identificationNumberValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.citizenNumber, text, allowEmpty)
  }

  emailValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.email, text, allowEmpty)
  }

  emailKeyUpValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.emailKeyUp, text, allowEmpty)
  }

  phoneNumberValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.phoneNumber, text, allowEmpty)
  }

  thaiPhoneNumberValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.thaiPhoneNumber, text, allowEmpty)
  }
  
  amountValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.amount, text, allowEmpty)
  }

  lowercaseValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.lowercase, text, allowEmpty)
  }

  uppercaseValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.uppercase, text, allowEmpty)
  }

  numbericValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.numberic, text, allowEmpty)
  }

  specialcharValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.specialchar, text, allowEmpty)
  }

  expiryValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.expiry, text, allowEmpty)
  }

  passwordCaseThai(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.passwordCaseThai, text, allowEmpty)
  }

  validPassNumberic(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.validPassNumberic, text, allowEmpty)
  }

  validPassSpecialchar(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.validPassSpecialchar, text, allowEmpty)
  }

  validPassUppercase(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.validPassUppercase, text, allowEmpty)
  }

  validPassLowercase(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.validPassLowercase, text, allowEmpty)
  }

  thaiCharValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.thai, text, allowEmpty)
  }

  faxValidate(text: string = '', allowEmpty: boolean = true) {
    return this.validate(this.regEx.fax, text, allowEmpty)
  }


  

  


}

export default RegularExpression