import _ from 'lodash'
import UserRoleModel from './UserRoleModel'


class UserModel {

    id:number
    email: string
    fullname: string
    contact_no: string
    user_group: string
    expire_date: string
    active: string
    merchant_id: string
    member_id: string

    user_role : UserRoleModel[]
 

    constructor(json: any) {
        
        this.id = _.get(json, 'id')
        this.email = _.get(json, 'email')
        this.fullname = _.get(json, 'fullname')
        this.contact_no = _.get(json, 'contact_no')
        this.user_group = _.get(json, 'user_group')
        this.expire_date = _.get(json, 'expire_date')

        this.merchant_id = _.get(json, 'merchant_id')

        this.user_role = _.get(json, 'user_role')
        this.active = _.get(json, 'active')
      
    }

    // userModel.id = users_id;
    // userModel.email = userForm.email;
    // userModel.fullname = userForm.fullname;
    // userModel.contact_no = userForm.contactNo;
    // userModel.expire_date = formatInvert(userForm.expireDate);
    // userModel.user_group = userForm.user_group;
    // userModel.user_role = userRole;
    // userModel.active = userForm.active;


    setMerchantId(merchant_id: string){
        this.merchant_id = merchant_id
    }

    setMemberId(member_id: string){
        this.member_id = member_id
    }
}

export default UserModel