import _ from "lodash";
import { HTTPMethodEnum } from "common/enumerations/HTTPMethodEnum";
import APIRequest from "common/interface/APIRequest";
import APISearchRequest from "common/interface/APISearchRequest";
import FilterModel from "common/models/FilterModel";
import MainBalanceDatePeriodModel from "../models/MainBalanceDatePeriodModel";


class GetMainBalanceRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.POST;
  url: string = "/dashboard/main-balance/get";
  param:FilterModel
  mainBalanceDatePeriod:MainBalanceDatePeriodModel

  constructor(mainBalanceDatePeriodModel:MainBalanceDatePeriodModel) {   
    this.mainBalanceDatePeriod = mainBalanceDatePeriodModel
  }

  makeBody() { 

    return { 
       main_balance_date_period: this.mainBalanceDatePeriod
    }
    
   }

  makeQuery() {}
}

export default GetMainBalanceRequest;
