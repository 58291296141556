import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'
import APIRequest from 'common/interface/APIRequest'


class GetLocalesAPIRequest implements APIRequest {
  method: HTTPMethodEnum = HTTPMethodEnum.GET;
  url: string = `/locales`;
  token: string;


  

  constructor() {
  }

  makeQuery() { }

  makeHeader() { }

  makeBody() {
  
  }


}

export default GetLocalesAPIRequest;
