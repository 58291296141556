import React, { Fragment, useEffect, useState } from 'react'


const EditContact = (props) => {

  const {
    contactForm,
    hasError,
    updateContact,
    handleInputChange,
    channel,
    formEror,
    channelSelected,
    handleCheckbox,
    businessType,
    contentTranslate,
    bank 
  } = props


  return (
    <Fragment>
         <div className="col-lg-12 col-sm-12">
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Contact Information</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form>
                
                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Merchant Name</label>
                    <div className='col-sm-10'>
                      <input
                        type='text'
                        name='merchant_name'
                        className={
                          hasError("merchant_name")
                            ? "form-control is-invalid"
                            : "form-control"
                        }                    
                        placeholder=''
                        value={contactForm?.merchant_name}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                      Merchant name  is required.
                      </div>
                    </div>
                  </div>
                  
                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Address</label>
                    <div className='col-sm-10'>
                      <input
                        type='address'
                        name='address'
                        className={
                          hasError("address")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder=''
                        value={contactForm?.address}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                          Address is required.
                      </div>
                    </div>
                  </div>

                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Mobile</label>
                    <div className='col-sm-10'>
                      <input
                        type='text'
                        name='mobile'
                        className={
                          hasError("mobile")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder=''
                        value={contactForm?.mobile}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                        Mobile is required or not correct.
                      </div>
                    </div>
                  </div>
   
                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Phone</label>
                    <div className='col-sm-10'>
                      <input
                        type='text'
                        name='phone'
                        className={
                          hasError("phone")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder=''
                        value={contactForm?.phone}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                        Phone is required or not correct.
                      </div>
                    </div>
                  </div>
   
                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Contact Email</label>
                    <div className='col-sm-10'>
                      <input
                        type='text'
                        name='contact_email'
                        className={
                          hasError("contact_email")
                            ? "form-control is-invalid"
                            : "form-control"
                        }                      
                        placeholder=''
                        value={contactForm?.contact_email}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                      Contact Email  is required or not correct.
                      </div>
                    </div>
                  </div>
  
                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Contact Person</label>
                    <div className='col-sm-10'>
                      <input
                        type='text'
                        name='contact_person'
                        className={
                          hasError("contact_person")
                            ? "form-control is-invalid"
                            : "form-control"
                        }                     
                        placeholder=''
                        value={contactForm?.contact_person}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                      Contact Person  is required.
                      </div>
                    </div>
                  </div>

                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Support Email</label>
                    <div className='col-sm-10'>
                      <input
                        type='text'
                        name='support_email'
                        className={
                          hasError("support_email")
                            ? "form-control is-invalid"
                            : "form-control"
                        }                     
                        placeholder=''
                        value={contactForm?.support_email}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                        Support Email is not correct.
                      </div>
                    
                    </div>
                  </div>

                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Fax</label>
                    <div className='col-sm-10'>
                      <input
                        type='text'
                        name='fax'
                        className={
                          hasError("fax")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                 
                        placeholder=''
                        value={contactForm?.fax}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Website</label>
                    <div className='col-sm-10'>
                      <input
                        type='text'
                        name='website'
                        className={
                          hasError("website")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                 
                        placeholder=''
                        value={contactForm?.website}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Facebook</label>
                    <div className='col-sm-10'>
                      <input
                        type='text'
                        name='facebook'
                        className={
                          hasError("facebook")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                 
                        placeholder=''
                        value={contactForm?.facebook}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Instagram</label>
                    <div className='col-sm-10'>
                      <input
                        type='text'
                        name='instagram'
                        className={
                          hasError("instagram")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                 
                        placeholder=''
                        value={contactForm?.instagram}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Line</label>
                    <div className='col-sm-10'>
                      <input
                        type='text'
                        name='line'
                        className={
                          hasError("line")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                 
                        placeholder=''
                        value={contactForm?.line}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Business Type</label>
                    <div className='col-sm-10'>
                  
                        <select id="sel1" 
                          name="business_type"
                          className={
                            hasError("business_type")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          onChange={handleInputChange}
                     
                        >
                            <option value="" >{contentTranslate['regis.step3.choose_business_type']}  </option>
                            {businessType.map(b =>
                            <option value={b.id} selected={b.id == contactForm?.business_type?.id} >{contentTranslate[b.key_label]} 
                            </option>
                          )}

                        </select>

                        <div className="invalid-feedback text-left">
                          {contentTranslate["regis.step3.business_type.error"]}
                        </div>
                        
                    </div>
                  </div>

                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Channel</label>
                    <div className='col-sm-10'>
                          {channel.map(c =>
                          <div className='form-check'>
                            <label className='form-check-label'>
                              <input
                                type='checkbox'
                                value={c.id}
                                name="payment_method"
                                id="payment_method"
                                checked={channelSelected(c.id)}
                                className={
                                  hasError("channel")
                                    ? "form-check-input is-invalid"
                                    : "form-check-input"
                                }
                                onChange={handleCheckbox}
                              />
                              {c.name}
                            </label>
                          </div>
                        )}

                      {hasError("payment_method") && (
                        <div className="error text-left">
                          {contentTranslate["regis.step3.payment_method.error"]}
                        </div>
                      )}

                    </div>
                  </div>

                  <div className="form-group row">
                  <label className='col-sm-2 col-form-label'>{contentTranslate['regis.step3.bank_name']}</label>
                    <div className='col-sm-10'>   
                      <select id="sel1"
                        name='bank'
                        className={
                          hasError("bank")
                            ? "form-control is-invalid"
                            : "form-control"
                        }

                        onChange={handleInputChange}
                      >
                        <option value="" >{contentTranslate['regis.step3.choose_bank_name']}</option>
                        {bank.map(b =>
                          <option value={b.id} selected={b.id == contactForm?.bank?.id} >{contentTranslate[b.key_label]}</option> 

                        )}
                      </select>

                      <div className="invalid-feedback text-left">
                        {contentTranslate['regis.step3.bank_name.error']}
                      </div>
                    </div>

                  </div>                   

                  <div className='form-group row'>
                    <label className='col-sm-2 col-form-label'>Bank Account</label>
                    <div className='col-sm-10'>
                        <input
                          type='text'
                          name='bank_account'
                          className={
                            hasError("bank_account")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                  
                          placeholder=''
                          value={contactForm?.bank_account}
                          onChange={handleInputChange}
                          />
                    </div>
                  </div>
  
                  <div class="row mb-3">
                    <div class="col-3">                      
                    </div>
                    <div class="col-9">
                        <a
                          onClick={updateContact}
                          className="btn btn-primary mb-1 ml-1 btn btn-primary"
                          role="button"
                        >
                          Save 
                        </a>
                    </div>
                  </div> 
                 
                </form>
              
              </div>
              
            </div>
          </div>
        </div>
        
     

    </Fragment>
  )
}

export default EditContact
