import _ from 'lodash'
import { HTTPMethodEnum } from 'common/enumerations/HTTPMethodEnum'



class ViewRequest  {

  method: HTTPMethodEnum = HTTPMethodEnum.POST
  url: string = '/account/credential/get'
   
 
  constructor () {}

  makeBody() {}
  makeQuery() {}
  makeHeader() {}

}

export default ViewRequest