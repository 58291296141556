
import React, { useEffect, useState } from 'react';
import {TransactionStatusEnum} from "common/enumerations/TransactionStatusEnum";

const TransactionColorBadgeEnum = {
  [TransactionStatusEnum.Pending]: <span className='badge badge-rounded badge-pending'>Pending</span>,
  [TransactionStatusEnum.Pending_3D]: <span className='badge badge-rounded badge-pending'>Pending_3D</span>,
  [TransactionStatusEnum.Authorized]: <span className='badge badge-rounded badge-authorized'>Authorized</span>,
  [TransactionStatusEnum.Accepted]: <span className='badge badge-rounded badge-accepted'>Accepted</span>,

  [TransactionStatusEnum.Settled]: <span className='badge badge-rounded badge-settled'>Settled</span>,
  [TransactionStatusEnum.Voided]: <span className='badge badge-rounded badge-voided'>Voided</span>,
  [TransactionStatusEnum.Refund]: <span className='badge badge-rounded badge-refund'>Refund</span>,

  [TransactionStatusEnum.Cancel]: <span className='badge badge-rounded badge-cancel'>Cancel</span>,
  [TransactionStatusEnum.Expired]: <span className='badge badge-rounded badge-expired'>Expired</span>,
  [TransactionStatusEnum.Rejected]: <span className='badge badge-rounded badge-rejected'>Rejected</span>,

  [TransactionStatusEnum.Other]: <span className='badge badge-rounded badge-other'>Other</span>,

}

const TransactionColorBadge = ({ transactionStatus }) => {
  
  const badgeHTML = TransactionColorBadgeEnum[transactionStatus];

  return (
    <div>
      {badgeHTML}
    </div>
  );
};

export default TransactionColorBadge;

